import {StyledSubmitButton} from "../../Report/new/Footer/Footer-styled";
import {StyledBody} from "../SidebarMenu/SidebarMenu-styled";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {getOpsColor} from "../../../utils/helper";

const FinaliseExport = ({
  onDownload,
  config,
  loading,
  t
}) => {
  const {activeModule} = useSelector(state=>state);
  const opsColor = useMemo(()=>getOpsColor(config, activeModule),[config, activeModule])
  return (
    <StyledBody style={{justifyContent:'center'}}>
      <div style={{
        display:'flex',
        flexDirection:'column',
      }}>
        <p style={{textAlign:'justify'}}>{t("print.final.questions.1.title")}</p>
        <StyledSubmitButton
          onClick={onDownload}
          bg={opsColor}
          disable={loading}
          className="gtag"
          data-tag-name="Print-Export"
        >
          <span>{t("print.final.questions.1.options.export")}</span>
        </StyledSubmitButton>
      </div>
    </StyledBody>
  );
};

export default FinaliseExport;
