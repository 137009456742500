import Input from "../../../Report/new/Input/Input";
import React, { useMemo, useState } from "react";
import { CoordinateNotationWrapper, StyledSwitch } from "../Editor-styled";
import Dropdown, { DropdownBody } from "../../../Dropdown";

const CoordinateSwitch = ({ dir, selected, onClick }) => {
  const array = useMemo(() => {
    if (dir === "vertical") {
      return ["N", "S"];
    } else {
      return ["W", "E"];
    }
  }, [dir]);

  return (
    <StyledSwitch dir={dir === "vertical" ? "column" : "row"}>
      {array.map((item) => (
        <div
          key={item}
          onClick={() => onClick(item, dir)}
          className={item === selected ? "selected" : ""}
        >
          <span>{item}</span>
        </div>
      ))}
    </StyledSwitch>
  );
};

const CoordinateNotation = ({
  handleInputChange,
  coordinates,
  onDirectionSelect,
  type,
  directions,
  color,
  t,
  onKeyPress,
}) => {
  const arrayInputs = useMemo(() => {
    const array = [
      {
        label: t("screen.widget.CoordinateConversion.degrees"),
        path: "degree",
      },
      {
        label: t("screen.widget.CoordinateConversion.minutes"),
        path: "minutes",
      },
    ];

    if (type === "dms") {
      array.push({
        label: t("screen.widget.CoordinateConversion.seconds"),
        path: "seconds",
      });
    }

    return array;
  }, [type]);

  return (
    <CoordinateNotationWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p>{type.toUpperCase()}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 4,
          alignItems: "center",
        }}
      >
        <CoordinateSwitch
          onClick={onDirectionSelect}
          dir="vertical"
          selected={directions[0]}
        />
        {arrayInputs.map((item) => (
          <Input
            key={item.label}
            onChange={handleInputChange}
            style={{ width: "100%" }}
            placeholder={item.label}
            label={item.label}
            value={coordinates.lat[item.path]}
            name={`lat.${item.path}`}
            margin="0px"
            borderColor={color}
            onKeyPress={onKeyPress}
          />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 4,
          alignItems: "center",
        }}
      >
        <CoordinateSwitch
          onClick={onDirectionSelect}
          dir="horizontal"
          selected={directions[1]}
        />
        {arrayInputs.map((item) => (
          <Input
            key={item.label}
            onChange={handleInputChange}
            style={{ width: "100%" }}
            placeholder={item.label}
            label={item.label}
            value={coordinates.lon[item.path]}
            name={`lon.${item.path}`}
            margin="0px"
            borderColor={color}
          />
        ))}
      </div>
    </CoordinateNotationWrapper>
  );
};

export default CoordinateNotation;
