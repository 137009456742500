import { clusterRed } from "../../utils/API";
import { DragAndDropGraphics } from "./DragAndDropGraphics";
import { iconNameToIcon } from "../../components/Icons/layerSvg/clusterAssets";
import { getFeatureTitle } from "../custom-popup-content";
import { getOpsColor } from "../../utils/helper";

export const getScreenSizeRatio = () => {
  if (!window) return;
  const windowWidth = window.innerWidth;

  if (windowWidth > 1210) {
    return 4;
  } else if (windowWidth < 768) {
    return 6;
  }

  return 5;
};

export function displayTextWidth(text, font) {
  let canvas =
    displayTextWidth.canvas ||
    (displayTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width;
}

export const ConopsIconAttributeMap = {
  hascoordination: {
    iconName: "coordinationCircle",
    color: "",
  },
  hasinfomgnt: {
    iconName: "informationManagementCircle",
    color: "",
  },
  hasroadtransport: {
    iconName: "roadTransportCircle",
    color: "",
  },
  hasairfixed: {
    iconName: "airTransportCircle",
    color: "",
  },
  hasairheli: {
    iconName: "airTransportHelyCircle",
    color: "",
  },
  hasrivertransport: {
    iconName: "riverTransportCircle",
    color: "",
  },
  hasseatransport: {
    iconName: "seaTransportCircle",
    color: "",
  },
  hasfuelprovision: {
    iconName: "fuelProvisionCircle",
    color: "",
  },
  hasroadrehab: {
    iconName: "roadRehabilitationCircle",
    color: "",
  },
  hasgiscapacity: {
    iconName: "gisCapacityCircle",
    color: "",
  },
  hasport: {
    iconName: "portCircle",
    color: "",
  },
  hasairport: {
    iconName: "airportCircle",
    color: "",
  },
  hasstorage: {
    iconName: "storageCircle",
    color: "",
  },
  hasstoragecontrol: {
    iconName: "storageClimateControl",
    color: "",
  },
  //TODO: these attributes don't have corresponding icons
  hastranshipmenthub: {
    iconName: "circle",
    color: "",
  },
  hasaircargoconsolidation: {
    iconName: "airTransportHelyCircle",
    color: "",
  },
  haspreparedness: {
    iconName: "preparednessCircle",
    color: "",
  },
  hasothers: {
    iconName: "circle",
    color: "",
  },
};
/**
 * Base class for the Conops and commenting layers
 * This class creates a Graphics layer. On the graphics layer we draw SVG images for features.
 * The SVG images can be dragged and dropped
 */
export class Conops extends DragAndDropGraphics {
  dimensions = {
    width: 100,
    height: 100,
  };

  createSvgSymbol = (feature) => {
    const title = getFeatureTitle(feature, this.t);
    const storageCap = feature.attributes["storagecap"];
    const storageControlCap = feature.attributes["storagecontrolcap"];
    return this.svgsToBase(
      title,
      this.getConopIconNames(feature.attributes),
      clusterRed,
      storageCap,
      storageControlCap
    );
  };

  getConopIconNames = (attributes) => {
    return Object.keys(ConopsIconAttributeMap)
      .filter((attribute) => attributes[attribute] === 1)
      .map((attribute) => {
        return ConopsIconAttributeMap[attribute];
      });
  };

  svgsToBase = (title, iconNames, color, storageCap, storageControlCap) => {
    const font = "44px 'Open Sans', sans-serif";
    const titleLength = displayTextWidth(title, font);
    const padding = 5,
      textBarHeight = 60,
      minWidth = titleLength > 504 ? titleLength + 8 : 508;

    let inlineSVGContainer = this.createNsElement("svg");
    inlineSVGContainer.style.backgroundColor = "white";
    inlineSVGContainer.style.color = "#4A4A4A";
    inlineSVGContainer.style.borderColor = getOpsColor(this.config);
    inlineSVGContainer.style.borderWidth = "3px";
    inlineSVGContainer.style.borderStyle = "solid";

    let height = padding;
    let maxWidth = 0;
    let cursor = [padding, padding];
    if (title) {
      const textEl = this.createNsElement("text");
      textEl.setAttribute("x", "10");
      textEl.setAttribute("y", "56");
      textEl.setAttribute("style", `font:${font}; color:#4A4A4A;`);
      textEl.innerHTML = title;
      inlineSVGContainer.append(textEl);
      cursor[1] += textBarHeight;
    }

    let lineHeight = 0,
      iconsOnLine = 0;

    const newLine = () => {
      iconsOnLine = 0;
      cursor[0] = padding;
      cursor[1] += lineHeight + 3;
      lineHeight = 0;
    };

    const cloneIcon = (icon) => {
      let result = new DOMParser().parseFromString(icon, "text/xml");
      let inlineSVG = result.getElementsByTagName("svg")[0];
      inlineSVG.removeAttribute("xmlns");

      const viewBox = inlineSVG.getAttribute("viewBox").split(" ");

      const clone = inlineSVG.cloneNode(true);
      const circle = clone.getElementsByTagName("circle");

      if (circle.length === 0) {
        const c = document.createElement("circle");
        c.setAttribute("cx", "36");
        c.setAttribute("cy", "36");
        c.setAttribute("r", "36");
        c.setAttribute("fill", color);
        clone.prepend(c);
      }

      clone.setAttribute("width", `${this.dimensions.width}px`);
      clone.setAttribute("height", `${this.dimensions.height}px`);

      //creating g element to put svg inside otherwise it is not possible to set width and height to svg because of the path
      const g = document.createElement("g");

      g.append(clone);
      g.setAttribute("transform", `translate(${cursor[0]} ${cursor[1]})`);
      inlineSVGContainer.append(g);
      return ["0", "0", this.dimensions.width, this.dimensions.height];
    };

    iconNames.forEach((item) => {
      let { iconName, color = "#219150" } = item;
      if (!iconName) iconName = "epamOthers";

      const isStorageText =
        iconName === ConopsIconAttributeMap.hasstorage.iconName && storageCap;
      const isStorageControlText =
        iconName === ConopsIconAttributeMap.hasstoragecontrol.iconName &&
        storageControlCap;
      //Break line after 4 icons
      if (iconsOnLine > 3 || isStorageText || isStorageControlText) newLine();

      let icon = iconNameToIcon(iconName);
      if (color) {
        icon = icon.replaceAll(clusterRed, color);
        icon = icon.replaceAll(clusterRed.toUpperCase(), color);
      }

      const viewBox = cloneIcon(icon);

      cursor[0] += Number(viewBox[2]) + 3;
      maxWidth = Math.max(cursor[0], maxWidth);
      lineHeight = Math.max(lineHeight, Number(viewBox[3]));
      iconsOnLine++;

      if (isStorageText) {
        this.addStorageCapText(
          inlineSVGContainer,
          cursor,
          padding,
          lineHeight,
          storageCap
        );
        newLine();
      }

      if (isStorageControlText) {
        this.addStorageCapText(
          inlineSVGContainer,
          cursor,
          padding,
          lineHeight,
          storageControlCap
        );
        newLine();
      }
    });

    maxWidth += padding;
    maxWidth = Math.max(minWidth, maxWidth);
    height += cursor[1] + lineHeight + padding;

    this.setElementWidthHeight(inlineSVGContainer, maxWidth, height);

    const ratio = getScreenSizeRatio();

    let svgString = new XMLSerializer().serializeToString(inlineSVGContainer);

    //when having this value in elements icon is not rendered in box
    svgString = svgString.replaceAll(
      'xmlns="http://www.w3.org/1999/xhtml"',
      ""
    );
    return {
      type: "picture-marker",
      height: height / ratio,
      width: maxWidth / ratio,
      url:
        "data:image/svg+xml;base64," +
        btoa(unescape(encodeURIComponent(svgString))),
    };
  };

  addStorageCapText = (
    inlineSVGContainer,
    cursor,
    padding,
    lineHeight,
    storageCap
  ) => {
    const textEl = this.createNsElement("text");
    textEl.setAttribute("x", cursor[0] + padding);
    textEl.setAttribute("y", cursor[1] + lineHeight / 2 + padding * 2);
    textEl.setAttribute("style", "font: 38px 'Open Sans', sans-serif;");
    textEl.innerHTML = ` = ${storageCap} m<tspan dy='-8'>2</tspan>`;
    inlineSVGContainer.append(textEl);
  };
}
