import styled, {keyframes} from "styled-components";
import {StyledFiltersBackButton} from "../MobileApp/MobileFilter/MobileFilter-styled";

export const StyledModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: ${({show}) => show ? '100%' : '0%'};
    height: ${({show}) => show ? '100%' : '0%'};
    background: #00000088;
    z-index: 12;
`

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #FFFFFF;
  gap: 10px;
  left: 8px;
  width: ${({show})=> show ? 'calc(100% - 16px)' : '0%'};
  height: ${({show, isHeaderShown})=> show ? !isHeaderShown ? 'calc(100% - 16px)' : 'calc(100% - 56px)' : '0%'};
  top: ${({isHeaderShown})=> !isHeaderShown ? '8px' : '48px'};
  overflow: ${({show})=> show ? 'visible' : 'hidden'};
  pointer-events: ${({show})=> show ? 'auto' : 'none'};
  opacity: ${({show})=> show ? '1' : '0'};
  transition: all 0.2s;
  
  div {
      opacity: ${({show})=> show ? '1' : '0'};
      //transition: opacity 0.1s 0.15s;
  }
  
  @media screen and (min-width: 769px){
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    gap: 10px;
  }
  
  @media screen and (min-width: 769px) and (max-width: 1240px){
    width: 80%;
    padding: 20px;
  }

  @media screen and (min-width: 1240px){
    width: 60%;
    padding: 20px;
  }
  
  border-radius: 8px;
  box-sizing: border-box;
`

export const StyledModalBody = styled.div`
    position: relative;
    flex:1;
    overflow:auto;
    display:flex;
    flex-direction:column;
    gap:16px;
    padding-bottom: 16px;
`

export const StyledModuleWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  padding: 0 8px;
  width: 100%;
    box-sizing: border-box;
  
  @media screen and (min-width: 769px){
    padding: 0;
    grid-template-columns: 1fr 1fr;
  }
`

export const StyledModuleCard = styled.div`
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    align-items: flex-start;
    //min-height: 100px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px 16px;
    transition: all 0.3s;
    cursor: pointer;
    gap: 4px;
    border: 1px solid #8c9ba561;
    box-sizing: border-box;
    //overflow:hidden;
    
    
    :hover {
        //box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
    }
    
    :active {
        background: #8c9ba526;
    }
`

export const StyledCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  position: relative;
    box-sizing: border-box;
  
  .module__icon {
    display: inline-flex;
    padding: 8px;
    align-items: center;
    border-radius: 12px;
    background: ${({opsColor})=>opsColor};
  }
  
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    color: #c0392b;
    flex-shrink: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  
  .module__info_box {
    margin-left: auto;
  }

  @media screen and (min-width: 769px){
    .module__info_box {
      display: none;
    }
  }
`

export const StyledCardBody = styled.div`
  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
`

export const StyledExploreSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border-radius: 8px;
    background: none;
    padding: 0 8px 0px 8px;
    width: 100%;
    box-sizing: border-box;
    
    button {
        width: auto;
        background: #c0392b;
        color: #fff;
        padding: 4px 34px;
    }
    
    p {
        font-size: 12px;
        margin: 0;
        display: none;
    }
    
    @media screen and (min-width: 769px){
        background: ${({bg = '#f0f3f6'})=> bg};
        padding: 10px;
        
        button {
            width: auto;
        }
        
        p {
            display: block;
        }
    }
`

export const StyledModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 8px 4px 8px;
    //box-shadow: rgba(33,35,38,0.1) 0px 2px 6px -2px;
    gap: 8px;
    
    h1 {
        margin: 0;
        font-size: 18px;
        font-weight: 400;
        color: #000;
    }
    
    @media screen and (min-width: 769px){
        padding: 0;
        h1 {
            font-size: 32px;
        }
    }
    
    button {
        border: none;
        padding: 4px;
        margin-left: auto;
        background: none;
        display: flex;
        color: #8C9BA5;
        cursor: pointer;
    }
`

export const StyledButton = styled.button`
    letter-spacing: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    text-decoration: none;
    transition-duration: 50ms;
    transition-timing-function: ease-in;
    white-space: nowrap;
    font-style: normal;
    line-height: 26px; /* 162.5% */
    margin: auto;
    padding: 0 34px;
    font-size: 12px;
    border-radius: 100px;
    background: #fff;
    font-weight: 600;
    color: #000;
    border: 1px solid #8c9ba561;
    
    span {
        pointer-events: none;
    }
    
    
    @media screen and (min-width: 769px){
        width: auto;
        font-size: 14px;
        padding: 4px 28px;
    }
    
    //:hover {
    //    background-color: #FFFFFFE6;
    //}
`

export const StyledModuleIcon = styled.div`
  display: inline-flex;
  padding: 10px;
  align-items: center;
  border-radius: 100px;
  background: ${({opsColor})=> opsColor};
  cursor: pointer;
  gap: 8px;
  
  svg {
      max-width: 26px;
      max-height: 26px;
  }
  
  span {
      font-size: 14px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      
      svg {
          margin-top: 2px;
      }
  }
`

export const StyledSavePreferences = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items:center;
    ${({isSmall})=> !isSmall ? '10px' : 0};
    //border-top: 1px solid #8c9ba561;
    padding: 4px 8px 0 8px;
    justify-content: center;
    position: relative;
    
    @media screen and (min-width: 769px){
        //padding: 0;
    }
    
    span {
        font-size: 12px;
        font-weight: 400;
        line-height: 162.5%;
        margin:0;
        color: #000000;
        //font-style: italic;
    }
    
    label {
        display: grid;
        grid-template-columns: 1em auto;
        gap: 0.5em;
        align-items: center;
    }
    
    input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: #fff;
        /* Not removed via appearance */
        margin: 4px 0 0 0;
        font: inherit;
        color: #303030;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid #8C9BA5;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
    }
    
    input[type="checkbox"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #8c9ba5;
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    
    input[type="checkbox"]:checked {
        border-color: #8c9ba51a;
    }
    
    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }
    
    input[type="checkbox"]:focus {
        //outline: max(1px, 0.15em) solid currentColor;
        //outline-offset: max(2px, 0.15em);
    }
    
    input[type="checkbox"]:disabled {
        color: gray;
        cursor: not-allowed;
    }
`

export const StyledDisclaimer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10px;
    margin-top: 10px;
    
    a:not(:first-child) {
        border-left: 1px solid #eee;
    }
    
    a {
        text-decoration: none;
        padding-left: 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 162.5%;
        margin: 0;
        color: #303030;
        font-style: italic;
    }
    
    span {
        text-decoration: none;
        padding-left: 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 162.5%;
        margin: 0;
        color: #303030;
        font-style: italic;
    }
    
    .disclaimer-text {
        padding: 0px 10px;
    }
`

const arrowPointAnimation = keyframes`
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    40% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
    60% {
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
`

export const StyledArrowIconWrapper = styled.div`
    position: absolute;
    bottom: 5px;
    right: 0px;
    z-index: 10;
    display: flex;
    margin-left: auto;
    padding: 10px;
    opacity: ${({show})=>show ? 1 : 0};
    pointer-events: ${({show})=>show ? 'auto' : 'none'};
    transition: all 0.3s;
    
    svg {
        opacity: ${({show})=>show ? 1 : 0};
        pointer-events: ${({show})=>show ? 'auto' : 'none'};
        background: #FFFFFF;
        border-radius: 100px;
        animation: ${arrowPointAnimation} 3s infinite;
        transition: all 0.3s;
    }
`

export const StyledBookmarkIcon = styled(StyledFiltersBackButton)`
  position: absolute;
  right: -12px;
  top: -7px;
  min-width: 24px;
  height: 24px;
    
    svg {
        width: 17px;
        height: 17px;
        stroke: #8c9ba561;
        path {
            fill: ${({selected})=> selected ? '#8c9ba561' : '#fff'};
        }
    }
`

