import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  lazy,
  Suspense,
} from "react";
import { loadModules, setDefaultOptions } from "esri-loader";
import {
  StyledApp,
  StyledLoader,
  StyledMapSurveyContainer,
} from "./App-styled";
import Header from "../Header";
import { EMBED_VARIANTS, getConfig } from "../../utils/helper";
import { useTranslation } from "react-i18next";
import { isWidgetDisplayed } from "../../esri/widgets/expandUtils";
import { useMediaQuery } from "react-responsive";
import { ConfigContext } from "../../utils/ConfigContext";
import { useSnackbar } from "../SnackBar";
import SideBar from "../SideBar";
import { useAuth } from "react-oidc-context";
import { addSymbology } from "../../utils/symbologies";
import { view } from "../../utils/API";
import { useDispatch, useSelector } from "react-redux";
import { User } from "oidc-client-ts";
// import Reports from "../Report/new/Reports";
import FeatureTableWrapper from "../FeatureTable/FeatureTableWrapper";
import Dashboard from "../Dashboard/Dashboard";
import MapDisclaimer from "../MapDisclaimer/MapDisclaimer";
import { register } from "swiper/element/bundle";
import "shepherd.js/dist/css/shepherd.css";
import Insights from "../Dashboard/Insights/Insights";
import { setMobileApp } from "../../redux/action/MobileReducer-action";

const MapContainer = lazy(() => import("../MapContainer"));
const Reports = lazy(() => import("../Report/new/Reports"));

const MobileList = lazy(() => import("../MobileList"));
const MobilePopup = lazy(() => import("../MobileApp/MobilePopup/MobilePopup"));
const MobileFilter = lazy(() => import("../MobileApp/MobileFilter"));

register();

const snackOptions = {
  position: "top-center",
  style: {
    backgroundColor: "#FFFFFF",
    color: "#393738",
  },
  closeStyle: {
    color: "#393738",
  },
};

export const MOBILE_SCREENS = Object.freeze({
  MAP: "Map",
  TABLE: "FeatureTable",
  INSIGHTS: "Insights",
});

/**
 * 4.23 gives errors for FeatureTable.filterGeometry accessors (although it works correctly in the end)
 * 4.26: unable to add features with the editor widget
 */
setDefaultOptions({ css: true, version: "4.30" });

const App = () => {
  const { panelPath, showLatestUpdates, printWidget, cycleManagerReducer } =
    useSelector((store) => store);
  const [featureTableActive, setFeatureTableActive] = useState(false);
  const [sideBarIFrame, setSideBarIFrame] = useState(null);
  const [sideBarMobile, setSideBarMobile] = useState(false);
  const [openSnackbar] = useSnackbar(snackOptions);
  const [config, setConfig] = useState();
  const { t, i18n } = useTranslation("common");

  let { activeModule, layersLoading, dashboard, mobileReducer } = useSelector(
    (store) => store
  );

  let [activeLanguage, setActiveLanguage] = useState();

  //mobile states
  const [mobileScreen, setMobileScreen] = useState(MOBILE_SCREENS.MAP);
  const [searchListText, setSearchListText] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  //let [windowWidth, windowHeight] = useWindowSize()
  const auth = useAuth();
  const dispatch = useDispatch();

  //console.debug("Rendering with window dimensions: " + windowWidth + "/" + windowHeight)
  window.isSmall = useMediaQuery({ maxWidth: 768 });
  window.isLarge = useMediaQuery({ minWidth: 1024, maxWidth: 1599 });
  window.isExtraLarge = useMediaQuery({ minWidth: 1600 });
  window.isMedium = !window.isSmall && !window.isLarge && !window.isExtraLarge;
  const ref = useRef();

  useEffect(() => {
    //Initialize configuration
    const conf = getConfig(t, openSnackbar);
    if (
      conf.id === "vanuatu" ||
      (Array.isArray(conf.alias)
        ? conf.alias.includes("vanuatu")
        : conf.alias === "vanuatu")
    ) {
      openSnackbar(
        <div className="layer-error">
          <span>
            This is a demo page for the{" "}
            <span className="layer-error__names">HNPW 2024</span>, showcasing
            the benefits of integrating humanitarian information systems. The
            data provided is combining information from{" "}
            <span className="layer-error__names">
              STOCKHOLM, Dubai Humanitarian, ASYREC, and LogIE
            </span>
            . See the links in the dashboard for further information
          </span>
        </div>,
        60 * 10000
      );
    }

    let language = i18n.language?.toLowerCase();
    if (!isWidgetDisplayed(conf, "LanguageSwitcher") && language !== "en") {
      language = "en";
    }

    if (
      conf.supportedLngs &&
      Array.isArray(conf.supportedLngs) &&
      !conf.supportedLngs.includes(language) &&
      conf.supportedLngs.length > 0
    ) {
      language = conf.supportedLngs[0];
    }

    setActiveLanguage(language || "en");

    window.addEventListener("offline", () => {
      openSnackbar(t("screen.message.offline"), 15000);
    });
    window.addEventListener("online", () => {
      openSnackbar(t("screen.message.online"), 15000);
    });
    window.addEventListener("swUpdatefound", () => {
      openSnackbar(t("screen.message.newVersion"), 15000);
    });

    //After changing language, translated labels should be updated
    view?.map.layers.forEach((l) => {
      addSymbology(l, config, t);
    });
  }, [i18n.language]);

  useEffect(() => {
    if (!activeLanguage) return;
    i18n
      .changeLanguage(activeLanguage, () => {
        const conf = getConfig(t, openSnackbar);
        document.body.style.setProperty("--opsColor", conf.opsColor);
        setConfig(conf);
        const isMobileApp = !!conf.embed || window.isSmall;
        dispatch(setMobileApp(isMobileApp));

        loadModules(["esri/intl"]).then(([intl]) => {
          intl.setLocale(i18n.language);
        });
      })
      .then(() => {});
  }, [activeLanguage]);

  useEffect(() => {
    if (layersLoading) return;

    const handleGtagEvent = (event) => {
      if (window.gtag) {
        const tagName = event?.target?.dataset?.tagName;

        if (!tagName || event?.target?.ariaExpanded === "true") return;
        window.gtag("event", "widget", { widget_name: tagName });
      }
    };

    document.querySelectorAll(".gtag").forEach((element) => {
      element.addEventListener("click", handleGtagEvent);
    });

    return () => {
      document.querySelectorAll(".gtag").forEach((element) => {
        element.removeEventListener("click", handleGtagEvent);
      });
    };
  }, [layersLoading]);

  const isAppEmbed = useMemo(
    () => config?.embed === EMBED_VARIANTS.APP,
    [config]
  );

  const mapContainerClassName = useMemo(() => {
    if (printWidget.open && !printWidget.loading) {
      return "print-enabled";
    } else if (cycleManagerReducer.open) {
      return "hide-all-widgets";
    } else if (dashboard.editorDrawActive) {
      return "editor-draw-active";
    } else {
      return "";
    }
  }, [
    cycleManagerReducer.open,
    printWidget.open,
    printWidget.loading,
    dashboard.editorDrawActive,
  ]);

  if (!config)
    return (
      <StyledLoader
        text="Loading..."
        id="app-loader"
        data-testid="app-loader"
      />
    );

  if (
    !isWidgetDisplayed(config, "LanguageSwitcher") &&
    activeLanguage !== "en"
  ) {
    // setActiveLanguage("en")
    return;
  }

  if (config.supportedLngs && !config.supportedLngs.includes(activeLanguage)) {
    // setActiveLanguage(config.supportedLngs[0])
    return;
  }

  if (sideBarMobile)
    return (
      <SideBar
        sideBarIFrame={sideBarIFrame}
        setSideBarIFrame={setSideBarIFrame}
        setSideBarMobile={setSideBarMobile}
      />
    );

  const user = getUser(auth);
  if (
    config.auth === "wfp" &&
    !user &&
    !auth.isLoading &&
    !auth.isAuthenticated &&
    !auth.activeNavigator
  )
    auth.signinRedirect({
      state: {
        url: window.location.href,
        search: window.location.search,
      },
    });

  if (auth.isLoading)
    return (
      <StyledLoader
        text="This is a secured content. Redirecting user to the authentication page"
        id="app-loader"
      />
    );

  //ESRI authentication can cause the auth object to give error. Check if the url is set, in this case the authorization was triggered by us
  if (auth.error && auth.user?.state?.url)
    return <div>Oops... {auth.error.message}</div>;

  const title = t("ops." + config.id + ".title", config.title);

  if (mobileReducer.isMobileApp) {
    return (
      <StyledApp data-testid="app-main">
        <ConfigContext.Provider
          value={{
            config,
            setConfig,
            searchListText,
            setSearchListText,
          }}
        >
          {!isAppEmbed && view && (
            <Suspense>
              <Reports
                config={config}
                t={t}
                openSnackbar={openSnackbar}
                activeLanguage={activeLanguage}
              />
            </Suspense>
          )}

          <Header
            mobileScreen={mobileScreen}
            setMobileScreen={setMobileScreen}
            setSearchListText={setSearchListText}
            searchListText={searchListText}
            setShowFilters={setShowFilters}
          />
          <StyledMapSurveyContainer
            style={{
              display: mobileScreen === MOBILE_SCREENS.MAP ? "flex" : "none",
            }}
            ref={ref}
            id="mapContainer"
            className={`mobile__app ${
              isAppEmbed ? "mobile__app--embed" : "mobile__app--not-embed"
            }`}
          >
            <Suspense fallback={<StyledLoader />}>
              <MapContainer
                style={{
                  display:
                    mobileScreen === MOBILE_SCREENS.MAP ? "flex" : "none",
                }}
                id="map-container"
                setSideBarIFrame={setSideBarIFrame}
                sideBarIFrame={sideBarIFrame}
                activeModule={activeModule}
                activeLanguage={activeLanguage}
                setActiveLanguage={setActiveLanguage}
                setFeatureTableActive={setFeatureTableActive}
                setSideBarMobile={setSideBarMobile}
                sideBarMobile={sideBarMobile}
              />
            </Suspense>
          </StyledMapSurveyContainer>
          {MOBILE_SCREENS.INSIGHTS === mobileScreen && (
            <div
              style={{
                marginTop: 70,
                width: "100%",
                position: "relative",
                background: "#fff",
                height: "100%",
                display: "flex",
              }}
            >
              <Insights
                expand={true}
                showSidebar={() => {}}
                closeSidebar={() => {}}
              />
            </div>
          )}
          {view && (
            <Suspense>
              <MobileList config={config} mobileScreen={mobileScreen} />
            </Suspense>
          )}
          {view && (
            <Suspense>
              <MobilePopup />
            </Suspense>
          )}
        </ConfigContext.Provider>
      </StyledApp>
    );
  }

  return (
    <StyledApp data-testid="app-main">
      <ConfigContext.Provider value={{ config, setConfig }}>
        <Suspense>
          {view && (
            <Reports
              config={config}
              t={t}
              openSnackbar={openSnackbar}
              activeLanguage={activeLanguage}
            />
          )}
        </Suspense>
        <Header
          setSideBarIFrame={setSideBarIFrame}
          module={activeModule}
          setActiveLanguage={setActiveLanguage}
        />
        <StyledMapSurveyContainer
          ref={ref}
          id="mapContainer"
          className={mapContainerClassName}
        >
          <Suspense fallback={<StyledLoader />}>
            <MapContainer
              id="map-container"
              setSideBarIFrame={setSideBarIFrame}
              sideBarIFrame={sideBarIFrame}
              activeModule={activeModule}
              activeLanguage={activeLanguage}
              setActiveLanguage={setActiveLanguage}
              setFeatureTableActive={setFeatureTableActive}
              setSideBarMobile={setSideBarMobile}
              sideBarMobile={sideBarMobile}
            />
          </Suspense>
          <SideBar
            sideBarIFrame={sideBarIFrame}
            setSideBarIFrame={setSideBarIFrame}
            setSideBarMobile={setSideBarMobile}
          />
          {view && <Dashboard />}
        </StyledMapSurveyContainer>
        {featureTableActive && view && (
          <FeatureTableWrapper openSnackbar={openSnackbar} />
        )}
        {!isAppEmbed && <MapDisclaimer config={config} />}
        <meta property="og:title" content={title} />
        {/*{(panelPath && panelPath !== '' && panelPath !== 'reportAll') && <Panel config={config} t={t} openSnackbar={openSnackbar} />}*/}
      </ConfigContext.Provider>
    </StyledApp>
  );
};
const getUser = (auth) => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${auth.settings.authority}:${auth.settings.client_id}`
  );
  if (!oidcStorage) return null;

  return User.fromStorageString(oidcStorage);
};
export default App;
