import {getFeatureTitle} from "../../../../esri/custom-popup-content";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {AccordionBody, AccordionButton} from "./Accordion/Accordion";

const BatchFieldsHeader = ({
  features,
  editableLayer,
  config,
  activeColor,
  style = {},
}) => {
  const [show, setShow] = useState(false);
  const {t} = useTranslation('common');
  
  return (
    <div style={{
      display:'flex',
      gap:4,
      flexDirection:'column',
      position: 'sticky',
      top: '0px',
      background: '#fff',
      zIndex: 1,
      padding: '0px 14px',
      ...style
    }}>
      <span
        style={{
          fontSize:24,
          color: 'rgb(76,76,76, 1)',
          outline:"none",
          padding:2,
          maxWidth: 'calc(100% - 24px)',
        }}>
        {editableLayer.getLayerTitle(t)} ({features.length})
      </span>
      {
        editableLayer.geometryType === 'point' && (
          <div>
            <AccordionButton
              selected={show}
              color={config}
              label="See selected"
              onSelect={()=>{
                setShow(prev=>!prev)
              }}
            />
            <AccordionBody selected={show}>
              <div style={{display:'flex', flexDirection:'column', gap:4, maxHeight: 150, overflow: 'auto'}}>
                {
                  features.map(item=>(
                    <div style={{display:'flex', flexDirection:'row', gap:4, alignItems:'center'}}>
                      {item.graphic.symbol.url &&
                        <img width={16} height={16} src={item.graphic.symbol.url} alt="feature-symbol"/>}
                      <span style={{color:'rgb(76,76,76, 1)', fontSize:14}}>{getFeatureTitle(item.feature, t)}</span>
                    </div>
                  ))
                }
              </div>
            </AccordionBody>
          </div>
        )
      }
    </div>
  );
};

export default BatchFieldsHeader;
