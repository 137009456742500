import React from "react";
import { ArrowLeftIcon, MOBILE_ICON_COLOR, SearchIcon } from "../../Icons";
import { StyledFiltersBackButton } from "../../MobileApp/MobileFilter/MobileFilter-styled";
import Input from "../../Report/new/Input/Input";
import { SearchWidgetWrapper, StyledIconBox } from "../SearchWidget-styled";

export const SearchBox = ({
  value,
  onChange,
  onBackClick,
  children,
  isMobileApp,
  rotate = "rotate(0deg)",
  ...props
}) => (
  <SearchWidgetWrapper isMobileApp={isMobileApp}>
    <div className="search__box">
      {!!onBackClick && (
        <StyledFiltersBackButton
          style={{
            display: "flex",
            position: "absolute",
            top: "50%",
            transform: `translateY(-50%) ${rotate}`,
            zIndex: 1,
            alignItems: "center",
            height: 34,
            left: 3,
          }}
          onClick={onBackClick}
        >
          <ArrowLeftIcon />
        </StyledFiltersBackButton>
      )}
      <Input
        borderColor="#939393"
        value={value}
        onChange={onChange}
        margin="0px"
        {...props}
      >
        <StyledIconBox>
          <div
            onClick={() => {
              onChange({
                target: {
                  value: "",
                },
              });
            }}
            style={{
              opacity: value ? 1 : 0,
              pointerEvents: value ? "auto" : "none",
              display: "flex",
            }}
          >
            <svg
              stroke="currentColor"
              fill="#939393" //{isMobileApp ? MOBILE_ICON_COLOR : '#6F7072'}
              strokeWidth="0"
              viewBox="0 0 512 512"
              width="18px"
              height="18px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z" />
            </svg>
          </div>
          <SearchIcon
            width="18px"
            height="18px"
            fill={isMobileApp ? MOBILE_ICON_COLOR : "#6F7072"}
          />
        </StyledIconBox>
      </Input>
    </div>
    {children}
  </SearchWidgetWrapper>
);

export default SearchBox;
