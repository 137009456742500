import ProgressBar from "../../Report/new/ProgressBar/ProgressBar";
import {StyledSubmitButton} from "../../Report/new/Footer/Footer-styled";
import React, {useCallback, useContext} from "react";
import {ConfigContext} from "../../../utils/ConfigContext";
import {useDispatch} from "react-redux";
import {setCycleManagerOpen} from "../../../redux/action/CycleManager-action";
import useCycleManager from "../../../hooks/CycleManager/useCycleManager";

const MonitoringProgress = () => {
  const {config} = useContext(ConfigContext);
  const {updatePercent} = useCycleManager(config);
  
  const dispatch = useDispatch();
  
  const openCycleManager = useCallback(()=>{
    dispatch(setCycleManagerOpen(true));
  },[])
  
  return (
    <div>
      <div style={{
        display:'flex',
        gap:10,
        padding:'0px 4px'
      }}>
        <span style={{fontSize:12}}>Current Update status:</span>
        <div style={{flex:1, display:'flex', gap:4, alignItems:'center'}}>
          <span style={{fontSize:12, fontWeight:500}}>{updatePercent}%</span>
          <div
            style={{
              position:'relative',
              height:8,
              width:'100%',
              borderRadius:100,
              overflow:'hidden'
            }}
          >
            <ProgressBar
              color="#0B8B00"
              currentPosition={updatePercent}
              total={100}
              hasTopRadius={false}
            />
          </div>
        </div>
      </div>
      <div style={{display:'flex', justifyContent:'center'}}>
        <StyledSubmitButton style={{color:'black', margin: '10px 0px'}} bg="#D9D9D9"
          onClick={()=>{
            openCycleManager();
          }}
        >
          Open update monitoring
        </StyledSubmitButton>
      </div>
    </div>
  );
};

export default MonitoringProgress;
