import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPrintWidgetOpen } from "../../../redux/action/PrintWidget-action";
import WidgetButton from "../../Header/components/WidgetButton";
import { ConfigContext } from "../../../utils/ConfigContext";
import { useTranslation } from "react-i18next";
import {
  ProgressLoader,
  StyledProgressBar,
} from "../../Report/new/ProgressBar/ProgressBar-styled";
import { PrintLoadingOverlay } from "./PrintButton-styled";
import { stepsValues, transition } from "../SidebarMenu/SidebarMenu";
import { getOpsColor } from "../../../utils/helper";

const PrintButton = () => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");
  const { printWidget, activeModule } = useSelector((state) => state);
  const dispatch = useDispatch();

  const opsColor = useMemo(
    () => getOpsColor(config, activeModule),
    [config, activeModule]
  );
  return (
    <WidgetButton
      data-tag-name="Print-Start"
      title={t("screen.widget.Print.name")}
      type="Print"
      className="onboarding-print gtag"
      isLoading={printWidget.isLoading}
      disabled={printWidget.isLoading}
      onClick={() => {
        if (printWidget.isLoading) return;

        dispatch(setPrintWidgetOpen(true));
      }}
    >
      <PrintLoadingOverlay isLoading={printWidget.isLoading}>
        <div
          style={{
            width: "100%",
            height: 10,
            minHeight: 10,
            borderRadius: 10,
            overflow: "hidden",
            background: "#a8b1b780",
            position: "relative",
          }}
        >
          <ProgressLoader color={opsColor} />
          <StyledProgressBar
            style={{ transition: `all ${transition[printWidget.step]}s` }}
            color={opsColor}
            progress={(100 / 4) * (stepsValues[printWidget.step] ?? 0)}
          />
        </div>
        <span>{t("print.final.loadingSteps." + printWidget.step)}</span>
      </PrintLoadingOverlay>
    </WidgetButton>
  );
};

export default PrintButton;
