import {
  ChecklistSidebarModuleWrapper,
  ChecklistSidebarOverallBox,
  ChecklistSidebarOverallHeader,
  CheckListSidebarWrapper,
} from "../../Monitoring-styled";
import React, {useContext, useMemo, useState} from "react";
import {ConfigContext} from "../../../../../utils/ConfigContext";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ProgressBar from "../../../../Report/new/ProgressBar/ProgressBar";
import SidebarModule from "./SidebarModule";
import {getModules} from "../../../../../hooks/useModuleSelection";
import {CalendarIcon, TargetIcon} from "../ChecklistHeader/ChecklistHeader";
import {formatIntlDateTime, timeAgo} from "../../../../../utils/helper";

const ChecklistSidebar = ({
  onLayerSelect,
  layers,
  editableLayer,
}) => {
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);
  const {layersUpdate, updatePercent, lastCycleUpdate, cycleUpdateDueDate} = useSelector(state=>state.cycleManagerReducer);
  const {config} = useContext(ConfigContext);
  const {t, i18n} = useTranslation('common');
  
  const modules = useMemo(()=>getModules(config, t), [config, t])
  
  function formatTime(time) {
    if (!time) return '';
    
    const utcDate = new Date(time);
    const utcNow = new Date();
    const diffTime = Math.abs(utcNow.getTime() - utcDate.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    const hasTimeLeft = utcNow.getTime() - utcDate.getTime() < 0;
    
    if (diffDays === 1) {
      return hasTimeLeft ? 'tomorrow' : 'yesterday';
    }
    
    if (diffDays > 1) {
      const options = {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      
      const formattedDate = formatIntlDateTime({
        languageCode: i18n.language,
        options: options,
        date: utcDate
      })
      
      return formattedDate + ' UTC';
    }
    
    let result = '';
    if (diffHours > 0) {
      result += `${diffHours} h`;
    }
    if (diffMinutes > 0) {
      if (result) {
        result += `, `;
      }
      result += `${diffMinutes} m`;
    }
    if (!result) {
      result = 'just now';
    } else {
      result += (hasTimeLeft ? '' : ' ago');
    }
    return result;
  }
  
  const format = (value) => {
    if (!value) return '';
    return timeAgo(new Date(value), i18n.language, false)
  }
  
  return (
    <CheckListSidebarWrapper>
      <ChecklistSidebarOverallBox>
        <ChecklistSidebarOverallHeader>
          <ProgressBar bgColor="#f5f7f7" color={config.opsColor} currentPosition={updatePercent} total={100} hasTopRadius={false} />
          <p>
            Situational Info Checklist
          </p>
          <span>
            {updatePercent}%
          </span>
        </ChecklistSidebarOverallHeader>
      </ChecklistSidebarOverallBox>
      <ChecklistSidebarModuleWrapper>
        {
          modules.map((item)=>(
            <SidebarModule
              key={item.module}
              module={item.module}
              moduleName={item.moduleName}
              config={config}
              moduleLayers={item.layers}
              editableLayers={layers}
              t={t}
              onLayerSelect={onLayerSelect}
              editableLayer={editableLayer}
            />
          ))
        }
      </ChecklistSidebarModuleWrapper>
      <div style={{display:'flex', gap:8, flexDirection:'column', textAlign:'right', padding:'10px 14px', borderTop:'1px solid rgba(67,71,85,0.27)'}}>
        <div style={{paddingRight:4, display:'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', gap:4}}>
          <div style={{display:'flex', alignItems:'center', gap:4}}>
            <TargetIcon width="16" height="16" />
            <span style={{fontSize:12}}><span style={{color:'#4b4b4b'}}>due cycle:</span> {formatTime(cycleUpdateDueDate)}</span>
          </div>
          <div style={{display:'flex', alignItems:'center', gap:4}}>
            <CalendarIcon width="16" height="16" />
            <span style={{fontSize:12}}>
                <span style={{color:'#4b4b4b'}}>last cycle:</span> {format(lastCycleUpdate)}
              </span>
          </div>
        </div>
      </div>
    </CheckListSidebarWrapper>
    
    // <CheckListTabWrapper
    //   style={{
    //     flexDirection:'column',
    //     flex:'none'
    //   }}
    // >
    //   {
    //     layers.map(l=>(
    //       <ChecklistTab
    //         selected={layer === l}
    //         onClick={()=>onSelect(l)}
    //         layerUpdate={layersUpdates[l.layerConfig?.id]}
    //       >
    //         <LayerIcon
    //           layer={l}
    //           config={config}
    //           t={t}
    //           opsColor={config.opsColor}
    //           width={22}
    //           height={22}
    //           textColor="#red"
    //         />
    //       </ChecklistTab>
    //     ))
    //   }
    // </CheckListTabWrapper>
  );
};

export default ChecklistSidebar;
