import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {isYesNoDomain, renderCell} from "../../../../utils/helper";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {useTranslation} from "react-i18next";
import LayerTable, {formatToDate, generateColumn, generateRows} from "../../../LayerTable/LayerTable";
import {currAsOfDateFieldName, graphicsLayer, showpublicFieldName} from "../../../../utils/API";
import {
  createFeatureGraphic,
  getFeatureNameField,
  getSituationalBatchFields,
  zoomToFeaturesExtent
} from "../../Editor/helpers";
import {LAYER_EFFECT} from "../../Editor/EditorSwiper/EditorSwiper";
import {EditorContext} from "../../Editor/EditorContextProvider";
import Loader from "../../Editor/Loader/Loader";
import {calculateMissingFeatures, isOutdated} from "../MonitoringDashboard/MonitoringDashboard";
import {
  CheckboxWrapper,
  StyledCheckListTableWrapper,
} from "../Monitoring-styled";
import Message from "../../Editor/Message/Message";
import {useDispatch, useSelector} from "react-redux";
import {addLayersUpdate, setLayersUpdates} from "../../../../redux/action/CycleManager-action";
import {getLayerSymbology} from "../../../../utils/symbologies";

/**
 * Calculates the width of a text in pixels based on its length.
 *
 * @param {string} text - The text to measure.
 * @param {string} font - The font to use for the measurement.
 * @returns {number} The width of the text in pixels.
 */
function calculateTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }
  
  return 0;
}

const MissingIcon = () =>(
  <svg stroke="white" fill="orange" strokeWidth="0" viewBox="0 0 512 512" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
    <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" />
  </svg>
)

export const Outdated = (props) => (
  <svg
    stroke="currentColor"
    fill="#FF6900"
    strokeWidth="0"
    viewBox="0 0 256 256"
    height="14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm-6-82V80a6,6,0,0,1,12,0v56a6,6,0,0,1-12,0Zm16,36a10,10,0,1,1-10-10A10,10,0,0,1,138,172Z"
    />
  </svg>
)

export const UpToDate = (props) => (
  <svg
    stroke="green"
    fill="currentColor"
    strokeWidth="2"
    viewBox="0 0 512 512"
    height="14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="34"
      d="M416 128 192 384l-96-96"
    />
  </svg>
)

const UpToDateButUnknown = () =>(
  <svg stroke="currentColor" fill="green" strokeWidth="0" viewBox="0 0 16 16" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
  </svg>
)

const UpdateCellRenderer = ({params, layer}) => {
  
  const renderIcon = useCallback(() => {
    switch (params.value) {
      case 'missing':{
        return <MissingIcon />
      }
      
      case 'outdated':{
        return <Outdated />
      }
      
      case 'up-to-date':{
        return <UpToDate />
      }
      
      case 'up-to-date-but-status-unknown':{
        return <UpToDateButUnknown />
      }
    }
  },[params.value])
  
  
  return <div>
    {renderIcon()}
  </div>
}

const updateValueFormatter = (feature, hasStatus, lastUpdate, dueDate) => {
  const outdated = isOutdated(feature, lastUpdate, dueDate)
  if (outdated){
    return 'outdated'
  } else {
    if (hasStatus){
      return 'up-to-date'
    } else {
      return 'up-to-date'//'up-to-date-but-status-unknown'
    }
  }
}

const generateUpdateColumn = ({config, layer, t}) =>{
  const defaultColumn = {
    field: 'update',
    headerName: '',
    minWidth: 90,
    maxWidth:40,
    filter: false,
    editable: false,
    cellDataType: undefined,
    cellRenderer: (params)=>{
      return <UpdateCellRenderer layer={layer} params={params} />
    },
  }
  
  const field = {
    name: 'update',
    domain: {
      codedValues:[
        // {
        //   code: 'missing',
        //   name: 'Missing'
        // },
        {
          code: 'outdated',
          name: 'Outdated'
        },
        {
          code: 'up-to-date',
          name: 'Up to date'
        },
        // {
        //   code: 'up-to-date-but-status-unknown',
        //   name: 'Up-to-date but status is unknown'
        // },
      ]
    }
  }
  
  //showing select with values
  // defaultColumn.cellEditor = "agSelectCellEditor"
  const values = [];
  let refData = {};
  field.domain.codedValues.forEach(cv=>{
    values.push(cv.code)
    refData = {
      ...refData,
      [cv.code]: renderCell(field, cv.code, t, config)
    }
  })
  
  //Implemented custom dropdown filter for domains
  // defaultColumn.filter = (params) => <DomainFilter layer={layer} field={field} {...params} />
  
  defaultColumn.refData = refData;
  // defaultColumn.cellEditorParams = {
  //   values
  // }
  return defaultColumn;
}


const Checkbox = ({title, isSelected = false, handleChange, layerConfigId}) => {
  const {layersUpdates} = useSelector(state=>state.cycleManagerReducer)
  const dispatch = useDispatch();
  
  const [showMessage, setShowMessage] = useState(false);
  
  const onChange = useCallback((e)=>{
    const {checked} = e.target;
    
    setShowMessage(checked);
  },[])
  
  const handleSave = useCallback(()=>{
    setShowMessage(false);
    // // setChecked(true);
    // const newLayersId = [...layersUpToDate];
    // newLayersId.push(layerId)
    // dispatch(setLayersUpToDate(newLayersId))
    if (handleChange){
      handleChange();
    }
  },[handleChange])
  
  const checked = useMemo(()=>{
    return Array.isArray(layersUpdates[layerConfigId]?.outdated) ? layersUpdates[layerConfigId].outdated.length === 0 : false;
  },[layerConfigId])
  
  return (
    <>
      {showMessage && (
        <Message
          title="Are you sure?"
          subtitle="Selecting all the features will mark them as updated"
          onSubmit={handleSave}
          onCancel={()=>{
            setShowMessage(false);
          }}
          type="warning"
          position="absolute"
        />
      )}
      <CheckboxWrapper checked={checked}>
        <span>{title} is up to date</span>
        <svg style={{
          // position: 'absolute',
          // right: '0px',
          // top: '-1px',
        }} stroke="green" fill={checked ? "green" : 'var(--opsColor)'} strokeWidth="1" viewBox="0 0 512 512" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"></path><path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path></svg>
        {/*<div>*/}
        {/*  /!*<input onChange={onChange} checked={checked} type="checkbox" />*!/*/}
        {/*  /!*<svg viewBox="0 0 35.6 35.6">*!/*/}
        {/*  /!*  <circle className="background" cx="17.8" cy="17.8" r="17.8"></circle>*!/*/}
        {/*  /!*  <circle className="stroke" cx="17.8" cy="17.8" r="14.37"></circle>*!/*/}
        {/*  /!*  <polyline className="check" points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>*!/*/}
        {/*  /!*</svg>*!/*/}
        {/*</div>*/}
      </CheckboxWrapper>
    </>
  );
};

const ChecklistTable = ({
                          layer,
                          editedFeatures,
                          setEditedFeatures,
                          handleNextLayer,
                          loading,
                          setLoading,
                          gridApi,
                          setGridApi,
                          setFeatures,
                          features,
                          saved
                        }) => {
  
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [layerLoading, setLayerLoading] = useState(false);
  
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation('common');
  const {setBatchUpdateFeatures, batchUpdateFeatures} = useContext(EditorContext);
  const {layersUpdates, cycleUpdateDueDate, lastCycleUpdate, deploymentStatus} = useSelector(state=>state.cycleManagerReducer)
  const dispatch = useDispatch();
  
  // useEffect(()=>{
  //   setColumns([]);
  //   setRows([]);
  // },[layer])
  
  useEffect(() => {
    if (!layer || loading) return;
    
    const newSituationalFields = [...getSituationalBatchFields({editableLayer: layer, config})];
    
    const name = getFeatureNameField(layer);
    const nameField = layer.fields.find(f=>f.name === name);
    const showPublic = layer.fields.find(f=>f.name === showpublicFieldName);
    
    if (!newSituationalFields.some(f=>f.name === showpublicFieldName) && showPublic) {
      newSituationalFields.unshift(showPublic);
    }
    
    if (nameField && !newSituationalFields.some(f=>f.name === nameField.name)){
      newSituationalFields.unshift(nameField);
    }
    
    const fetchLayer = async (fields) => {
      const outFields = [...fields.map(field => field.name), layer.objectIdField];
      
      const query = {
        outFields,
        where: layer.definitionExpression,
        returnGeometry: true
      }
      
      const result = await layer.queryFeatures(query);
      
      const layerUpdate = layersUpdates[layer.id] ? {...layersUpdates[layer.layerConfig.id]} : {};
      layerUpdate.features = result.features;
      layerUpdate.outdated = calculateMissingFeatures(result.features, lastCycleUpdate, cycleUpdateDueDate);
      
      const newLayersUpdates = {
        ...layersUpdates,
        [layer.layerConfig.id]: layerUpdate
      }
      
      dispatch(addLayersUpdate({
        id: layer.layerConfig.id,
        layerUpdate: layerUpdate
      }))
      
      const tableRows = generateRows({
        features: result.features,
        fields: fields,
        layer,
        config,
        t
      })
  
      
      const batchUpdateFeaturesIds = batchUpdateFeatures.map(item=>item.feature.attributes[layer.objectIdField])
      
      if (batchUpdateFeaturesIds.length === 0) {
        zoomToFeaturesExtent(result.features);
      }
      
      const rows = tableRows.map(item=>{
        const rowColorMapFields = Object.keys(item.rowColorMap);
        const emptyValues = rowColorMapFields.filter(fieldName=> !item.feature.attributes[fieldName]);
        
        return {
          ...item,
          featureSelect: batchUpdateFeaturesIds.includes(item[layer.objectIdField]),
          update: updateValueFormatter(item.feature, emptyValues.length === 0, lastCycleUpdate, cycleUpdateDueDate),
        }
      })
      
      setRows(rows);
      setFeatures(result.features);
    }
    
    const columns = newSituationalFields.map(field=>{
      return generateColumn({field, config, layer, t, minWidth: isYesNoDomain(field) ? 66 : 120, maxWidth: isYesNoDomain(field) ? undefined : undefined})
    })
    
    columns.unshift(generateColumn({
      field: {name: 'featureSelect'},
      config,
      layer,
      t,
      isEditable: false,
      maxWidth: 40,
      minWidth: 40
    }))
    
    columns.unshift(generateUpdateColumn({
      config,
      layer,
      t,
    }))
    
    setColumns(columns);
    setEditedFeatures({});
    
    setLayerLoading(true);
    fetchLayer(newSituationalFields)
      .then(()=>{
        setLayerLoading(false);
        if (saved){
          validateAndSaveCycleDate();
        }
      })
      .catch(err=>{
        console.log(err);
        setLayerLoading(false);
        setRows([]);
      })
  },[layer, config, loading])
  
  const validateAndSaveCycleDate = ()=>{
    
    if (deploymentStatus){
      
      let outdatedCount = 0;
      Object.values(layersUpdates).forEach(layerUpdate=>{
        const {outdated} = layerUpdate || {};
        if (outdated) {
          outdatedCount+=outdated.length
        }
      })
      
      const {layer, feature} = deploymentStatus;
      const clone = feature.clone();
      
      clone.attributes['lastcycleupdate'] = new Date().getTime();
      layer.applyEdits({
        updateFeatures: [clone]
      }).then(res=>{
        console.log(res)
      }).catch(err=>{
        console.log(err);
      })
    }
  }
  
  useEffect(()=>{
    if (!layer) return
    if (batchUpdateFeatures.length > 0) {
      layer.effect = LAYER_EFFECT;
      zoomToFeaturesExtent(batchUpdateFeatures.map(item=>item.feature));
    } else if (layer.effect) {
      layer.effect = undefined;
      zoomToFeaturesExtent(features);
    }
  },[batchUpdateFeatures])
  
  const onCellClicked = useCallback((event)=>{
    const {colDef, data, node, value} = event;
    if (colDef.field === 'featureSelect') {
      const objecIdField = layer.objectIdField;
      const feature = data.feature;
      const featureObjectId = feature.attributes[objecIdField];
      const newData = data;
      
      if (!value) {
        newData[colDef.field] = true;
        node.updateData(newData)
        createFeatureGraphic(feature, layer).then(graphic=>{
          graphicsLayer.add(graphic);
          setBatchUpdateFeatures(prev=>prev.concat({
            feature,
            graphic
          }));
        })
      } else {
        
        newData[colDef.field] = false;
        node.updateData(newData)
        setBatchUpdateFeatures(prev=>prev.filter(item=> item.feature.attributes[objecIdField] !== featureObjectId));
        const graphic = graphicsLayer.graphics.find(g=>g.attributes[objecIdField] === featureObjectId);
        
        if (graphic){
          graphicsLayer.remove(graphic);
        }
      }
    }
  },[features, layer])
  
  const onCellValueChanged = useCallback((event) => {
    const {colDef, data, node, value, column, oldValue} = event;
    
    const objectIdField = data.objectIdField;
    const objectId = data[objectIdField];
    
    //to get the latest value of feature saved in the layer
    const feature = features.find(feat=>feat.attributes[objectIdField] === objectId)
    const featureValue = feature.attributes[colDef.field];
    
    let newValue = event.newValue === 'NULL' ? null : event.newValue;
    if (featureValue === newValue){
      const {[objectId]: attributes, ...restEditedFeatures} = editedFeatures;
      const {[colDef.field]: changedField, ...restAttributes} = attributes || {};
      
      if (Object.values(restAttributes).length > 0){
        restEditedFeatures[objectId] = restAttributes;
      }
      
      colDef.cellClass = (p) => {
        const isDirty = restAttributes[p.colDef.field] !== undefined;
        return isDirty ? "ag-cell-dirty" : '';
      };
      
      setEditedFeatures(restEditedFeatures);
    } else {
      if (colDef.cellDataType === 'date'){
        if (newValue) {
          const formattedValue = newValue.getTime(); // - (newValue.getTimezoneOffset() * 60000);
          feature.attributes[colDef.field] = formattedValue;
          data[colDef.field] = formatToDate(newValue);
          newValue = formattedValue;
        } else {
          data[colDef.field] = oldValue;
        }
        node.updateData(data)
      }
      
      const newEditedFeatures = {
        ...editedFeatures,
        [objectId]: {
          ...editedFeatures[objectId],
          [colDef.field]: newValue
        }
      }
      setEditedFeatures(newEditedFeatures)
      
      colDef.cellClass = (p) => {
        const featureId = p.data[objectIdField];
        let isDirty = false;
        if (editedFeatures[featureId]){
          isDirty = editedFeatures[featureId][p.colDef.field] !== undefined
        }
        
        if (p.rowIndex.toString() === node.id){
          isDirty = true;
        }
        return isDirty ? "ag-cell-dirty" : undefined;
      };
    }
    
    event.api.refreshCells({
      columns: [column.getId()],
      rowNodes: [node],
      force: true // without this line, the cell style is not refreshed at the first time
    });
    
  },[features, editedFeatures])
  
  const handleSave = useCallback(()=>{
    const updateFeatures = [];
    const objectIdField = layer.objectIdField;
    
    const newFeatures = features.map(feat=>{
      const newAttributes = editedFeatures[feat.attributes[objectIdField]] || {};
      
      if (Object.keys(newAttributes).length > 0){
        const newFeature = feat.clone();
        newAttributes[objectIdField] = feat.attributes[objectIdField];
        
        if (!newAttributes[currAsOfDateFieldName]) {
          newAttributes[currAsOfDateFieldName] = new Date().getTime();
        }
        newFeature.attributes = newAttributes;
        Object.keys(newAttributes).forEach(k=>{
          feat.attributes[k] = newAttributes[k]
        });
        
        updateFeatures.push(newFeature);
      }
      return feat.clone();
    })
    setLoading(true);
    layer.applyEdits({
      updateFeatures: updateFeatures
    }).then(res=>{
      layer.refresh();
      gridApi.refreshCells();
      setEditedFeatures({});
      setFeatures(newFeatures);
      setLoading(false);
    })
  },[layer, editedFeatures, features])
  
  const handleUpdateAll = useCallback(async ()=>{
    try {
      setLoading(true);
      const layerUpdate = {...layersUpdates[layer.layerConfig.id]};
      const featureToSave = [];
  
  
      layerUpdate.features.forEach(feat=>{
        const clone = feat.clone();
        clone.attributes[currAsOfDateFieldName] = new Date().getTime();
        featureToSave.push(clone);
      })
  
      const res = await layer.applyEdits({
        updateFeatures: featureToSave
      })
  
      layerUpdate.features = featureToSave;
      layerUpdate.outdated = [];
      
      const newLayersUpdate = {
        ...layersUpdates,
        [layer.layerConfig.id]:layerUpdate
      }
      
      dispatch(addLayersUpdate({id: layer.layerConfig.id, layerUpdate}))
      dispatch(setLayersUpdates(newLayersUpdate))
      handleNextLayer();
    } catch (err){
      console.error(err);
    } finally {
      setLoading(false);
    }
  },[layersUpdates, layer, handleNextLayer])
  
  const isEditing = useMemo(()=>{
    return Object.values(editedFeatures).length > 0 || batchUpdateFeatures.length > 0
  },[editedFeatures, batchUpdateFeatures])
  
  // const autoSizeStrategy = {
  //   type: 'fitCellContents',
  //   // If true, the header won't be included when calculating the column widths.
  //   skipHeader:true,
  //   // If not provided will auto-size all columns. Otherwise will size the specified columns.
  // };
  
  if (!layer) return null
  
  return (
    <div style={{
        display:'flex',
        flexDirection:'column',
        height:'100%',
        padding:'0px 10px',
        flex:1,
      }}>
        {loading || Object.keys(layersUpdates).length === 0 && <Loader position="absolute"/>}
        <StyledCheckListTableWrapper>
          <LayerTable
            rows={rows}
            columns={columns}
            layer={layer}
            features={features}
            setGridApi={setGridApi}
            onCellClicked={onCellClicked}
            onCellValueChanged={onCellValueChanged}
            loading={loading || layerLoading}
          />
        </StyledCheckListTableWrapper>
        {/*<StyledCheckListFooter>*/}
        {/*  /!*<div style={{*!/*/}
        {/*  /!*  display:'flex',*!/*/}
        {/*  /!*  alignItems:'center'*!/*/}
        {/*  /!*}}>*!/*/}
        {/*  /!*  <StyledArrowButton*!/*/}
        {/*  /!*    bg={config.opsColor}*!/*/}
        {/*  /!*    onClick={handlePrevLayer}*!/*/}
        {/*  /!*  >*!/*/}
        {/*  /!*    <RightArrowIcon*!/*/}
        {/*  /!*      width={18}*!/*/}
        {/*  /!*      color="#4b4b4b"*!/*/}
        {/*  /!*    />*!/*/}
        {/*  /!*  </StyledArrowButton>*!/*/}
        {/*  */}
        {/*  /!*  <CheckListTabWrapper>*!/*/}
        {/*  /!*    {*!/*/}
        {/*  /!*      layers.map(l=>(*!/*/}
        {/*  /!*        <ChecklistTab*!/*/}
        {/*  /!*          selected={layer === l}*!/*/}
        {/*  /!*          onClick={()=>onSelect(l)}*!/*/}
        {/*  /!*          layerUpdate={layersUpdates[l.layerConfig?.id]}*!/*/}
        {/*  /!*        >*!/*/}
        {/*  /!*          <LayerIcon*!/*/}
        {/*  /!*            layer={l}*!/*/}
        {/*  /!*            config={config}*!/*/}
        {/*  /!*            t={t}*!/*/}
        {/*  /!*            opsColor={config.opsColor}*!/*/}
        {/*  /!*            width={20}*!/*/}
        {/*  /!*            height={20}*!/*/}
        {/*  /!*            textColor="#red"*!/*/}
        {/*  /!*          />*!/*/}
        {/*  /!*        </ChecklistTab>*!/*/}
        {/*  /!*      ))*!/*/}
        {/*  /!*    }*!/*/}
        {/*  /!*  </CheckListTabWrapper>*!/*/}
        {/*  */}
        {/*  /!*  <StyledArrowButton*!/*/}
        {/*  /!*    bg={config.opsColor}*!/*/}
        {/*  /!*    onClick={handleNextLayer}*!/*/}
        {/*  /!*  >*!/*/}
        {/*  /!*    <LeftArrowIcon*!/*/}
        {/*  /!*      width={18}*!/*/}
        {/*  /!*      color="#4b4b4b"*!/*/}
        {/*  /!*    />*!/*/}
        {/*  /!*  </StyledArrowButton>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*  /!*<div style={{display:"flex", gap:8}}>*!/*/}
        {/*  /!*  <Checkbox*!/*/}
        {/*  /!*    title={getLayerTitle(layer,t)}*!/*/}
        {/*  /!*    layerConfigId={layer.layerConfig?.id}*!/*/}
        {/*  /!*    handleChange={handleUpdateAll}*!/*/}
        {/*  /!*  />*!/*/}
        {/*  /!*  <StyledSubmitButton disable={Object.keys(editedFeatures).length === 0} onClick={handleSave}>Save</StyledSubmitButton>*!/*/}
        {/*  /!*  <StyledSubmitButton disable={batchUpdateFeatures.length === 0} onClick={handleNext}>Batch Update{batchUpdateFeatures.length > 0 ? ` (${batchUpdateFeatures.length})` : ''}</StyledSubmitButton>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*</StyledCheckListFooter>*/}
      </div>
  );
};

export default ChecklistTable;
