import {getFieldDefaultValue} from "../components/Dashboard/Editor/helpers/helpers";
import {EditType} from "../components/Dashboard/Editor/EditorSwiper/EditorSwiper";
import {currAsOfDateFieldName, graphicsLayer, isdeletedFieldName, view} from "../utils/API";
import {useContext, useState} from "react";
import {EditorContext, UPDATE_STATUS_TYPE} from "../components/Dashboard/Editor/EditorContextProvider";
import {useTranslation} from "react-i18next";
import useCustomSnackbar from "./useCustomSnackbar";
import {loadModules} from "esri-loader";
import {useForm} from "react-hook-form";
import {batchUpdateFields} from "../components/Dashboard/Editor/EditorFields/EditorSituationalFields";

const useEditor = ({editType, config, onSave, goBack, openSnackbar}) => {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation('common');
  const {
    highlightFeature,
    attachments,
    addedPoints,
    batchUpdateFeatures,
    situationalUpdateType,
    editableLayer,
  } = useContext(EditorContext);
  const methods = useForm();
  /**
   * Returns the geometry that will be added as a new feature to the map
   * @returns {*}
   */
  const getGeometry = () => {
    if (!addedPoints || addedPoints.length === 0) return;
    
    if (editableLayer.geometryType === "point"){
      return addedPoints[0]
      
    } else if (editableLayer.geometryType === "polyline"){
      return addedPoints[0]
      
    } else if (editableLayer.geometryType === "polygon") {
      return addedPoints[0]
    }
  }
  
  const confirmStatus = async () => {
    try {
      const newBatchUpdateFeatures = batchUpdateFeatures.map(item=>item.feature);
      const today = new Date();
      
      newBatchUpdateFeatures.forEach(feat=>{
        feat.attributes[currAsOfDateFieldName] = today.getTime();
      })
      
      setLoading(true);
      
      await editableLayer.applyEdits({
        updateFeatures: newBatchUpdateFeatures
      });
      editableLayer.refresh();
      onEditComplete();
    } catch (err){
      openSnackbar(t("screen.message.error"), 15000);
      setLoading(false);
    }
  }
  
  const doEdit = async (data, event) => {
    if (situationalUpdateType === UPDATE_STATUS_TYPE.CURRENT){
      
      return confirmStatus()
    }
    
    const inputs = document.querySelectorAll("#editorFields");
    const newBatchUpdateFeatures = batchUpdateFeatures.map(item=>item.feature);
    
    const atts = {...data};
    const fieldTypes = {};
    editableLayer.fields.forEach((f) =>{
      const defaultValue = getFieldDefaultValue(f, config);
      fieldTypes[f.name] = f.type;
      
      if (defaultValue && !atts[f.name]) {
        atts[f.name] = defaultValue
      }
    })
    
    Object.keys(atts).forEach(fieldName=>{
      let val = atts[fieldName];
      if (fieldTypes[fieldName] === 'date' && val){
        const today = new Date();
        const utc_hour = today.getUTCHours();
        const utc_minutes = today.getUTCMinutes();
        const utc_seconds = today.getUTCSeconds();
  
        const selectedDate = new Date(val);
        selectedDate.setUTCHours(utc_hour, utc_minutes, utc_seconds);
        val = selectedDate.getTime()
      }
  
      if (val === '')
        val = null
  
      if (editType === EditType.edit){
        if (highlightFeature) {
          highlightFeature.attributes[fieldName] = val;
        } else if (newBatchUpdateFeatures.length > 0) {
          newBatchUpdateFeatures.forEach(feature=>{
            if (val){
              feature.attributes[fieldName] = val;
            } else if (batchUpdateFields.includes(fieldName)) {
              feature.attributes[fieldName] = val;
            }
          })
        }
      } else {
        atts[fieldName] = val;
      }
    })
    // console.log(atts);
    // Array.from(inputs).forEach( (el) => {
    //   const fieldName = el.getAttribute("data-field-name")
    //   let val = el.value;
    //   if (el.value && el.getAttribute("type") === "date"){
    //     const today = new Date();
    //     const utc_hour = today.getUTCHours();
    //     const utc_minutes = today.getUTCMinutes();
    //     const utc_seconds = today.getUTCSeconds();
    //
    //     const selectedDate = new Date(val);
    //     selectedDate.setUTCHours(utc_hour, utc_minutes, utc_seconds);
    //     val = selectedDate.getTime()
    //   }
    //
    //   if (el.value === '')
    //     val = null
    //
    //   if (editType === EditType.edit){
    //     if (highlightFeature) {
    //       highlightFeature.attributes[fieldName] = val;
    //     } else if (newBatchUpdateFeatures.length > 0) {
    //       newBatchUpdateFeatures.forEach(feature=>{
    //         if (val){
    //           feature.attributes[fieldName] = val;
    //         }
    //       })
    //     }
    //   } else {
    //     atts[fieldName] = val
    //   }
    // })

    const geometry = getGeometry();
    
    if (editType === EditType.create && !geometry)
      return

    if (editType === EditType.edit && editableLayer.geometryType !== 'point' && highlightFeature){
      highlightFeature.geometry = geometry;
    }
    
    setLoading(true);
    
    try {
      await editableLayer.applyEdits({
        addFeatures: (editType === EditType.create) ? [{geometry: geometry, attributes: atts}] : [],
        updateFeatures: (editType === EditType.edit) ? highlightFeature ? [highlightFeature] : newBatchUpdateFeatures : []
      });
      
      if (attachments.files.length > 0) {
        const promises = [];
        attachments.files.forEach((file)=>{
          const formData = new FormData();
          formData.set('attachment', file)
          promises.push(editableLayer.addAttachment(highlightFeature, formData))
        })
      
        await Promise.all(promises);
      }
      editableLayer.refresh();
      onEditComplete();
    } catch (err) {
      console.log(err)
      openSnackbar(t("screen.message.error"), 15000);
      setLoading(false);
    }
  }
  
  const onEditComplete = () => {
    view.whenLayerView(editableLayer).then((layerView) => {
      loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils])=>{
        reactiveUtils.whenOnce(() => !layerView.updating).then(() => {
          setLoading(false);
          // graphicsLayer.removeAll();
          openSnackbar(t("screen.message.batchEditorReady"), 105000)
          methods.reset();
          if (onSave){
            onSave()
          }
        })
      })
    })
  }
  
  const getIsDeletedField = () => {
    if (!editableLayer)
      return
    
    return editableLayer.fields.filter((f) => f.name === isdeletedFieldName)[0]
  }
  
  const doDelete = () => {
    if (!getIsDeletedField()){
      if (goBack){
        goBack();
      }
      return
    }
    
    const doDelete = highlightFeature.attributes[isdeletedFieldName] !== 1;
    highlightFeature.attributes[isdeletedFieldName] = doDelete ? 1 : 2
    setLoading(true);
    editableLayer.applyEdits({
      updateFeatures: [highlightFeature]
    }).then(() => {
      editableLayer.refresh();
      view.graphics.removeAll()
      onEditComplete()
    }).catch(err=>{
      openSnackbar(t("screen.message.error"), 15000);
      setLoading(false);
    })
  }
  
  return {
    doEdit,
    loading,
    doDelete,
    setLoading,
    methods,
    confirmStatus,
    getIsDeletedField
  };
};

export default useEditor;
