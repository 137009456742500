import { useTranslation } from "react-i18next";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CloseButton,
  FeatureIconContainer,
  FeatureTableContainer,
  FeatureTableTab,
  FeatureTableTooltip,
  SectionItem,
  StyledTableContentWrapper,
  StyledTableTopBar,
} from "./FeatureTable-styled";
import {
  getFieldsByPattern,
  getOpsColor,
  hasUrlPattern,
  ROLE_EDITOR,
} from "../../utils/helper";
import { ConfigContext } from "../../utils/ConfigContext";
import { useDispatch, useSelector } from "react-redux";
import { getLayerSymbology } from "../../utils/symbologies";
import { loadModules } from "esri-loader";
import DownloadButton from "./DownloadButton/DownloadButton";
import { setAnimateFeatureTable } from "../../redux/action/Dashboard-action";
import CustomSwitch from "../CustomSwitch/CustomSwitch";
import TableGrid from "./TableGrid/TableGrid";
import { getLayerTitle } from "../../esri/custom-popup-content";
import { generateColumn, getCellValue } from "../LayerTable/LayerTable";
import LayerIcon from "../Icons/LayerIcon";

export const getColumnTemplate = (label, fieldSet, t, layer, config) => {
  const columns = {
    headerName: label,
    children: [],
  };

  columns.children = fieldSet.map((field) => {
    const defaultColumn = generateColumn({
      field,
      config,
      layer,
      t,
      isEditable: field.name !== "featureSelect",
      minWidth: field.alias ? 200 : 50,
    });
    return defaultColumn;
  });

  return columns;
};

const FeatureTable = ({ layersIds, count, view }) => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation("common");

  const [visibleLayers, setVisibleLayers] = useState([]);
  const [currLayer, setCurrLayer] = useState();

  const [columns, setColumns] = useState(null);
  const [row, setRow] = useState(null);
  const [features, setFeatures] = useState([]);
  const [outFields, setOutFields] = useState([]);

  const [filterByExtent, setFilterByExtent] = useState(true);

  const [featureTableExpanded, setFeatureTableExpanded] = useState(false);
  const [featureTableFullScreenActive, setFeatureTableFullScreenActive] =
    useState(false);
  const { filters, dashboard } = useSelector((state) => state);
  const [gridApi, setGridApi] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  const gridRef = useRef();
  const featureTableRef = useRef();
  const visibleTimer = useRef(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("datatable") === "open") {
      setFeatureTableExpanded(true);
      setFeatureTableFullScreenActive(true);
      featureTableRef.current.style.flexBasis = `${window.innerHeight}px`;
    }
  }, [t]);

  useEffect(() => {
    clearTimeout(visibleTimer.current);
    visibleTimer.current = setTimeout(() => {
      let layers = view.map.layers
        .filter((layer) => layersIds[layer.id])
        .reverse();
      // .sort((a, b) => {
      // 	if (!a || !a.title || !b || !b.title)
      // 		return 0
      // 	else
      // 		return a.title.localeCompare(b.title)
      // });
      if (dashboard.editableLayer) {
        const editableLayer = dashboard.editableLayer;
        layers = layers.filter((l) => l.id === editableLayer.id);
      }

      const layerArray = layers.toArray();

      const selectedLayerIndex = layerArray.findIndex(
        (l) => l.id === currLayer?.id
      );
      const layerIndex = selectedLayerIndex > -1 ? selectedLayerIndex : 0;

      setVisibleLayers(layers.toArray());
      setCurrLayer(layers.toArray()[layerIndex]);
      setActiveIndex(layerIndex);
    }, 250);
  }, [layersIds, count, dashboard.editableLayer]);

  // const getColumnTemplate = (label, fieldSet, t, layer) =>{
  // 	const columns = {
  // 		headerName: label,
  // 		children: [],
  // 	}
  //
  // 	columns.children = fieldSet.map((field) => {
  // 		const defaultColumn = {
  // 			field: field.name,
  // 			headerName: field.alias ? t('layer.fieldAlias.' + field.name, field.alias) : '',
  // 			minWidth: field.alias ? 200 : 50,
  // 			filter: !!field.alias,
  // 			// floatingFilter:true,
  // 			editable: (config.role === ROLE_EDITOR) && (field.name !== 'showpublic' && field.name !== 'featureSelect'),
  // 			wrapText:true,
  // 			// autoHeight: false,
  // 			cellDataType: field.type === 'date' ? 'date' : undefined,
  // 			cellRenderer: (params)=>{
  // 				return getCellRenderer(field, params, layer)
  // 			},
  // 		}
  //
  // 		//showing select with values
  // 		if (field.domain && field.name !== 'showpublic') {
  // 			defaultColumn.cellEditor = "agSelectCellEditor"
  // 			const values = [];
  // 			let refData = {};
  // 			field.domain.codedValues.forEach(cv=>{
  // 				values.push(cv.code)
  // 				refData = {
  // 					...refData,
  // 					[cv.code]: renderCell(field, cv.code, t, config)
  // 				}
  // 			})
  //
  // 			if (field.nullable) {
  // 				refData['NULL'] = t('screen.domain.unspecified', "Unspecified");
  // 				values.push('NULL');
  // 			}
  // 			//Implemented custom dropdown filter for domains
  // 			defaultColumn.filter = (params) => <DomainFilter layer={layer} field={field} {...params} />
  //
  // 			defaultColumn.refData = refData;
  //
  // 			if (config.role === ROLE_EDITOR) {
  // 				defaultColumn.cellEditorParams = {
  // 					values
  // 				}
  // 			}
  // 		}
  // 		return defaultColumn;
  // 	})
  //
  // 	return columns
  // }

  const defExpressionRef = useRef();
  useEffect(() => {
    if (!featureTableExpanded || !currLayer) return;
    queryLayer(currLayer, filterByExtent);
    defExpressionRef.current?.remove();
    defExpressionRef.current = currLayer.watch("definitionExpression", () => {
      queryLayer(currLayer, filterByExtent);
    });

    return () => {
      defExpressionRef.current?.remove();
    };
  }, [currLayer, config, filterByExtent, featureTableExpanded, filters]);

  useEffect(() => {
    if (featureTableExpanded) {
      closeTooltip();
    }
  }, [featureTableExpanded]);

  const queryLayer = async (layer, filterByExtent) => {
    const { layerConfig } = layer || {};
    if (!layerConfig) {
      setColumns([]);
      setRow([]);
      return;
    }

    const visibilityFields = getFieldsByPattern(
      currLayer,
      layerConfig.visibilityFields,
      config.role !== ROLE_EDITOR
    );
    const situationalFields = getFieldsByPattern(
      currLayer,
      layerConfig.situationalFields,
      config.role !== ROLE_EDITOR
    );
    const baselineFields = getFieldsByPattern(
      currLayer,
      layerConfig.baselineFields,
      config.role !== ROLE_EDITOR
    );

    const efi = currLayer.editFieldsInfo;
    const editFields = efi
      ? getFieldsByPattern(
          currLayer,
          [
            efi.creationDateField,
            efi.creatorField,
            efi.editDateField,
            efi.editorField,
          ],
          config.role !== ROLE_EDITOR
        )
      : [];

    const fields = [
      ...visibilityFields,
      ...situationalFields,
      ...baselineFields,
      ...editFields,
    ];
    const outFields = fields.map((field) => field.name);

    const tableTemplate = {
      columnTemplates: [
        getColumnTemplate(
          "",
          [
            {
              name: "featureSelect",
            },
          ],
          t,
          layer,
          config
        ),
        getColumnTemplate(
          t("screen.widget.Editor.visibilityFields.label"),
          visibilityFields,
          t,
          layer,
          config
        ),
        getColumnTemplate(
          t("screen.widget.Editor.baselineFields.label"),
          baselineFields,
          t,
          layer,
          config
        ),
        getColumnTemplate(
          t("screen.widget.Editor.situationalFields.label"),
          situationalFields,
          t,
          layer,
          config
        ),
        getColumnTemplate(
          t("screen.widget.Editor.editFields.label"),
          editFields,
          t,
          layer,
          config
        ),
      ],
    };

    const columns = [];
    tableTemplate.columnTemplates.forEach((item) => {
      columns.push(...item.children);
    });

    setColumns(columns);

    if (layerConfig.featureTable.exportCoordinates) {
      outFields.push("latitude", "longitude");
    }
    setOutFields(outFields);
    const query = {
      outFields: [...fields.map((field) => field.name), layer.objectIdField],
      where: layer.definitionExpression,
      returnGeometry: layerConfig.featureTable.exportCoordinates,
    };

    if (filterByExtent) {
      query.geometry = filterByExtent ? view.extent : undefined;
      query.spatialRelationship = "intersects";
    }

    try {
      const result = await layer.queryFeatures(query);
      const row = [];
      const { colorMap } = getLayerSymbology(layer, config) || {};
      const rowColorMap = {};

      if (colorMap?.field) {
        rowColorMap[colorMap.field] = colorMap.default;
      }

      if (Array.isArray(colorMap?.fields)) {
        colorMap.fields.forEach((field) => {
          rowColorMap[field] = colorMap.default;
        });
      }

      result.features.forEach((feature) => {
        const rowData = {
          featureSelect: false,
          [layer.objectIdField]: feature.attributes[layer.objectIdField],
          objectIdField: layer.objectIdField,
          rowColorMap,
          feature,
        };

        fields.forEach((field) => {
          let cellValue = getCellValue({
            field,
            value: feature.attributes[field.name],
            config,
            t,
          });
          if (field.type === "date" && !feature.attributes[field.name]) {
            cellValue = "";
          }

          if (hasUrlPattern(feature.attributes[field.name])) {
            cellValue = feature.attributes[field.name];
          }

          rowData[field.name] = cellValue === null ? "NULL" : cellValue;
        });

        row.push(rowData);
      });

      setFeatures(result.features);
      setRow(row);
    } catch (err) {
      console.log(err);
      setColumns([]);
      setRow([]);
    }
  };

  const viewMovingRef = useRef({
    when: undefined,
    timer: undefined,
  });

  useEffect(() => {
    viewMovingRef.current.when?.remove();
    clearTimeout(viewMovingRef.current.timer);

    if (!filterByExtent) return;
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
      viewMovingRef.current.timer = setTimeout(() => {
        viewMovingRef.current.when = reactiveUtils.when(
          () => !!view?.stationary,
          () => {
            // Get the new extent of view/map whenever map is updated.
            if (view.extent) {
              // Filter out and show only the visible features in the feature table.
              queryLayer(currLayer, filterByExtent);
            }
          },
          { initial: false }
        );
      }, 250);
    });
    return () => {
      viewMovingRef.current.when?.remove();
      clearTimeout(viewMovingRef.current.timer);
    };
  }, [currLayer, filterByExtent, config]);

  const handleSwitchChange = useCallback((value) => {
    setFilterByExtent(value);
  }, []);

  let mapDiv = document.getElementById("mapContainer");

  const onArrowClick = useCallback(() => {
    if (!featureTableRef.current) return;
    featureTableRef.current.style.transition = "all 0.5s";
    if (!featureTableExpanded) {
      setFeatureTableExpanded(true);
      setFilterByExtent(true);
    } else {
      featureTableRef.current.style.removeProperty("flex-basis");
      setFeatureTableExpanded(false);
      setFeatureTableFullScreenActive(false);
    }
  }, [featureTableRef, featureTableExpanded]);

  const doDrag = (e) => {
    e.preventDefault();
    if (!featureTableRef.current) return;

    const appHeight = window.innerHeight;
    const headerHeight = document.getElementById("header")?.offsetHeight || 0;
    const disclaimerHeight =
      document.getElementById("disclaimer").offsetHeight || 0;
    if (!mapDiv) mapDiv = document.getElementById("mapContainer");
    mapDiv.style.cursor = "n-resize";
    featureTableRef.current.style.transition = "none";
    const tableFlexBasis = appHeight - e.clientY - disclaimerHeight;
    const isFullScreen =
      tableFlexBasis >= appHeight - headerHeight - disclaimerHeight - 5;
    if (tableFlexBasis > 13 && !featureTableRef.current.style.flexBasis) {
      setFeatureTableExpanded(true);
      // setFilterByExtent(true)
    }
    //when table height is near the bottom
    if (tableFlexBasis <= 13) {
      featureTableRef.current.style.removeProperty("flex-basis");
      setFeatureTableExpanded(false);
      setFeatureTableFullScreenActive(false);
      return;
    }

    featureTableRef.current.style.flexBasis = `${
      isFullScreen ? appHeight : tableFlexBasis
    }px`;
    setFeatureTableFullScreenActive(isFullScreen);
  };

  useEffect(() => {
    setFilterByExtent(!featureTableFullScreenActive);
  }, [featureTableFullScreenActive]);

  const stopDrag = () => {
    if (mapDiv) mapDiv.style.cursor = "default";
    if (featureTableRef.current)
      featureTableRef.current.style.cursor = "default";
    document.documentElement.removeEventListener("mousemove", doDrag, false);
    document.documentElement.removeEventListener("mouseup", stopDrag, false);
  };

  const initDrag = (e) => {
    e.preventDefault();
    if (e.target.id !== "resizer") return;
    document.documentElement.addEventListener("mousemove", doDrag, false);
    document.documentElement.addEventListener("mouseup", stopDrag, false);
  };

  const getTabTitle = (layer) => {
    return (
      <LayerIcon
        config={config}
        layer={layer}
        t={t}
        opsColor={opsColor}
        width={22}
        height={22}
        selected={false}
        textColor="#000"
      />
    );
  };

  const closeTooltip = useCallback(() => {
    dispatch(setAnimateFeatureTable(false));
  }, []);

  const showTooltip = useMemo(() => {
    return dashboard.animateFeatureTable && !featureTableExpanded;
  }, [featureTableExpanded, dashboard.animateFeatureTable]);

  const onTabSelectionChange = useCallback(
    (index) => {
      setActiveIndex(index);
      const layer = visibleLayers[index];
      setRow(null);
      setColumns(null);
      setFeatures([]);
      setCurrLayer(layer);
    },
    [visibleLayers]
  );

  const opsColor = useMemo(() => getOpsColor(config), [config]);

  return (
    <FeatureTableContainer
      isExpanded={featureTableExpanded}
      isDisclaimerShown={true}
      ref={featureTableRef}
      id="ftc"
      opsColor={opsColor}
      animate={showTooltip}
    >
      {showTooltip && (
        <FeatureTableTooltip>
          <span>You can also use data table to edit multiple features</span>
          <button onClick={closeTooltip}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="10"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
            </svg>
          </button>
        </FeatureTableTooltip>
      )}
      <CloseButton
        onClick={onArrowClick}
        style={{
          transform: "translateY(-50%) rotate(270deg)",
          right: "50%",
          top: "-12px",
          display: "flex",
          flexDirection: "column",
          gap: 6,
          width: "fit-content",
          height: "fit-content",
          padding: 0,
          borderLeft: "none",
        }}
      >
        <SectionItem
          className="gtag"
          data-tag-name="Data-Table"
          style={{
            minHeight: 48,
            padding: "4px",
          }}
        >
          <svg
            style={{
              transform: "rotate(90deg)",
              width: 16,
              height: 16,
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
          >
            <path d="M0 1v14h16V1zm1 5h2v2H1zm8 2H4V6h5zm0 1v2H4V9zM1 9h2v2H1zm0 5v-2h2v2zm3 0v-2h5v2zm11 0h-5v-2h5zm0-3h-5V9h5zm0-3h-5V6h5zm0-3H1V2h14z" />
          </svg>
        </SectionItem>
      </CloseButton>
      <StyledTableContentWrapper
        opsColor="var(--opsColor)"
        isExpanded={featureTableExpanded}
      >
        <StyledTableTopBar
          isExpanded={featureTableExpanded}
          key="resizer"
          id="resizer"
          onMouseDown={(e) => {
            initDrag(e);
          }}
        >
          <div
            id="tab_icons"
            key="tab_icons"
            style={{
              opacity: featureTableExpanded ? 1 : 0,
              pointerEvents: featureTableExpanded ? "auto" : "none",
              display: "flex",
              justifyContent: "flex-end",
              marginLeft: "1em",
              fontSize: "12px",
              fontFamily: "'Open Sans',sans-serif !important",
              gap: 10,
            }}
          >
            <FeatureIconContainer
              isTableFullScreen={featureTableFullScreenActive}
            >
              <CustomSwitch
                checked={filterByExtent}
                onChange={handleSwitchChange}
                labelA={t("screen.widget.common.filterByMapExtent")}
              />
            </FeatureIconContainer>
            {featureTableExpanded && (
              <DownloadButton
                t={t}
                config={config}
                features={features}
                fields={outFields}
                layer={currLayer}
                gridApi={gridApi}
              />
            )}
          </div>
        </StyledTableTopBar>
        {featureTableExpanded && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 10,
                overflow: "scroll",
              }}
            >
              {visibleLayers.map((layer, index) => (
                <FeatureTableTab
                  key={layer.id}
                  selected={activeIndex === index}
                  onClick={() => onTabSelectionChange(index)}
                  className="tab__title"
                >
                  {getTabTitle(layer)}
                  <span className="text-elipsis">
                    {getLayerTitle(layer, t)}
                  </span>
                </FeatureTableTab>
              ))}
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              {visibleLayers.map(
                (layer, index) =>
                  index === activeIndex && (
                    <div style={{ height: "100%" }} key={layer.id}>
                      {columns && featureTableExpanded && (
                        <TableGrid
                          ref={gridRef}
                          row={row}
                          columns={columns}
                          layer={layer}
                          features={features}
                          setGridApi={setGridApi}
                        />
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </StyledTableContentWrapper>
    </FeatureTableContainer>
  );
};

export default FeatureTable;
