import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {currAsOfDateFieldName, showpublicFieldName} from "../../../../utils/API";
import {StyledButton, StyledTitle} from "../../Editor/Editor-styled";
import {getLayerSymbology} from "../../../../utils/symbologies";
import {iconNameToIcon} from "../../../Icons/layerSvg/clusterAssets";
import {LayerIconWrapper} from "../../../ManualWidgets/ManualWidget-styled";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {StyledSubmitButton} from "../../../Report/new/Footer/Footer-styled";
import {BreakdownCard} from "../Monitoring-styled";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import Loader from "../../Editor/Loader/Loader";

export const isOutdated = (feature, startDateTime, endDateTime) => {
  const now = feature.attributes[currAsOfDateFieldName];
  
  let isPublic = true;
  if (showpublicFieldName in feature.attributes){
    isPublic = feature.attributes[showpublicFieldName] === 1;
  }
  
  return isPublic && (now === null || !(now >= startDateTime && now <= endDateTime || now > endDateTime));
}

export const calculateMissingFeatures = (features, lastDateTime ,dueDateTime) => {
  const missingFeatures = features.filter(f=>{
    return isOutdated(f, lastDateTime, dueDateTime);
  })
  
  return missingFeatures;
}

const MonitoringDashboard = ({layers, handleNext, layerUpdates, setLayerUpdates}) => {
  const [loading, setLoading] = useState(false);
  const [openSnackbar] = useCustomSnackbar();
  const {t} = useTranslation('common');
  const {config} = useContext(ConfigContext);
  useEffect(() => {
    const fetchFeatures = async () =>{
      try {
        const promises = [];
        const yesterday = new Date() - (24 * 60 * 60 * 1000);
        layers.forEach(l=>{
          promises.push(l.queryFeatures());
        })
  
        const results = await Promise.allSettled(promises);
        const layerUpdates = {};
        results.forEach((res, index)=>{
          const {status, value} = res
          const layer = layers.toArray()[index];
          const layerUpdate = {
            missing: [],
            features: [],
            status,
            layer
          }
    
          if (status === 'fulfilled'){
            const {features} = value;
            
            const missingFeatures = calculateMissingFeatures(features, yesterday);
            layerUpdate.features = features;
            layerUpdate.missing = missingFeatures
          }
  
          layerUpdates[layer.id] = layerUpdate
        })
        
        setLayerUpdates(layerUpdates);
      } catch (err){
        console.log(err);
      }
    }
  
    fetchFeatures();
  },[layers])
  
  const handleOpenChecklist = useCallback(()=>{
  
  },[])
  
  const handleMarkAsSame = useCallback(async ()=>{
    setLoading(true);
    try {
      const promises = [];
      const newLayerUpdates = {};
      Object.keys(layerUpdates).map(key=>{
        const item = layerUpdates[key];
        const {features, layer} = item;
        const objectIdField = layer.objectIdField;
        
        const newFeatures = features.map(feat=>{
          const newFeature = feat.clone();
          newFeature.attributes = {
            [currAsOfDateFieldName]: new Date().getTime(),
            [objectIdField]: newFeature.attributes[objectIdField]
          }
          
          return newFeature;
        })
    
    
        promises.push(layer.applyEdits({
          updateFeatures: newFeatures
        }))
        newLayerUpdates[key] = {
          missing: [],
          features: newFeatures,
          layer
        }
      })
  
      await Promise.all(promises);
      setLayerUpdates(newLayerUpdates);
    } catch (err){
      if (err.message){
        openSnackbar(err.message)
      } else {
        openSnackbar(t("screen.message.error"), 15000);
      }
    } finally {
      setLoading(false);
    }
    
  },[layerUpdates])
  
  const getTabTitle = (layer) => {
    const symbology = getLayerSymbology(layer, config);
    if (symbology){
      const icon = iconNameToIcon(symbology.defaultIcon);
      return icon ? <LayerIconWrapper width={60} height={60} fill={config.opsColor} dangerouslySetInnerHTML={{__html:icon}} /> : null
    }
    return layer.getLayerTitle(t)[0]
  }
  
  const updatesArray = useMemo(()=> Object.values(layerUpdates),[layerUpdates])
  
  return (
    <div style={{padding: '10px 14px', height:'100%', display:'flex', flexDirection:'column', boxSizing:'border-box'}}>
      {
        loading && <Loader />
      }
      <div>
        <div>
          <StyledTitle>
            Updates breakdown
          </StyledTitle>
          <div style={{position:'relative', minHeight:150}}>
            {
              updatesArray.length > 0 ? <>
                <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', gap:10, position:'relative'}}>
                  {updatesArray.map((item)=>{
                    return <BreakdownCard>
                      <div>
                        {item.layer.layerConfig.titleLabel !== "roadsSituational" ? getTabTitle(item.layer) : <svg stroke="currentColor" fill={config.opsColor} strokeWidth="0" viewBox="0 0 24 24" height="60" width="60" xmlns="http://www.w3.org/2000/svg"><g id="Route"><path d="M21.792,17.086c-.58-.58-1.16-1.17-1.75-1.75-.08-.08-.16-.17-.25-.25a.492.492,0,0,0-.7,0,.5.5,0,0,0,0,.71l1.14,1.14H9.282a2.22,2.22,0,0,1,0-4.44h3a3.215,3.215,0,1,0,0-6.43H7.012a2.5,2.5,0,1,0,0,1h5.27a2.215,2.215,0,1,1,0,4.43h-3a3.22,3.22,0,1,0,0,6.44h10.96l-.9.9c-.09.08-.17.17-.25.25a.5.5,0,0,0,0,.71.511.511,0,0,0,.7,0l1.75-1.75.25-.25A.5.5,0,0,0,21.792,17.086ZM4.562,8.066a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,4.562,8.066Z"></path></g></svg>}
                      </div>
                      <div style={{display:"flex",flexDirection:'column'}}>
                        <span className="tag" style={{fontSize:18}}>{item.features.length - item.missing.length}/{item.features.length}</span>
                        <span style={{fontSize:10}}>{item.layer.getLayerTitle(t)}</span>
                      </div>
                    </BreakdownCard>
                  })}
                </div>
                <StyledButton style={{margin:'20px auto 0px auto', gap:4}} onClick={handleMarkAsSame}>
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path><path d="M11 8v5l4.25 2.52.77-1.28-3.52-2.09V8H11zm10 2V3l-2.64 2.64A8.937 8.937 0 0 0 12 3a9 9 0 1 0 9 9h-2c0 3.86-3.14 7-7 7s-7-3.14-7-7 3.14-7 7-7c1.93 0 3.68.79 4.95 2.05L14 10h7z" />
                  </svg>
                  Mark all as same
                </StyledButton>
              </> : (
                <Loader position="absolute" />
              )
            }
          </div>
        </div>
      </div>
      <StyledSubmitButton style={{marginTop:'auto'}} onClick={handleNext}>
        Open update checklist
      </StyledSubmitButton>
    </div>
  );
};

export default MonitoringDashboard;
