import styled from "styled-components";

export const TableGridWrapper = styled.div`
  width: 100%;
  height: 100%;
  
  --ag-font-family: 'Open Sans', sans-serif;
  --ag-borders: none;
  /* then add back a border between rows */
  --ag-row-border-style: solid !important;
  --ag-row-border-width: 1px;
  --ag-row-border-color: #eee;
  /* and between columns */
  --ag-cell-horizontal-border: solid #eee !important;
  
  --ag-header-height: 40px !important;
  
  //--ag-icon-font-code-aggregation: url('/assets/brush.svg');
  //--ag-icon-font-code-arrows: "\\f0b2";
  //--ag-icon-font-code-asc: "\\f062";
  
  //hiding filter icon
  --ag-icon-image-filter:url('/assets/filter.svg');
  --ag-icon-font-display-filter: none;
  
  //--ag-header-column-separator-display: block;
  //--ag-header-column-separator-height: 100%;
  //--ag-header-column-separator-width: 2px;
  //--ag-header-column-separator-color: purple;
  //
  //.ag-filter-icon {
  //  content: url('/assets/brush.svg'); /* Use the path relative to public */
  //  width: 20px; /* Adjust size as needed */
  //  height: 20px;
  //}
  
  .ag-root-wrapper {
    border-radius: 8px;
  }
  .ag-header-cell-label {
    font-size: 14px;
    font-weight: 400;
    color: #212121;
    text-align: center;
    justify-content:center;
  }

  .ag-body-horizontal-scroll-container, .ag-body-horizontal-scroll, .ag-body-horizontal-scroll.ag-apple-scrollbar {
    height: 8px !important;
    max-height: 8px !important;
    min-height: 8px !important;
  }

  .ag-body-horizontal-scroll-viewport {
    height: 8px !important;
    max-height: 8px !important;
    min-height: 8px !important;
    
    ::-webkit-scrollbar-thumb{
      background: rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 2px #FFFFFF, 0 0 2px #FFFFFF;
      border-radius: 2px;
      width: 4px;
    }

    ::-webkit-scrollbar {
      height: 8px;
    }
  }
`
