import React, {useEffect, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux";
import {setPanelPath_actionType} from "../../../../redux/constants";
import {StyledReportButton} from "./ReportButton-styled";
import {getModulesWithReports, getOperationsWithReports, getReports, replaceURLParams} from "../helpers";
import {EMBED_VARIANTS} from "../../../../utils/helper";
import WidgetButton from "../../../Header/components/WidgetButton";

export const ReportButton = ({config, t})=>{
    const dispatch = useDispatch()
    const {activeModule, panelPath, mobileReducer} = useSelector((store) => store)
    
    useEffect(() => {
        if (config.embed === EMBED_VARIANTS.APP) {
            return;
        }
        
        if (config.datacollection === 'open') {
            let reports = [];
            const urlParams = new URLSearchParams(window.location.search);
            const urlModule = urlParams.get('module');
            const urlDataCollection = urlParams.get('datacollection');
            const urlOp = urlParams.get('op');
    
            if (urlDataCollection) {
                if (urlModule) {
                    reports = getReports(config, activeModule)
                } else if (urlOp) {
                    reports = getModulesWithReports(config);
                } else {
                    reports = getOperationsWithReports(config);
                }
            } else if (urlOp) {
                reports = getReports(config, activeModule)
            }

            if (reports.length > 0) {
                if (mobileReducer.isMobileApp){
                    setTimeout(()=>{
                        setPanelPath('reportAll')
                    },1500)
                } else {
                    setPanelPath('reportAll')
                }
            }
        } else {
            setPanelPath('');
        }
    },[])
    
    const setPanelPath = (newPath) => {
        dispatch({type: setPanelPath_actionType, payload: newPath})
    }
    
    const reportType = useMemo(()=>{
        const reports = config.modules[activeModule]?.reports ?? config.opDefaults.modules[activeModule]?.reports ?? [];
        const urlParams = new URLSearchParams(window.location.search);

        if (!urlParams.has('op') || reports.length > 0) {
            return 'reportAll';
        }
    
    },[config, activeModule])
    
    const openReports = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has('op')) {
            replaceURLParams([{key:'datacollection', value:'open'}])
        } else {
            const op = urlParams.get('op')
            const params = [
                {
                    key: "op",
                    value: op
                },
                {
                    key: "module",
                    value: activeModule.toLowerCase()
                },
                {
                    key: "datacollection",
                    value: "open"
                }
            ]
            replaceURLParams(params)
        }

        setPanelPath(reportType)
    }

    if (!reportType || config.embed === EMBED_VARIANTS.APP) return <></>
    
    return (
      <StyledReportButton id="report-widget--open" className="onboarding-report gtag" data-tag-name="Report-Start" onClick= {openReports}>
          <span>{t("screen.popup.actions.report")}</span>
          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
              <path d="M18.5 2.5a2.12 2.12 0 0 1 3 3L12 15l-4 1 1-4Z"></path>
          </svg>
      </StyledReportButton>
    )
}

export default ReportButton
