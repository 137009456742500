import {StyledProgressBarContainer, StyledProgressBar} from "./ProgressBar-styled";
import React from "react";

const ProgressBar = ({total, currentPosition, color, hasTopRadius, bgColor}) => {
  return (
    <StyledProgressBarContainer bgColor={bgColor} hasTopRadius={hasTopRadius}>
      <StyledProgressBar
        color={color}
        progress={100 / total * currentPosition}
      />
    </StyledProgressBarContainer>
  );
};

export default ProgressBar;
