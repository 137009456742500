import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import CustomSwiper, {CustomSwiperSlide} from "../../CustomSwiper";
import {StyledEditorBreadcrumbs, StyledEditorWrapper, StyledList, StyledListItem, StyledTitle} from "./Editor-styled";
import {ConfigContext} from "../../../utils/ConfigContext";
import SharedInformation from "../../SharedInformation/SharedInformation";
import {EditType} from "./EditorSwiper/EditorSwiper";
import {useDispatch, useSelector} from "react-redux";
import useLayersChange from "../../../hooks/useLayersChange";
import {view} from "../../../utils/API";
import {isWidgetDisplayed} from "../../../esri/widgets/expandUtils";
import ProgressBar from "../../Report/new/ProgressBar/ProgressBar";
import MonitoringProgress from "../Monitoring/MonitoringProgress";
import useEditableLayers from "../../../hooks/useEditableLayers";
import {setCycleManagerOpen} from "../../../redux/action/CycleManager-action";

const managerSelections = [
  // {
  //   id: 'create',
  //   step: 'layerEdit',
  //   editType: 'create',
  //   label : 'Add a new element',
  //   icon:  <svg stroke="currentColor" fill="#c0392b" strokeWidth="0" viewBox="0 0 512 512" height="30px" width="30px" xmlns="http://www.w3.org/2000/svg"><path d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z"></path></svg>
  // },
  // {
  //   id: 'edit',
  //   step: 'layerEdit',
  //   editType: 'edit',
  //   label: 'Edit an existing element',
  //   icon: <svg fill="#fff" stroke="#c0392b" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path><path d="m15 5 4 4"></path></svg>
  // },
  // {
  //   id: 'platform',
  //   step: 'platform',
  //   editType: '',
  //   label: 'Editor Platform',
  //   icon: <svg fill="#fff" stroke="#c0392b" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path><path d="m15 5 4 4"></path></svg>
  // },
];

const reportManager = {
  id: 'reportManager',
  step: 'reportManager',
  editType:'',
  label : 'Shared Information',
  icon: <svg stroke="currentColor" fill="#c0392b" strokeWidth="0" viewBox="0 0 256 256" height="30px" width="30px" xmlns="http://www.w3.org/2000/svg"><path d="M104,152a8,8,0,0,1-8,8H56a8,8,0,0,1,0-16H96A8,8,0,0,1,104,152Zm136-36v60a16,16,0,0,1-16,16H136v32a8,8,0,0,1-16,0V192H32a16,16,0,0,1-16-16V116A60.07,60.07,0,0,1,76,56h76V24a8,8,0,0,1,8-8h32a8,8,0,0,1,0,16H168V56h12A60.07,60.07,0,0,1,240,116ZM120,176V116a44,44,0,0,0-88,0v60Zm104-60a44.05,44.05,0,0,0-44-44H168v72a8,8,0,0,1-16,0V72H116.75A59.86,59.86,0,0,1,136,116v60h88Z"></path></svg>,
  tag: 3
}

const EditorManagerSelection = ({
  handleNext,
  situationalLayers
}) =>{
  const {open} = useSelector(state=>state.cycleManagerReducer)
  const {config} = useContext(ConfigContext);
  const {layerCount, visibleLayersIds} = useLayersChange(config);
  const [sharedInformation, setSharedInformation] = useState({
    show: false,
    updates: 0
  })
  const timer = useRef(0);
  
  useEffect(()=>{
    clearTimeout(timer.current);
    if (!isWidgetDisplayed(config, 'ReportManager')) return;
    const reportManagerLayers = view.map.layers.filter(l=>visibleLayersIds[l.id] && l.layerConfig?.isShownInReportManager).toArray();

    if (reportManagerLayers.length === 0) {
      setSharedInformation(prev=>({...prev, show: false, updates: 0}));
      return;
    }
    
    setSharedInformation(prev=>({...prev, show: true}));
    timer.current = setTimeout(()=>{
      const layerPromises = reportManagerLayers.map(l=>l.load());
      Promise.all(layerPromises).then(results=>{
        const queryFeatures = results.map(layer=>{
          const query = layer.createQuery();
          query.where = query.where.length > 0 ? query.where + ' AND currvalidationfield = 1' : 'AND currvalidationfield = 1';
          return layer.queryFeatures(query);
        })
    
        let newUpdates = 0;
        Promise.all(queryFeatures).then(queryResults=>{
          queryResults.forEach(res=>{
            newUpdates += res.features.length
          })
          setSharedInformation(prev=>({...prev, updates: newUpdates}))
        })
      })
    },250)
    
    return ()=>{
      clearTimeout(timer.current);
    }
  },[layerCount, config])
  
  const allSelections = useMemo(()=>{
    const result = [...managerSelections];
    if (sharedInformation.show){
      
      result.push({
        ...reportManager,
        tag: sharedInformation.updates
      });
    }
    
    return result;
  },[sharedInformation])
  
  if (open) return null;
  
  return <div style={{
    height:'100%',
    display:'flex',
    flexDirection:'column'
  }}>
    <StyledEditorBreadcrumbs hasProgress>
      <ProgressBar color={config.opsColor} currentPosition={0} total={1} />
      <p>Editor Manager</p>
    </StyledEditorBreadcrumbs>
    <div style={{
      textAlign:'left',
      padding: '10px 14px',
      flex:1,
      display:'flex',
      flexDirection:"column",
    }}>
      <MonitoringProgress
        situationalLayers={situationalLayers}
        handleNext={handleNext}
      />
      <StyledTitle>
        What do you want to do?
      </StyledTitle>
      <StyledList>
        {
          allSelections.map(item=>(
            <StyledListItem
              onClick={()=>handleNext(item.step, item.editType)}
              key={item.id}
            >
              {Number.isFinite(item.tag) && (
                <div style={{
                  position: "absolute",
                  right: 0,
                  top: -10
                }}>
                  <div className="tag">{item.tag} NEW</div>
                </div>)}
              
              {item.icon}
              <span>
                {item.label}
              </span>
            </StyledListItem>
          ))
        }
      </StyledList>
    </div>
  </div>
}

const editorManager = {
  'editorManager': 0,
  'reportManager': 1,
  'layerEdit': 2,
  'platform': 3,
  'monitoring': 4
}

const EditorManager = ({expand, expandToWidth}) => {
  const [step, setStep] = useState('editorManager');
  const [editType, setEditType] = useState(EditType.edit);
  const swiperRef = useRef();
  const activeModule = useSelector(state=>state.activeModule);
  const {editableFeature} = useSelector(state=>state.dashboard);
  const {open} = useSelector(state=>state.cycleManagerReducer);
  const {config} = useContext(ConfigContext);
  const {editableLayers, situationalLayers} = useEditableLayers(config);
  const dispatch = useDispatch();
  
  useEffect(()=>{
    if (step !== 'editorManager') {
      handleSlideChange('editorManager');
    }
  },[activeModule])
  
  //for editor
  // useEffect(() => {
  //   if (editableFeature && step === 'editorManager'){
  //     setTimeout(() => {
  //       const layer = editableFeature.sourceLayer ? editableFeature.sourceLayer : editableFeature.layer;
  //       handleSlideChange('platform', EditType.edit, 0);
  //       dispatch(setEditableLayer(layer));
  //     },500)
  //   }
  // },[editableFeature])
  
  const handleSlideChange = useCallback((id, editType = '', speed= 300)=>{
    swiperRef.current.swiper.slideTo(editorManager[id], speed)
    if (editType){
      setEditType(editType);
    }
    setStep(id);
  },[])
  
  useEffect(()=>{
    if (!expand && !!step){
      swiperRef.current?.swiper?.slideTo(editorManager['editorManager'])
      setStep('editorManager');
    }
  },[expand])
  
  const handleGoBack = useCallback(()=>{
    handleSlideChange('editorManager');
    dispatch(setCycleManagerOpen(false));
  },[handleSlideChange])
  
  if (!expand) return;
  
  return (
    <StyledEditorWrapper>
      <CustomSwiper
        ref={swiperRef}
        init="true"
        initial-slide={0}
        prevent-interaction-on-transition
        no-swiping
        allow-touch-move={false}
        prevent-clicks={false}
        simulate-touch={false}
        prevent-clicks-propagation={false}
        resizeObserver={true}
        observer={true}
        virtual="true"
        style={{
          width:'100%',
          height:'100%'
        }}
      >
        <CustomSwiperSlide key="selection">
          {expand && <EditorManagerSelection handleNext={handleSlideChange} situationalLayers={situationalLayers} />}
        </CustomSwiperSlide>
        <CustomSwiperSlide key="reportManager">
          {step === "reportManager" && <SharedInformation expand={true} selectedTab="sharedInformation" handleGoBack={handleSlideChange}/>}
        </CustomSwiperSlide>
        {/*//for editor*/}
        {/*<CustomSwiperSlide key={EditType.create}>*/}
        {/*  {step === 'layerEdit' && (*/}
        {/*    <Editor handleGoBack={handleGoBack} editType={editType} handleMainSlideChange={handleSlideChange}/>*/}
        {/*  )}*/}
        {/*</CustomSwiperSlide>*/}
        {/*<CustomSwiperSlide>*/}
        {/*  {step === 'platform' && (*/}
        {/*    <EditorPlatform*/}
        {/*      setEditType={setEditType}*/}
        {/*      handleGoBack={handleGoBack}*/}
        {/*      editType={editType}*/}
        {/*      handleMainSlideChange={handleSlideChange}*/}
        {/*      editableLayers={editableLayers}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</CustomSwiperSlide>*/}
        
        {/*//toremve*/}
        {/*  <CustomSwiperSlide>*/}
        {/*  {step === 'monitoring' && (*/}
        {/*    <EditorContextProvider>*/}
        {/*      {*/}
        {/*        createPortal(*/}
        {/*          <div style={{*/}
        {/*            background: 'white',*/}
        {/*            height:'100%',*/}
        {/*            width:'100%',*/}
        {/*            animation:`${!open ? 'cycleManagerLeave' : 'cycleManagerEnter' } 0.5s linear`,*/}
        {/*            pointerEvents:'auto',*/}
        {/*            borderRadius:20*/}
        {/*          }}>*/}
        {/*            <MonitoringSlides*/}
        {/*              layers={situationalLayers}*/}
        {/*              goBack={handleGoBack}*/}
        {/*              expandToWidth={expandToWidth}*/}
        {/*            />*/}
        {/*          </div>,*/}
        {/*          document.querySelector('#cycle-manager')*/}
        {/*        )*/}
        {/*      }*/}
        {/*    </EditorContextProvider>*/}
        {/*  )}*/}
        {/*  */}
        {/*</CustomSwiperSlide>*/}
      </CustomSwiper>
    </StyledEditorWrapper>
  );
};

export default EditorManager;
