import { addLayerToSearch } from "./widgets/search";
import { getLayerFromId, view } from "../utils/API";
import { addSymbology } from "../utils/symbologies";
import {
  applyCurrentFiltersOnLayer,
  composeIsoAndIsDeletedWhereCondition,
} from "../components/Filter/ApplyFilters";
import { setLayerClustering } from "../utils/clustering";
import { loadModules } from "esri-loader";
import { extend, getConfigISO, ROLE_EDITOR } from "../utils/helper";
import { createCustomPopup } from "./custom-popup-content";
import roads from "../data/roads.json";
import { async } from "regenerator-runtime";

const layerSequence = ["polygon", "polyline", "line", "point"];

export const createLayer = (
  layerAlias,
  index,
  config,
  setSideBarIFrame,
  t,
  i18n,
  activeModule
) => {
  //Get layer definition, if not found, just create a default one
  let layerConfig = config.layerConfig[layerAlias];
  if (!layerConfig) {
    layerConfig = { ...config.layerDefaults };
    layerConfig.id = layerAlias;
    config.layerConfig[layerAlias] = layerConfig;
  }

  layerConfig.alias = layerAlias;
  layerConfig.layerModules = [];
  let modules = config.modules;
  modules &&
    Object.keys(modules).forEach((moduleName) => {
      const currModule = modules[moduleName];
      if (config.activeModules && config.activeModules.includes(moduleName)) {
        if (Array.isArray(currModule.layers)) {
          currModule.layers.forEach((moduleLayer) => {
            if (moduleLayer === layerAlias)
              layerConfig.layerModules.push(moduleName);
          });
        }

        if (Array.isArray(currModule?.optionalLayers)) {
          currModule.optionalLayers.forEach((moduleLayer) => {
            if (moduleLayer === layerAlias)
              layerConfig.layerModules.push(moduleName);
          });
        }
      }
    });
  if (layerConfig.id) {
    let layerId = layerConfig.id;

    //Load normal layers with id
    return loadModules(["esri/layers/Layer"]).then(([Layer]) => {
      let layerPromise = getLayerFromId(Layer, layerId);
      return layerPromise
        .then((layerPreLoad) => {
          //For conops layers it is important to load all fields (otherwise the layerview query will not work
          if (
            layerConfig.isConops ||
            layerConfig.commentBoxOps ||
            layerConfig.isEpam
          ) {
            layerPreLoad.outFields = ["*"];
          }

          layerPreLoad.mode = "on-the-fly";

          return layerPreLoad.load().then((layer) => {
            layer.layerConfig = layerConfig;

            setCustomLayerAttributes(
              layer,
              index,
              layerConfig.layerModules,
              layerId
            );
            setLayerVisibility(layer, config, layerConfig);
            checkAndAddLayerToMap(
              layer,
              config,
              layerConfig,
              setSideBarIFrame,
              t,
              i18n,
              activeModule
            );
            return layer;
          });

          //return layerPromise
        })
        .catch(() => {
          return null;
        });
    });
  } else if (layerConfig.subLayers) {
    //Load group layers
    return loadModules(["esri/layers/GroupLayer", "esri/layers/Layer"]).then(
      ([GroupLayer, Layer]) => {
        let layerPromises = [];
        Object.keys(layerConfig.subLayers).forEach((subLayerAlias) => {
          let subLayerConfig = layerConfig.subLayers[subLayerAlias];
          let subLayerPromise = getLayerFromId(Layer, subLayerConfig.id);
          subLayerPromise.then((subLayer) => {
            setCustomLayerAttributes(
              subLayer,
              index,
              layerConfig.layerModules,
              layerAlias,
              subLayerAlias
            );
          });
          layerPromises.push(subLayerPromise);
        });
        return Promise.all(layerPromises).then((resolvedLayers) => {
          let grLayer = new GroupLayer({
            title: layerAlias,
            layers: resolvedLayers,
          });

          setCustomLayerAttributes(
            grLayer,
            index,
            layerConfig.layerModules,
            layerAlias
          );
          setLayerVisibility(grLayer, config, layerConfig);
          return checkAndAddLayerToMap(
            grLayer,
            config,
            layerConfig,
            setSideBarIFrame,
            t,
            i18n,
            activeModule
          );
        });
      }
    );
  } else if (layerAlias.includes("roads_baseline")) {
    const iso3 = getConfigISO(config);
    const configRoads = roads.filter((item) => iso3.includes(item.iso3));
    loadModules(["esri/layers/Layer"]).then(([Layer]) => {
      const layerPromises = [];

      configRoads.map((item) => {
        if (!Array.isArray(item.layers)) {
          return;
        }

        item.layers?.map((item) => {
          const layerId =
            config.role === ROLE_EDITOR ? item.editable : item.viewer;
          if (config.role === ROLE_EDITOR) {
            layerConfig.id = item.editable;
            layerConfig.editable = item.editable;
            layerConfig.viewer = item.viewer;
          } else {
            layerConfig.id = item.viewer;
            layerConfig.editable = item.editable;
          }

          const layerPromise = getLayerFromId(Layer, layerId, item.instance);

          layerPromises.push(
            layerPromise
              .then((layerPreLoad) => {
                //For conops layers it is important to load all fields (otherwise the layerview query will not work
                if (layerConfig.isConops || layerConfig.commentBoxOps) {
                  layerPreLoad.outFields = ["*"];
                }

                layerPreLoad.mode = "on-the-fly";

                return layerPreLoad.load().then((layer) => {
                  layer.layerConfig = layerConfig;

                  setCustomLayerAttributes(
                    layer,
                    index,
                    layerConfig.layerModules,
                    layerId
                  );
                  setLayerVisibility(layer, config, layerConfig);
                  checkAndAddLayerToMap(
                    layer,
                    config,
                    layerConfig,
                    setSideBarIFrame,
                    t,
                    i18n,
                    activeModule
                  );
                  return layer;
                });
              })
              .catch(() => {
                return null;
              })
          );
        });
      });
      return Promise.all(layerPromises);
    });

    return;
  } else {
    //Maybe this layer is available only for editors
    return null;
  }
};

const setCustomLayerAttributes = (
  layer,
  index,
  layerModules,
  layerId,
  subLayerId
) => {
  layer.layerIndex = index;
  layer.originalId = layerId;
  layer.layerModules = layerModules;
  layer.originalSubLayerId = subLayerId;
};

const setLayerVisibility = (layer, config, layerConfig) => {
  if (layerConfig.layerModules.length > 0) {
    let module = config.defaultModule || config.modules[0];
    if (config.modules[module]?.optionalLayers?.includes(layerConfig.alias)) {
      layer.visible = false;
    } else {
      layer.visible = layerConfig.layerModules.includes(module);
    }
  }
};

const setLayerFilterAndPopup = (
  layer,
  config,
  layerConfig,
  setSideBarIFrame,
  t,
  i18n
) => {
  layer.layerConfig = layerConfig;
  if (
    !layerConfig.clickable ||
    (layerConfig.onclick && layerConfig.target === "openLinkOnSamePage")
  )
    layer.popupEnabled = false;
  else {
    loadModules([
      "esri/PopupTemplate",
      "esri/popup/content/CustomContent",
    ]).then(([PopupTemplate, CustomContent]) => {
      layer.popupTemplate = createCustomPopup(
        config,
        layer,
        PopupTemplate,
        CustomContent,
        t,
        i18n
      );
    });
  }

  if (layer.fields) {
    layer.layerConfig.originalDefinitionExpression = layer.definitionExpression;
    layer.definitionExpression = composeIsoAndIsDeletedWhereCondition(
      layer,
      config
    );
    layer.isFiltered = true;
  }
};
const checkAndAddLayerToMap = (
  layer,
  config,
  layerConfig,
  setSideBarIFrame,
  t,
  i18n,
  activeModule
) => {
  if (layer.type === "feature") {
    setLayerClustering(layer, config, t);

    setLayerFilterAndPopup(
      layer,
      config,
      layerConfig,
      setSideBarIFrame,
      t,
      i18n
    );
    return addLayerToMap(layer, config, null, t, activeModule);
  } else if (layer.type === "group") {
    configureGroupSubLayers(
      layer,
      config,
      layerConfig,
      setSideBarIFrame,
      t,
      i18n
    );
    return addLayerToMap(layer, config, null, t, activeModule);
  } else if (layer.type === "map-image") {
    configureImageSubLayers(
      layer,
      config,
      layerConfig,
      setSideBarIFrame,
      t,
      i18n
    );
    return addLayerToMap(layer, config, null, t, activeModule);
  } else if (layer.type === "wms") {
    return loadModules(["esri/layers/WMSLayer"]).then(([WMSLayer]) => {
      const wmsLayer = new WMSLayer({ url: layer.url });
      return wmsLayer.load().then((wmsLayer) => {
        wmsLayer.sublayers.forEach((sublayer) => {
          sublayer.visible = false;
        });
        return addLayerToMap(wmsLayer, config, null, t, activeModule);
      });
    });
  } else if (layer.type === "tile" && layer.url) {
    return loadModules(["esri/layers/TileLayer"]).then(([TileLayer]) => {
      let tileLayer = new TileLayer({ url: layer.url });

      setCustomLayerAttributes(
        tileLayer,
        layer.layerIndex,
        layer.layerModules,
        layer.originalId
      );
      setLayerFilterAndPopup(
        tileLayer,
        config,
        layerConfig,
        setSideBarIFrame,
        t,
        i18n
      );
      return addLayerToMap(tileLayer, config, null, t, activeModule);
    });
  }
};

const configureGroupSubLayers = (
  parentLayer,
  config,
  layerConfig,
  setSideBarIFrame,
  t,
  i18n
) => {
  parentLayer.layers.map((subLayer) => {
    subLayer.load().then((subLayer) => {
      let subLayerConfig = layerConfig;
      if (layerConfig.subLayers && layerConfig.subLayers[subLayer.layerId])
        subLayerConfig = extend(
          layerConfig,
          layerConfig.subLayers[subLayer.layerId]
        );

      setCustomLayerAttributes(
        subLayer,
        parentLayer.layerIndex,
        parentLayer.layerModules,
        parentLayer.originalId,
        subLayer.layerId
      );
      setLayerFilterAndPopup(
        subLayer,
        config,
        subLayerConfig,
        setSideBarIFrame,
        t,
        i18n
      );
    });
    return subLayer;
  });
};

const configureImageSubLayers = (
  parentLayer,
  config,
  layerConfig,
  setSideBarIFrame,
  t,
  i18n
) => {
  parentLayer.sublayers.map((subLayer) => {
    //const id = parentLayer.originalId + "." + subLayer.id
    let subLayerConfig = layerConfig;
    if (layerConfig.subLayers && layerConfig.subLayers[subLayer.id])
      subLayerConfig = extend(layerConfig, layerConfig.subLayers[subLayer.id]);

    //Inherit layer configuration from the head
    subLayer.originalId = parentLayer.originalId;
    subLayer.originalSubLayerId = subLayer.id;

    if (subLayer.sublayers)
      configureImageSubLayers(
        subLayer,
        config,
        layerConfig,
        setSideBarIFrame,
        t,
        i18n
      );

    subLayer.createFeatureLayer().then((featureLayer) => {
      if (featureLayer)
        setLayerFilterAndPopup(
          subLayer,
          config,
          subLayerConfig,
          setSideBarIFrame,
          t,
          i18n
        );
    });

    return subLayer;
  });
};

const zoomToCountry = async (config, l, activeModule) => {
  try {
    const zoom = activeModule
      ? config.modules[activeModule].zoom ?? config.zoom
      : undefined;

    if (
      l.layerConfig?.id === "d034f64504a04942a87cdd0165242679" &&
      l.layerConfig?.alias === "world_boundaries" &&
      zoom === undefined
    ) {
      const iso3 = getConfigISO(config);
      const queryCountry = iso3[0];
      const query = {
        where: `iso3 = '${queryCountry}'`,
        returnGeometry: true,
      };

      const result = await l.queryFeatures(query);
      if (result.features && result.features.length > 0) {
        const feature = result.features[0];
        // Get the extent of the polygon
        const extent = feature.geometry.extent.expand(1.1); // Expand for better view

        // Zoom to the extent
        view.goTo(
          {
            target: extent,
          },
          {
            duration: 1000, // Animation duration in milliseconds
            easing: "ease-out",
          }
        );
      }
    }
  } catch (err) {
    console.warn(err);
  }
};

/**
 *
 * Call this whenever a layer should be added.
 * It respects the following layer order:
 * -polygons
 * -lines
 * -points
 * -undefined
 */
export const addLayerToMap = (layer, config, map, t, activeModule) => {
  let actualMap = map ? map : view.map;
  let layerIndex = 0;

  layer.load().then((l) => {
    addSymbology(l, config, t);
    if (layer.layerConfig?.refreshInterval) {
      const interval = layer.layerConfig.refreshInterval;
      if (!isNaN(parseFloat(interval)) && isFinite(interval)) {
        console.log(
          layer.title +
            " was set to be refreshed with the interval: " +
            interval +
            " minute(s)"
        );
        layer.refreshInterval = interval;
      }
    }

    actualMap.layers.forEach((otherLayer) => {
      if (isAbove(config, l, otherLayer)) layerIndex++;
    });

    actualMap.add(l, layerIndex);

    addLabelingInfo(l);

    initFilters(layer, config, activeModule);
    prepareLayerForOfflineUse(l);
    zoomToCountry(config, l, activeModule);

    //Custom layer methods:
    l.getLayerTitle = (t) => {
      let transactionLabel =
        "layer.title." +
        (l.layerConfig?.titleLabel
          ? l.layerConfig.titleLabel
          : l.originalId +
            (l.originalSubLayerId ? "-" + l.originalSubLayerId : ""));
      return t(transactionLabel, l.title);
    };
  });

  /*
    if (layer.geometryType !== "polyline" && layer.layerConfig && layer.layerConfig.filterExpression){
        view.whenLayerView(layer).then((layerView) => {
            layerView.filter = {
                where: layer.layerConfig.filterExpression
            }
        })
    }
     */
  // addLayerToSearch(layer, view, config, t);
};

/**
 * Return true if layerA should be displayed on top of layerB
 */
const isAbove = (config, layerA, layerB) => {
  //if (layerA.type === "group")
  //	return true
  //if (layerB.type === "group")
  //	return true

  // if (layerA.layerConfig?.isConops || layerA.layerConfig?.isEpam || layerA.layerConfig?.commentBoxOps && !(layerB.layerConfig?.isConops || layerB.layerConfig?.isEpam || layerB.layerConfig?.commentBoxOps)){
  //     return true
  // }
  //
  // if (!(layerA.layerConfig?.isConops || layerA.layerConfig?.isEpam || layerA.layerConfig?.commentBoxOps) && layerB.layerConfig?.isConops || layerB.layerConfig?.isEpam || layerB.layerConfig?.commentBoxOps){
  //     return true
  // }
  //
  // if (layerA.layerConfig?.alias.includes('glpm') || layerB.layerConfig?.alias.includes('glpm')) return true

  if (!layerA.layerConfig) return false;

  if (!layerB.layerConfig) return true;

  //TODO: look at this code, it can be optimized
  if (config.activeModules) {
    let ret = null;
    config.activeModules.forEach((module) => {
      if (ret !== null) return;
      config.modules[module].layers.forEach((layerId) => {
        if (ret !== null) return;

        if (
          layerA.layerConfig.id === layerId ||
          layerA.layerConfig.alias === layerId
        )
          ret = false;
        if (
          layerB.layerConfig.id === layerId ||
          layerB.layerConfig.alias === layerId
        )
          ret = true;
      });
    });
    return ret;
  }

  if (config.layers) {
    let ret = null;
    config.layers.forEach((layerId) => {
      if (ret !== null) return;

      if (
        layerA.layerConfig.id === layerId ||
        layerA.layerConfig.alias === layerId
      )
        ret = false;
      if (
        layerB.layerConfig.id === layerId ||
        layerB.layerConfig.alias === layerId
      )
        ret = true;
    });
    return ret;
  }

  const lsa = getLayerGeometryType(layerA);
  const lsb = getLayerGeometryType(layerB);
  if (lsa !== lsb) return lsa > lsb;

  return layerA.layerIndex > layerB.layerIndex;
};

const getLayerGeometryType = (layer) => {
  if (layer.geometryType) return layerSequence.indexOf(layer.geometryType);
};

const getColoredLabelClass = (layer, symbol) => {
  const colorDefinitions = layer.renderer?.getColorDefinitions();
  const placement = "above-center";
  symbol.haloColor = "white"; // #949494 gray
  symbol.haloSize = 2;
  symbol.yoffset = -5;
  symbol.font.size = 9;
  symbol.font.family = "Roboto";
  symbol.font.style = "italic";
  const labelClasses = [];
  colorDefinitions.forEach((colorDef) => {
    const field = colorDef.field[0];
    labelClasses.push({
      labelExpressionInfo: {
        expression: `var values = Split($feature.${layer.layerConfig.labelField}, ' ');
                              var ret = ''; var temp = '';
                              for(var i in values){
                                //temp += values[i] + ' ';
                                var newTemp = temp + values[i]
                                if (Count(newTemp) > 20){
                                  ret += temp + TextFormatting.NewLine + values[i] + ' ';
                                  temp = '';
                                } else {
                                  temp += values[i] + ' ';
                                }
                              }
                              ret += temp;
                              return ret;`,
      },
      allowOverrun: false,
      symbol: {
        ...symbol,
        color: colorDef.color, //'#d68a00'
      },
      useCodedValues: true,
      repeatLabel: false,
      labelPlacement: placement,
      maxScale: 0,
      minScale: 25000000,
      where: `${field.name} = ${colorDef.value}`,
    });
  });
  return labelClasses;
};

const addLabelingInfo = (layer) => {
  if (!layer.layerConfig || !layer.layerConfig.labelField) return;

  const symbol = {
    type: "text",
    color: "#242424",
    font: { size: 8 },
  };

  if (
    layer.geometryType === "polyline" ||
    layer.geometryType === "line" ||
    layer.geometryType === "polygon"
  ) {
    const placement =
      layer.geometryType === "polyline" || layer.geometryType === "line"
        ? "center-along"
        : layer.geometryType === "polygon"
        ? "always-horizontal"
        : "below-center";

    symbol.haloColor = "white";
    symbol.haloSize = 2;
    symbol.yoffset = 15;

    if (!layer.layerConfig.labelField.includes("$feature")) {
      layer.labelingInfo = getColoredLabelClass(layer, symbol);
    } else {
      layer.labelingInfo = [
        {
          labelExpressionInfo: {
            expression: `${layer.layerConfig.labelField}`,
          },
          symbol: symbol,
          useCodedValues: true,
          repeatLabel: false,
          labelPlacement: placement,
        },
      ];
    }
  } else {
    const captionFieldName = layer.layerConfig?.labelField;
    const captionField = layer.fields.find((f) => f.name === captionFieldName);

    if (captionFieldName && !captionField)
      console.warn(
        "Nonexistent captionField specified: '" +
          captionFieldName +
          "' Layer: " +
          layer.title
      );

    if (captionField) {
      layer.labelingInfo = getColoredLabelClass(layer, symbol);
    } else {
      symbol.backgroundColor = [255, 255, 255, 1];
      symbol.borderLineColor = "black";
      symbol.borderLineSize = 0.5;

      const labelExpressionInfo = {
        expression: `${layer.layerConfig.labelField}`,
      };
      layer.labelingInfo = [
        {
          labelExpressionInfo: labelExpressionInfo,
          labelPlacement: "below-center",
          symbol: symbol,
          allowOverrun: false,
        },
      ];
    }
  }
};

/**
 * Add the filterFields element to the layerConfig layer attribute
 * We can spare time/network if a polyline layer is loaded with the default filter value
 */
export const initFilters = (layer, config, activeModule) => {
  if (layer.type !== "feature" || !layer.fields) return;

  if (config.defaultFilters[activeModule])
    applyCurrentFiltersOnLayer(
      layer,
      config.defaultFilters[activeModule],
      config
    );
};

/**
 * Offline functionality: Query features of the layer in advance. this way we can cache all features and the offline
 * version will not try to load them when a popup is to be opened
 *
 * Warning: This code is not mature enough to be used in production
 */
const prepareLayerForOfflineUse = (l) => {
  if (
    l.title === "glcconops_view" &&
    l.type === "feature" &&
    l.capabilities.query.supportsCacheHint
  ) {
    const fieldNames = l.fields.map((f) => f.name).sort();
    l.queryObjectIds().then((objectIds) => {
      if (objectIds.length > 200) objectIds = objectIds.slice(0, 200);

      let q = l.createQuery();
      q.objectIds = objectIds;
      q.outFields = fieldNames;
      q.returnM = true;
      q.returnZ = true;
      q.outSpatialReference = 102100;
      q.where = l.definitionExpression || "1=1";
      l.queryFeatures(q);

      //if (l.capabilities.operations.supportsQueryAttachments) {
      //layer.queryAttachments({objectIds: objectIds})
      //}
    });
  }
};
