import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { TooltipContainer, TriggerWrapper } from "./Tooltip-styled";
import useClickOutside from "../../../hooks/useClickOutside";

const Tooltip = ({
  children,
  content,
  onClick = undefined,
  placement = "top",
  offset = 8,
  showOnHover = false,
  showOnClick = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);
  const timeoutRef = useRef(null);

  // useClickOutside(tooltipRef, () => {
  //   if (showOnClick) {
  //     setIsVisible(false);
  //   }
  // });

  const clearTimeout = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  useEffect(() => {
    return () => clearTimeout();
  }, []);

  const calculatePosition = () => {
    if (!triggerRef.current || !tooltipRef.current) return;

    const triggerRect = triggerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    let top = 0;
    let left = 0;

    switch (placement) {
      case "top":
        top = triggerRect.top - tooltipRect.height - offset;
        left = triggerRect.left + (triggerRect.width - tooltipRect.width) / 2;
        break;
      case "bottom":
        top = triggerRect.bottom + offset;
        left = triggerRect.left + (triggerRect.width - tooltipRect.width) / 2;
        break;
      case "left":
        top = triggerRect.top + (triggerRect.height - tooltipRect.height) / 2;
        left = triggerRect.left - tooltipRect.width - offset;
        break;
      case "right":
        top = triggerRect.top + (triggerRect.height - tooltipRect.height) / 2;
        left = triggerRect.right + offset;
        break;
      default:
        break;
    }

    setPosition({ top, left });
  };

  useEffect(() => {
    if (isVisible) {
      calculatePosition();
      window.addEventListener("scroll", calculatePosition);
      window.addEventListener("resize", calculatePosition);

      return () => {
        window.removeEventListener("scroll", calculatePosition);
        window.removeEventListener("resize", calculatePosition);
      };
    }
  }, [isVisible]);

  const handleMouseEnter = () => {
    clearTimeout();
    if (showOnHover) {
      timeoutRef.current = window.setTimeout(() => {
        setIsVisible(true);
      }, 100);
    }
  };

  const handleMouseLeave = () => {
    if (showOnHover && !showOnClick) {
      clearTimeout();
      timeoutRef.current = window.setTimeout(() => {
        setIsVisible(false);
      }, 100);
    }
  };

  const handleClick = (e) => {
    if (showOnClick) {
      clearTimeout();
      setIsVisible(!isVisible);
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <>
      <TriggerWrapper
        ref={triggerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {children}
      </TriggerWrapper>
      {isVisible &&
        createPortal(
          <TooltipContainer
            ref={tooltipRef}
            style={{ top: position.top, left: position.left }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            placement={placement}
          >
            {content}
          </TooltipContainer>,
          document.body
        )}
    </>
  );
};

export default Tooltip;
