import React, {useContext, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {ConfigContext} from "../../../../../utils/ConfigContext";
import {
  StyledChecklistHeader,
  StyledCheckListProgressInformation,
  StyledCheckListTotalInformationWrapper,
} from "../../Monitoring-styled";
import {useSelector} from "react-redux";
import {formatIntlDateTime, timeAgo} from "../../../../../utils/helper";
import {EditorContext} from "../../../Editor/EditorContextProvider";
import {StyledFiltersBackButton} from "../../../../MobileApp/MobileFilter/MobileFilter-styled";
import Message from "../../../Editor/Message/Message";

export const HeaderButton = ({onClick, count = 0, Icon, config}) =>(
  <StyledFiltersBackButton
    disabled={count === 0}
    onClick={onClick}
    style={{
      position:'relative',
      borderColor: count > 0 ? config.opsColor : ''
    }}
    // style={{
    //   minWidth: 'auto',
    //   minHeight: 'auto',
    //   display: 'flex',
    //   alignItems: 'center',
    //   height: 'auto',
    //   width: 'auto',
    //   padding: '12px',
    //   borderRadius: '12px',
    //   position:'relative'
    // }}
    // bg={count > 0 ? config.opsColor : "#eeeeee"}
  >
    <Icon
      fill={count > 0 ? config.opsColor : "#4b4b4b"}
      width="14"
      height="14"
    />
    {count > 0 && (
      <span style={{
        fontSize: "10px",
        right: "0px",
        color: "#4b4b4b",
        position: "absolute",
        top: "-6px",
        background: "#eee",
        borderRadius: "100px",
        padding: "4px 6px",
        lineHeight: "1",
      }}>
        {count}
      </span>
    )}
  </StyledFiltersBackButton>
)

export const SaveIcon = ({fill, ...props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.00018 42" {...props}>
      <title>Asset 937</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Icons">
          <path
            fill={fill}
            d="M37,0H33V10a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V0H2A2,2,0,0,0,0,2V40a2,2,0,0,0,2,2H40.00018a2,2,0,0,0,2-2V5Zm0,36a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1H36a1,1,0,0,1,1,1Z"
          />
          <rect fill={fill} x="7" width="24" height="9"/>
        </g>
      </g>
    </svg>
  );
};

export const TargetIcon = ({...props}) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 256 256"
      height="200px"
      width="200px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M229.26,90.4a108,108,0,0,1-177.63,114A108,108,0,0,1,195.41,43.63l20.1-20.11a12,12,0,0,1,17,17l-96,96a12,12,0,1,1-17-17l24-24a36,36,0,1,0,19.76,39.65,12,12,0,0,1,23.53,4.74,60,60,0,1,1-25.73-62L178.3,60.74a84,84,0,1,0,28.46,38,12,12,0,1,1,22.5-8.35Z" />
    </svg>
  );
};

export const CalendarIcon = (props) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 448 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z" />
    </svg>
  );
};

export const EditorIcon = ({...props}) => {
  return (
    <svg
      aria-hidden="true"
      className="svg"
      fill="currentColor"
      height="100%"
      viewBox="0 0 16 16"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.721 4.007a.965.965 0 0 0-.03-1.385l-1.413-1.414a.965.965 0 0 0-1.385-.03L2.841 11.23l-1.756 4.097a.371.371 0 0 0 .488.487L5.67 14.06l8.607-8.609zM2.624 14.276l.554-1.294.74.74zm2.338-.924L3.55 11.937l8.007-8.008 1.414 1.415zm8.716-8.716l-1.414-1.414 1.09-1.09a.306.306 0 0 1 .433 0l.981.98a.306.306 0 0 1 0 .434z" />
    </svg>
  );
};

const LeftRightIcon = (props) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M15.97 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 1 1-1.06-1.06l3.22-3.22H7.5a.75.75 0 0 1 0-1.5h11.69l-3.22-3.22a.75.75 0 0 1 0-1.06Zm-7.94 9a.75.75 0 0 1 0 1.06l-3.22 3.22H16.5a.75.75 0 0 1 0 1.5H4.81l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}


const ChecklistHeader = ({
  editedFeatures,
  handleBatchUpdateShow,
  handleSave,
  handleUpdateAll
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const {lastCycleUpdate, layersUpdates, cycleUpdateDueDate} = useSelector(state=>state.cycleManagerReducer)
  const {config} = useContext(ConfigContext);
  const {t, i18n} = useTranslation('common');
  const {batchUpdateFeatures, editableLayer} = useContext(EditorContext);
  
  const calculatedPercent = useMemo(()=>{
    let featuresCount = 0, outdatedCount = 0;
    Object.values(layersUpdates).forEach(layerUpdate=>{
      outdatedCount += layerUpdate.outdated.length
      featuresCount += layerUpdate.features.length
    })
    
    const percent = Math.floor(((featuresCount - outdatedCount) * 100) / featuresCount);
    return percent
  },[layersUpdates]);
  
  const progressColor = useMemo(()=>{
    // let color = 'red';
    // if (calculatedPercent >= 85) {
    //   color = 'green';
    // } else if (calculatedPercent >= 50) {
    //   color = 'orange';
    // }
    
    return config.opsColor;
  },[calculatedPercent])
  
  const format = (value) => {
    if (!value) return '';
    return timeAgo(new Date(value), i18n.language, false)
  }
  
  function formatTime(time) {
    if (!time) return '';
    
    const utcDate = new Date(time);
    const utcNow = new Date();
    const diffTime = Math.abs(utcNow.getTime() - utcDate.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    const hasTimeLeft = utcNow.getTime() - utcDate.getTime() < 0;
    
    if (diffDays === 1) {
      return hasTimeLeft ? 'tomorrow' : 'yesterday';
    }
  
    if (diffDays > 1) {
      const options = {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      
      const formattedDate = formatIntlDateTime({
        languageCode: i18n.language,
        options: options,
        date: utcDate
      })
      
      return formattedDate + ' UTC';
    }
    
    let result = '';
    if (diffHours > 0) {
      result += `${diffHours} h`;
    }
    if (diffMinutes > 0) {
      if (result) {
        result += `, `;
      }
      result += `${diffMinutes} m`;
    }
    if (!result) {
      result = 'just now';
    } else {
      result += (hasTimeLeft ? '' : ' ago');
    }
    return result;
  }
  
  const editedFeaturesCount = useMemo(()=> Object.keys(editedFeatures).length,[editedFeatures])
  
  return (
    <>
      {showMessage && (
        <Message
          title={`Are you sure you want to update <b>${editableLayer.getLayerTitle(t)}</b> layer?`}
          subtitle="Selecting all the features will mark them as updated"
          onSubmit={()=>{
            setShowMessage(false);
            handleUpdateAll();
          }}
          onCancel={()=>{
            setShowMessage(false);
          }}
          type="warning"
          position="absolute"
        />
      )}
      <StyledChecklistHeader>
        <StyledCheckListTotalInformationWrapper>
          {/*<span style={{*/}
          {/*  fontSize:24,*/}
          {/*  fontWeight:500*/}
          {/*}}>32%</span>*/}
          <StyledCheckListProgressInformation style={{marginLeft:'auto'}}>
            {/*<div style={{display:'flex', gap:8, flexDirection:'row', textAlign:'right'}}>*/}
            {/*  <div style={{paddingRight:4, display:'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center', gap:40}}>*/}
            {/*    <div style={{display:'flex', alignItems:'center', gap:4}}>*/}
            {/*      <CalendarIcon width="16" height="16" />*/}
            {/*      <span style={{fontSize:12}}>*/}
            {/*        <span style={{color:'#4b4b4b'}}>last cycle:</span> {format(lastCycleUpdate)}*/}
            {/*      </span>*/}
            {/*    </div>*/}
            {/*    /!*<LeftRightIcon width={16} height={16} fill="#4b4b4b" />*!/*/}
            {/*    <div style={{display:'flex', alignItems:'center', gap:4}}>*/}
            {/*      <TargetIcon width="16" height="16" />*/}
            {/*      <span style={{fontSize:12}}><span style={{color:'#4b4b4b'}}>due cycle:</span> {formatTime(cycleUpdateDueDate)}</span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<CheckListTabWrapper>*/}
            {/*  {*/}
            {/*    layers.map(l=>(*/}
            {/*      <ChecklistTab*/}
            {/*        selected={selectedLayer === l}*/}
            {/*        onClick={()=>onSelect(l)}*/}
            {/*        layerUpdate={layersUpdates[l.layerConfig?.id]}*/}
            {/*      >*/}
            {/*        <LayerIcon*/}
            {/*          layer={l}*/}
            {/*          config={config}*/}
            {/*          t={t}*/}
            {/*          opsColor={config.opsColor}*/}
            {/*          width={20}*/}
            {/*          height={20}*/}
            {/*          textColor="#red"*/}
            {/*        />*/}
            {/*      </ChecklistTab>*/}
            {/*    ))*/}
            {/*  }*/}
            {/*</CheckListTabWrapper>*/}
            <div style={{display:'flex', gap:8}}>
              {/*<Checkbox*/}
              {/*  id={1}*/}
              {/*  checked={false}*/}
              {/*  disabled={false}*/}
              {/*  onChange={(e)=>{*/}
              {/*  }}*/}
              {/*/>*/}
              <HeaderButton onClick={handleBatchUpdateShow} config={config} count={batchUpdateFeatures.length} Icon={EditorIcon} />
              <HeaderButton config={config} count={editedFeaturesCount} Icon={SaveIcon} onClick={handleSave}/>
              <div
                onClick={()=>{
                  setShowMessage(true);
                }}
                style={{
                  display:'flex',
                  alignItems:'center',
                  background:config.opsColor,
                  borderRadius:100,
                  padding:'4px 18px',
                  cursor:'pointer'
                }}>
                <span style={{color:'#fff', fontWeight:400}}>info up to date</span>
              </div>
            </div>
          </StyledCheckListProgressInformation>
          {/*<StyledCheckListProgressWrapper>*/}
          {/*  <ProgressBar bgColor="#eee" color={progressColor} currentPosition={calculatedPercent} total={100} hasTopRadius={false} />*/}
          {/*</StyledCheckListProgressWrapper>*/}
        </StyledCheckListTotalInformationWrapper>
        <div style={{
          display:'flex',
          padding:'0px 10px'
        }}>
          {/*<CheckListTabWrapper>*/}
          {/*  {*/}
          {/*    layers.map(l=>(*/}
          {/*      <ChecklistTab*/}
          {/*        selected={selectedLayer === l}*/}
          {/*        onClick={()=>onSelect(l)}*/}
          {/*        layerUpdate={layersUpdates[l.layerConfig?.id]}*/}
          {/*      >*/}
          {/*        <LayerIcon*/}
          {/*          layer={l}*/}
          {/*          config={config}*/}
          {/*          t={t}*/}
          {/*          opsColor={config.opsColor}*/}
          {/*          width={34}*/}
          {/*          height={34}*/}
          {/*          textColor="#red"*/}
          {/*        />*/}
          {/*      </ChecklistTab>*/}
          {/*    ))*/}
          {/*  }*/}
          {/*</CheckListTabWrapper>*/}
          {/*<div style={{*/}
          {/*  display:'flex',*/}
          {/*  alignItems:'center',*/}
          {/*  gap:10,*/}
          {/*}}>*/}
          {/*  <HeaderButton onClick={handleBatchUpdateShow} config={config} count={batchUpdateFeatures.length} Icon={EditorIcon} />*/}
          {/*  <HeaderButton onClick={handleSave} config={config} count={editedFeaturesCount} Icon={SaveIcon} />*/}
          {/*</div>*/}
        </div>
      </StyledChecklistHeader>
    </>
  );
};

export default ChecklistHeader;
