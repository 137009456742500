import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { view } from "../../../utils/API";
import {
  StyledAnalytics,
  StyledFilterButton,
  StyledFilterWrapper,
  StyledLoaderWrapper,
} from "./Insights-styled";
import { useSelector } from "react-redux";
import { ConfigContext } from "../../../utils/ConfigContext";
import { polygonVertices16 } from "@esri/calcite-ui-icons";
import { CustomLoader, CustomSwitch } from "../../App/App-styled";
import { ROLE_EDITOR } from "../../../utils/helper";
import {
  applyCurrentFilters,
  composeIsoAndIsDeletedWhereCondition,
  composeWhereCondition,
} from "../../Filter/ApplyFilters";
import useFilters from "../../../hooks/useFilters";
import { isWidgetDisplayed } from "../../../esri/widgets/expandUtils";
import { loadModules } from "esri-loader";
import InsightsSCP from "./InsightLayers/InsightsSCP";
import InsightsStockPrepositioning from "./InsightLayers/InsightsStockPrepositioning";

const editorFields = [
  {
    name: "storagecapm2",
    type: "count",
  },
  {
    name: "managerorg",
    type: "count",
  },
  {
    name: "storagecapm2",
    type: "sum",
  },
  {
    name: "currutilizationperc",
    type: "avg",
  },
  {
    name: "communityshare",
    values: ["0", "1", "2"],
    type: "sum",
  },
  {
    name: "communityshare",
    type: "count",
  },
  {
    name: "storageclimatecontrol",
    values: ["0", "1", "2"],
    type: "sum",
  },
  {
    name: "ccstoragecapminus15m2",
    type: "sum",
  },
  {
    name: "ccstoragecap2to8m2",
    type: "sum",
  },
  {
    name: "ccstoragecap8to15m2",
    type: "sum",
  },
  {
    name: "ccstoragecap15to25m2",
    type: "sum",
  },
];

const publicFields = [
  {
    name: "storagecapm2",
    type: "sum",
  },
  {
    name: "ccstoragecapminus15m2",
    type: "sum",
  },
  {
    name: "ccstoragecap2to8m2",
    type: "sum",
  },
  {
    name: "ccstoragecap8to15m2",
    type: "sum",
  },
  {
    name: "ccstoragecap15to25m2",
    type: "sum",
  },
  {
    name: "currutilizationperc",
    type: "avg",
  },
  {
    name: "numpartners",
    type: "sum",
  },
  {
    name: "numwh",
    type: "sum",
  },
  {
    name: "numwhccs",
    type: "sum",
  },
  {
    name: "numcommunityshare",
    type: "sum",
  },
];

export const COLORS = {
  green: "#00C49F",
  blue: "#0088FE",
  orange: "#FF8042",
  dark: "#BEBEBE",
  grey: "#E8E8E8",
  white: "#FFFFFF",
  red: "#c03a2b",
  yellow: "#f0e68c",
};

const getSCPStatsDefinition = (config, layer) => {
  const fields = config.role === ROLE_EDITOR ? editorFields : publicFields;

  const filteredFields = fields.filter((field) =>
    layer.fields.some((f) => f.name === field.name)
  );

  // Creates a query object for statistics of each of the fields listed above
  const statDefinitions = [];
  filteredFields.map((field) => {
    if (Array.isArray(field.values)) {
      field.values.forEach((val) => {
        statDefinitions.push({
          onStatisticField: `CASE WHEN ${field.name} = ${val} THEN 1 ELSE 0 END`,
          outStatisticFieldName: field.name + "_" + field.type + "_" + val,
          statisticType: field.type,
        });
      });
    } else {
      statDefinitions.push({
        onStatisticField: field.name,
        outStatisticFieldName: field.name + "_" + field.type,
        statisticType: field.type,
      });
    }
  });

  return {
    defintion: statDefinitions,
  };
};

export const stocksKeyByModule = {
  prp: "quantity70pctprep",
  ant: "quantityanticipation1",
  res: "quantityanticipation2",
  stp: "quantity70pctprep",
};

export const getStockPrepositioningStatsDefinition = async (
  layer,
  mapFilters,
  config,
  activeModule,
  filters
) => {
  let expectedKey;
  if (layer?.layerConfig?.expectedQuantityField) {
    expectedKey = layer.layerConfig.expectedQuantityField;
  } else {
    expectedKey = stocksKeyByModule[activeModule.toLowerCase()];
  }

  const fields = [
    {
      name: "quantity",
      type: "sum",
    },
    {
      name: expectedKey,
      type: "sum",
    },
    {
      name: "hscode",
      type: "min",
    },
    {
      name: "hscode",
      type: "max",
    },
    {
      name: "asofdate",
      type: "max",
    },
  ];

  const filteredFields = fields.filter((field) =>
    layer.fields.some((f) => f.name === field.name)
  );

  // Creates a query object for statistics of each of the fields listed above
  const statDefinitions = [];
  filteredFields.map((field) => {
    if (Array.isArray(field.values)) {
      statDefinitions.push({
        onStatisticField: `CASE WHEN ${field.name} = '' THEN ${field.name} ELSE 0 END`,
        outStatisticFieldName: field.name,
        statisticType: field.type,
      });
    } else {
      statDefinitions.push({
        onStatisticField: field.name,
        outStatisticFieldName: field.name + "_" + field.type,
        statisticType: field.type,
      });
    }
  });

  const statsDefinition = {
    defintion: statDefinitions,
    groupBy: ["locationname", "itemgroup", "unit"],
  };

  const internationalQuery = layer.createQuery();
  const domesticQuery = layer.createQuery();

  internationalQuery.outStatistics = statsDefinition.defintion;
  domesticQuery.outStatistics = statsDefinition.defintion;
  if (statsDefinition.groupBy) {
    internationalQuery.groupByFieldsForStatistics = statsDefinition.groupBy;
    domesticQuery.groupByFieldsForStatistics = statsDefinition.groupBy;
  }
  const oldFiltered = layer.layerConfig.filtered;
  layer.layerConfig.filtered = false;
  internationalQuery.where = composeIsoAndIsDeletedWhereCondition(
    layer,
    config
  );
  layer.layerConfig.filtered = oldFiltered;
  domesticQuery.where = composeIsoAndIsDeletedWhereCondition(layer, config);

  const where = `${composeWhereCondition(layer, mapFilters)}`;

  if (where) {
    internationalQuery.where.length > 0
      ? (internationalQuery.where += `AND ${where}`)
      : (internationalQuery.where += `${where}`);
    domesticQuery.where += ` AND ${where}`;
  }
  // international 2, national 1
  internationalQuery.where +=
    internationalQuery.where.length > 0
      ? " AND typepreposition = 2"
      : "typepreposition = 2";
  domesticQuery.where += " AND typepreposition = 1";
  const international = await layer.queryFeatures(internationalQuery);
  const domestic = await layer.queryFeatures(domesticQuery);
  console.log(domestic, international);

  return {
    international: international.features,
    domestic: domestic.features,
    expectedKey,
  };
};

const Insights = ({ expand, showSidebar, closeSidebar }) => {
  //filtered layer and layerView
  const l = useRef();
  const lv = useRef();

  const [layerConfig, setLayerConfig] = useState(undefined);
  const [statsData, setStatsData] = useState([]);

  const [stockData, setStockData] = useState({
    international: [],
    domestic: [],
    expectedKey: "",
  });

  const [loading, setLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const { layersLoading, activeModule } = useSelector((state) => state);
  const [filters, setFilters] = useState({
    byExtent: undefined, //Calcite requires undefined for false values
    geometry: null,
  });

  const listeners = useRef([]);
  const sketchViewModel = useRef();
  const { config } = useContext(ConfigContext);
  const { filters: mapFilters, setFilters: setMapFilters } = useFilters({
    config,
  });
  const queryLayerViewStats = async () => {
    let layer = l.current;
    const layerView = lv.current;

    if (!layer || !layerView) {
      return;
    }

    if (!isWidgetDisplayed(config, "Filter")) {
      applyCurrentFilters(mapFilters[activeModule], config);
    }

    // const insights = config.insights;
    // if (!insights) return;
    const insightsLayer =
      layer?.layerConfig?.extends || layer?.layerConfig?.alias; //config.role === ROLE_EDITOR ? insights.editorLayer : insights.publicLayer;

    //temporarily for demo
    if (
      insightsLayer === "stockpreposition" ||
      insightsLayer === "stockpreposition_demo"
    ) {
      const res = await getStockPrepositioningStatsDefinition(
        layer,
        mapFilters[activeModule],
        config,
        activeModule,
        filters
      );
      const { domestic = [], international = [], expectedKey = "" } = res;

      setStockData({
        domestic,
        international,
        expectedKey,
      });
      return res;
    } else {
      //default for all layers
      const statsDefinition = getSCPStatsDefinition(config, layer);

      const query = layer.createQuery();

      query.outStatistics = statsDefinition.defintion;
      if (statsDefinition.groupBy) {
        query.groupByFieldsForStatistics = statsDefinition.groupBy;
      }

      query.where = composeIsoAndIsDeletedWhereCondition(layer, config);

      if (filters.byExtent) {
        query.geometry = view.extent;
      }

      if (filters.geometry) {
        query.geometry = filters.geometry.extent;
      }

      const where = `${composeWhereCondition(layer, mapFilters[activeModule])}`;

      if (where) {
        query.where += `AND ${where}`;
      }

      const res = await layer.queryFeatures(query);

      const stats = res.features;
      setStatsData(stats);
      return res;
    }
  };

  const onFilterChange = (filterObj) => {
    let _filters = JSON.parse(JSON.stringify(mapFilters));
    const allFilters = _filters[activeModule];

    Object.keys(filterObj).forEach((k) => {
      allFilters[k] = filterObj[k];
    });

    const newFilter = {};
    Object.keys(allFilters).map((k) => {
      const value = allFilters[k];
      if (value) {
        newFilter[k] = allFilters[k];
      }
    });

    _filters[activeModule] = newFilter;

    setMapFilters(_filters);
  };

  useEffect(() => {
    return () => {
      resetInsights();
    };
  }, []);

  const resetInsights = useCallback(() => {
    setIsReady(false);
    lv.current = null;
    l.current = null;
    setFilters({
      where: {},
      byExtent: undefined, //Calcite requires undefined for false values
      geometry: null,
    });
    if (sketchViewModel.current && sketchViewModel.current.layer) {
      sketchViewModel.current.layer.removeAll();
      sketchViewModel.current = null;
    }

    listeners.current?.forEach((l) => {
      l.remove();
    });
    listeners.current = [];
    setLayerConfig(undefined);
    closeSidebar();
  }, [onFilterChange]);

  useEffect(() => {
    // const insights = config.insights;

    if (layersLoading) return;
    // const layer = config.role === ROLE_EDITOR ? insights.editorLayer : insights.publicLayer;
    //
    // if (!layer) return;
    //
    // const layers = [layer];
    view.map.layers.forEach((layer) => {
      const showLayer =
        config.role === ROLE_EDITOR
          ? layer.layerConfig?.isShownInEditorInsights
          : layer.layerConfig?.isShownInPublicInsights;
      if (layer.visible && showLayer) {
        l.current = layer;
        setLayerConfig(layer.layerConfig);
        setIsReady(true);
      }

      // if (layer.visible && (layers.includes(layer.layerConfig?.alias) || layers.includes(layer.layerConfig?.extends))) {
      //   const insightsLayer = config.role !== ROLE_EDITOR ? insights.publicLayer : insights.editorLayer;
      //   if (layer.layerConfig?.alias === insightsLayer || layer.layerConfig?.extends === insightsLayer) {
      //     l.current = layer;
      //     setLayerConfig(layer.layerConfig)
      //     setIsReady(true);
      //   }
      // }
    });

    view?.map?.layers?.forEach((layer) => {
      const showLayer =
        config.role === ROLE_EDITOR
          ? layer.layerConfig?.isShownInEditorInsights
          : layer.layerConfig?.isShownInPublicInsights;
      if (showLayer) {
        const listener = layer.watch("visible", (visible) => {
          if (visible) {
            l.current = layer;
            setLayerConfig(layer.layerConfig);
            setIsReady(true);
            showSidebar();
          } else {
            closeSidebar();
            resetInsights();
          }
        });
        listeners.current.push(listener);
      }

      // if (layers.includes(layer.layerConfig?.alias) || layers.includes(layer.layerConfig?.extends)) {
      //   const listener = layer.watch("visible", (visible) => {
      //     if (visible) {
      //       const insightsLayer = config.role !== ROLE_EDITOR ? insights.publicLayer : insights.editorLayer;
      //       if (layer.layerConfig?.alias === insightsLayer || layer.layerConfig?.extends === insightsLayer) {
      //         l.current = layer;
      //         setLayerConfig(layer.layerConfig)
      //         setIsReady(true);
      //         showSidebar();
      //       }
      //     } else {
      //       closeSidebar();
      //       resetInsights();
      //     }
      //   })
      //   listeners.current.push(listener);
      // }
    });
  }, [layersLoading]);

  useEffect(() => {
    if (!isReady) return;
    const layer = l.current;

    if (listeners.current?.length > 0) {
      listeners.current.forEach((l) => {
        l.remove();
      });
      listeners.current = [];
    }

    const handleLayerViewStats = () => {
      //todo check for bugs
      setLoading(true);
      queryLayerViewStats()
        .then((newData) => {
          setLoading(false);
          // handleData(newData) //todo from component declaring
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    layer.outFields = ["*"];
    layer.load().then(() => {
      view.whenLayerView(layer).then((layerView) => {
        lv.current = layerView;

        handleLayerViewStats();

        loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) => {
          const stationary = reactiveUtils.when(
            () => !!view?.stationary,
            () => {
              if (!filters?.byExtent) return;

              handleLayerViewStats();
            },
            { initial: false }
          );
          listeners.current.push(stationary);
        });
      });
    });
    return () => {
      listeners.current.forEach((l) => {
        l.remove();
      });
      listeners.current = [];
    };
  }, [isReady, filters, mapFilters, activeModule]);

  //init sketch
  useEffect(() => {
    if (!view) return;
    if (!isReady) return;
    const handles = [];
    handles.forEach((h) => {
      h.remove();
    });
    loadModules([
      "esri/widgets/Sketch/SketchViewModel",
      "esri/layers/GraphicsLayer",
      "esri/geometry/geometryEngineAsync",
    ]).then(([SketchViewModel, GraphicsLayer, geometryEngineAsync]) => {
      const polygonGraphicsLayer = new GraphicsLayer();
      view.map.add(polygonGraphicsLayer);

      // create a new sketch view model set its layer
      const svw = new SketchViewModel({
        view: view,
        layer: polygonGraphicsLayer,
      });

      sketchViewModel.current = svw;

      const createHandle = svw.on("create", async (event) => {
        if (event.state === "complete") {
          // this polygon will be used to query features that intersect it
          const geometries = polygonGraphicsLayer.graphics.map(function (
            graphic
          ) {
            return graphic.geometry;
          });

          const queryGeometry = await geometryEngineAsync.union(
            geometries.toArray()
          );
          setFilters((prev) => ({
            ...prev,
            geometry: queryGeometry,
            byExtent: undefined,
          }));
        }
      });

      let timer;
      const updateHandle = svw.on("update", async () => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          // this polygon will be used to query features that intersect it
          const geometries = polygonGraphicsLayer.graphics.map(function (
            graphic
          ) {
            return graphic.geometry;
          });

          const queryGeometry = await geometryEngineAsync.union(
            geometries.toArray()
          );
          setFilters((prev) => ({
            ...prev,
            geometry: queryGeometry,
            byExtent: undefined,
          }));
        }, 250);

        handles.push(createHandle, updateHandle);
      });
    });

    return () => {
      handles.forEach((h) => {
        h.remove();
      });
    };
  }, [isReady]);

  const handleDraw = useCallback(() => {
    const sketchVW = sketchViewModel.current;
    if (!sketchVW) return;

    sketchVW.create("polygon");
  }, []);

  const handleSwitchChange = useCallback((e) => {
    if (sketchViewModel.current && sketchViewModel.current.layer.removeAll) {
      sketchViewModel.current.layer.removeAll();
    }

    setFilters((prev) => ({
      ...prev,
      byExtent: e.target.checked || undefined,
      geometry: null,
    }));
  }, []);

  const renderInsights = () => {
    // const insights = config.insights;
    // if (!insights) return;

    // const insightsLayer = config.role !== ROLE_EDITOR ? insights.publicLayer : insights.editorLayer;
    const insightsLayer =
      l.current?.layerConfig.extends || l.current?.layerConfig.alias;
    if (!insightsLayer) return;

    if (["whAdmin", "whReports"].includes(insightsLayer)) {
      return (
        <InsightsSCP
          expand={expand}
          closeSidebar={closeSidebar}
          showSidebar={showSidebar}
          onFilterChange={onFilterChange}
          statsData={statsData}
        />
      );
    } else if (
      ["stockpreposition", "stockpreposition_demo"].includes(insightsLayer)
    ) {
      return (
        <InsightsStockPrepositioning
          expand={expand}
          closeSidebar={closeSidebar}
          showSidebar={showSidebar}
          onFilterChange={onFilterChange}
          statsData={stockData}
          layer={l.current}
          filters={filters}
          setFilters={setFilters}
        />
      );
    }
    return null;
  };

  // const insightsLayer = useMemo(()=>{
  //   const insights = config.insights || {};
  //   return config.role !== ROLE_EDITOR ? insights.publicLayer : insights.editorLayer;
  // },[config])

  const insightsLayer =
    l.current?.layerConfig?.extends || l.current?.layerConfig?.alias;

  return (
    <StyledAnalytics>
      {loading && (
        <StyledLoaderWrapper>
          <CustomLoader fontSize="3rem" />
        </StyledLoaderWrapper>
      )}
      {insightsLayer &&
        !(
          insightsLayer === "stockpreposition" ||
          insightsLayer === "stockpreposition_demo"
        ) && (
          <StyledFilterWrapper>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  fontSize: 12,
                  lineHeight: 1,
                  marginRight: 6,
                  color: "#393738",
                }}
              >
                Filter by map extent
              </span>
              <CustomSwitch
                checked={filters.byExtent}
                onCalciteSwitchChange={handleSwitchChange}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  fontSize: 12,
                  lineHeight: 1,
                  marginRight: 4,
                  color: "#393738",
                }}
              >
                Filter by selection
              </span>
              <StyledFilterButton onClick={handleDraw}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#535353"
                >
                  <path d={polygonVertices16} />
                </svg>
              </StyledFilterButton>
            </div>
          </StyledFilterWrapper>
        )}
      {renderInsights()}
    </StyledAnalytics>
  );
};

export default Insights;
