import React, {useState, useEffect, useMemo, useRef} from "react";

import Select from 'react-select';

import {FilterFieldComboContainer} from "./Filter-styled";
import {isYesNoDomain, renderCellInEditor, renderMobileCell} from "../../utils/helper";
import {DomainFilter, ShowPublicFilter} from "../MobileApp/MobileFilter/MobileFilter";
import CustomSwitch from "../CustomSwitch/CustomSwitch";
import {showpublicFieldName} from "../../utils/API";

const FilterFieldCombo = (props) => {
	const [selectedValues, setSelectedValues] = useState(
		props.defaultValue ? props.defaultValue :
			props.multi ? [] : ""
	)
	const selectRef = useRef();
	
	
	useEffect(() => {
		setSelectedValues(props.defaultValue ? props.defaultValue :
			props.multi ? [] : "")
	}, [props.defaultValue])

	let handleValueSelection = (value) => {
		let selected;
		if (props.multi){
			selected = [];
			value.forEach(val => {
				if (Array.isArray(val.value)){
					selected.push(...val.value);
				} else {
					selected.push(val.value);
				}
			})
		} else {
			selected = value.value
		}
		
		setSelectedValues(value)
		props.onValuesChange(props.referenceKey, selected)
	}

	const handleTextEntry = (evt) => {
		if (evt.target.value) {
			setSelectedValues([evt.target.value]);
			props.onValuesChange(props.referenceKey, props.multi ? [evt.target.value] : evt.target.value)
		} else {
			setSelectedValues([])
			props.onValuesChange(props.referenceKey, [])
		}
	}

	const ReactSelectStyles = () => ({
		control: baseStyles => ({
			...baseStyles,
			fontSize: "12px",
			marginLeft: 0
		}),
		multiValueLabel: baseStyles => ({
			...baseStyles,
			whiteSpace: "normal",
		}),
	});
	const opsColor = '#eee';
	const mobileSelectStyles = () =>({
		control: (baseStyles, state) => {
			return {
				...baseStyles,
				margin: '0px 2px',
				color:'#000',
				borderColor: opsColor,
				boxShadow: state.isFocused && opsColor ? `0 0 0 1px ${opsColor}` : baseStyles.boxShadow,
				'&:hover': {
					borderColor: state.isFocused && opsColor ? opsColor : baseStyles.borderColor,
				},
				fontSize: 12,
				minHeight: 20,
			}
		},
		option:(baseStyles, state) => ({
			...baseStyles,
			fontSize:10,
			backgroundColor: state.isSelected && opsColor ? opsColor : state.isFocused && opsColor ? `${opsColor}1a` : baseStyles.backgroundColor,
			color: state.isSelected && opsColor ? '#000' : baseStyles.color,
			'&:hover': {
				backgroundColor: !state.isSelected ? `${opsColor}1a` : opsColor,
			},
		}),
		multiValue:(baseStyles, state)=>{
			const value = state.data.value;
			return {
				...baseStyles,
				borderRadius: 100,
				backgroundColor: props.colors[value] ?? '#808080d9',
				color:'#fff',
				padding:'6px 8px',
				fontWeight:500
			}
		},
		multiValueLabel:(baseStyles, state)=>({
			...baseStyles,
			color:'#fff',
			padding:0,
			fontSize:12,
		}),
		input: (baseStyles, state)=>{
			return {
				...baseStyles,
				height: 20,
			}
		},
		indicatorsContainer: (baseStyles) =>({
			...baseStyles,
			alignItems: 'flex-start',
			padding:4
		}),
		indicatorContainer:(baseStyles) =>({
			...baseStyles,
			padding:4
		}),
		MultiValueRemove: (baseStyles)=>({
			...baseStyles,
			'&:hover': {
				backgroundColor: 'transparent',
			},
		}),
		menuPortal: base => {
			const { zIndex, ...rest } = base;
			return { ...rest, zIndex: 9999 };
		},
		menuList: base => ({
			...base,
			maxHeight:200
		})
	})

	const renderCellValue = useMemo(() => {
		if (props.field.length > 60 && Array.isArray(props.defaultValue)) {
			 return props.defaultValue.join('');
		}
		return ""
	},[props.defaultValue, props.field])
	
	const renderField = () =>{
		const field = props.field
		
		if (field.domain && field.domain.codedValues) {
			let fieldValues = []
			if (field.name === showpublicFieldName){
				fieldValues.push(
					{
						label: props.t('layer.domain.showpublic.filter.1'),
						value: 1
					},
					{
						label: props.t('layer.domain.showpublic.filter.2'),
						value: 2
					},
					{
						label: props.t('layer.domain.showpublic.filter.all'),
						value: []
					}
				)
			} else {
				if (field.nullable)
					fieldValues.push({label: props.t('screen.domain.unspecified'), value: null})
				
				field.domain.codedValues.forEach((codedValue) => {
					fieldValues.push({
						label: props.t(`layer.domain.${field.name}.${codedValue.code}`, codedValue.name),
						value: codedValue.code
					})
				})
			}
			let defaultValues = null
			
			if (selectedValues) {
				if (Array.isArray(selectedValues)) {
					defaultValues = selectedValues.map((val) => {
						if (val?.label) {
							return val
						}
						return fieldValues.filter((fieldVal)=> fieldVal.value == val)[0]
					})
				} else {
					defaultValues = fieldValues[selectedValues]
				}
			}
			
			if (props.isMobile && field.domain.codedValues.length < 6){
				
				const isSwitchable = isYesNoDomain(field) && showpublicFieldName !== field.name
				if (isSwitchable){
					let isChecked = false;
					if (Array.isArray(defaultValues) && defaultValues.length > 0) {
						isChecked = defaultValues[0].value === 1;
					}
					
					return (
						<CustomSwitch
							labelA={props.t('layer.domain.' + field.name +  '.2')}
							labelB={props.t('layer.domain.' + field.name +  '.1')}
							checked={isChecked}
							onChange={(checked)=>{
								const value = checked ? 1 : [null, 0, 2]
								handleValueSelection([{label: props.t('layer.domain.' + field.name +  `${value}`), value: value}])
							}}
						/>
					)
				}	else {
					
					if (showpublicFieldName === field.name) {
						return (
							<ShowPublicFilter
								onChange={handleValueSelection}
								options={fieldValues}
								value={defaultValues}
								colors={props.colors}
							/>
						)
						
					}	else {
						return (
							<DomainFilter
								onChange={handleValueSelection}
								options={fieldValues}
								value={defaultValues}
								colors={props.colors}
							/>
						)
					}
					
					
				}
			} else {
				return (
					<Select
						id={field.name + 'select'}
						ref={selectRef}
						defaultValue={defaultValues}
						closeMenuOnSelect={!props.multi}
						menuPosition={'fixed'}
						isMulti={props.multi}
						onChange={(...props)=>{
							if (selectRef.current){
								selectRef.current.blur();
							}
							handleValueSelection(...props)
						}}
						styles={mobileSelectStyles()}
						options={fieldValues}
						value={defaultValues}
						classNamePrefix={'react-select'}
						isSearchable={false}
						menuPortalTarget={document.querySelector(`body`)}
						placeholder={props.t('screen.component.select.placeholder')}
					/>
				)
			}
			
		} else {
			if (props.isMobile){
				return renderMobileCell(field, renderCellValue, handleTextEntry)
			} else {
				return renderCellInEditor(field, renderCellValue, handleTextEntry)
			}
		}
	}
	
	return (
		<FilterFieldComboContainer>
			{!props.isMobile && <label>{props.t("layer.fieldAlias." + props.field.name, props.field.alias)}</label>}
			{renderField()}
		</FilterFieldComboContainer>
	)
}

export default FilterFieldCombo
