import {cutFeatures, getGraphic} from "../../BatchEditor/helper";
import {getLineSymbol} from "../../BatchEditor/Symbols";
import {selectionLineSymbol} from "./CoordinatesSelection/RoadsCoordinates";
import {graphicsLayer, view} from "../../../utils/API";
import {LineSymbol} from "../../ReportManager/symbols";
import {EditType, fillSymbol, LAYER_EFFECT, lineSymbol, pointSymbol} from "./EditorSwiper/EditorSwiper";
import {useCallback, useMemo} from "react";
import {baseLineColor} from "./EditorContextProvider";
import {loadModules} from "esri-loader";
import {getLayerSymbology} from "../../../utils/symbologies";
import {getFieldsByPattern} from "../../../utils/helper";
import {batchUpdateFields} from "./EditorFields/EditorSituationalFields";

export const calculateDistanceFromPixels = (tolerance, map, Point, Extent) => {
  let screenPoint = map.toScreen(map.extent.center);
  
  let upperLeftScreenPoint = new Point(screenPoint.x - tolerance, screenPoint.y - tolerance);
  let lowerRightScreenPoint = new Point(screenPoint.x + tolerance, screenPoint.y + tolerance);
  
  let upperLeftMapPoint = map.toMap(upperLeftScreenPoint);
  let lowerRightMapPoint = map.toMap(lowerRightScreenPoint);
  
  let ext = new Extent(upperLeftMapPoint.x, upperLeftMapPoint.y, lowerRightMapPoint.x, lowerRightMapPoint.y, map.spatialReference);
  return ext.width;
}

const roadFragmentInSelectionSymbol = getLineSymbol("#00FF00", 1)
export const cutFeaturesAsync = (features, selectionPolygon, {geometryEngine, Polyline, Point, isPointerMove}) => {
  
  const notContaining = features.filter(f=>{
    return !geometryEngine.contains(selectionPolygon, f.geometry)
  })
  
  const containing = features.filter(f=>{
    return geometryEngine.contains(selectionPolygon, f.geometry)
  })
  
  const newPath = [];
  if (notContaining.length > 0){
    const unionNotContaining = geometryEngine.union(notContaining.map((f)=>f.geometry));
    if (unionNotContaining.paths.length > 0) {
    
      unionNotContaining.paths.forEach(path=>{
        const arr = [[]]
        path.map(async (pointArr)=>{
          const [x,y] = pointArr;
        
          const point = new Point({
            x,
            y
          });
        
          const contains = geometryEngine.contains(selectionPolygon, point);
        
          if (contains){
            arr[arr.length-1].push(pointArr);
          } else if (arr[arr.length - 1].length > 0){
            arr.push([]);
          }
        })
        arr.forEach(pathArr=>{
          if (pathArr.length > 0){
            newPath.push(pathArr);
          }
        })
      })
    }
  }
  
  const polyline = new Polyline({
    paths: newPath
  })
  
  const result = geometryEngine.union([...containing.map(f=>f.geometry), polyline]);
  // const simplify = geometryEngine.simplify(result);
  // console.log(geometryEngine.isSimple(result));
  
  return result
}

const cutFragments = async (fragments, pathIndex, ring, esriModules, path) =>{
  const endPoint = (pathIndex === ring.length - 1) ? 0 : pathIndex + 1
  const newFragments = []
  const geometryEngineAsync = esriModules.geometryEngineAsync;
  
  await Promise.all(fragments.map(async (fragment) => {
    const cut = await geometryEngineAsync.cut(fragment, new esriModules.Polyline({paths: [path, ring[endPoint]]}))
    if (cut?.length > 0)
      cut.forEach((c) => newFragments.push(c))
    else
      newFragments.push(fragment)
  }))
  if (newFragments?.length > 1) {
    await cutFragments(newFragments)
  }
}

export const queryLayer = async (layer, selection, bufferNumber, clickedFeaturesIds, {geometryEngine, geometryEngineAsync, Polyline, Graphic, webMercatorUtils, Point}) =>{
  try {
    const layerObjectId = layer.objectIdField;
    const lv = await view.whenLayerView(layer);
    const res = await layer.queryFeatures({
      where: layer.definitionExpression,
      geometry: selection,
      spatialRelationship: "intersects",
      returnGeometry: true,
      outFields: ['*']
    })
    
    if (!res.features.length) return;
   
    const newPath = [];
    const final = cutFeaturesAsync(res.features, selection, {geometryEngine, Polyline, Point})
    
    const graphic = new Graphic({
      geometry: final,
      symbol: {
        ...selectionLineSymbol,
        color: baseLineColor
      },
      attributes: {}
    })
    return graphic;
  } catch (err) {
    console.log(err)
  }
  return null;
}

export const addLayerEffect = (keepLayers = []) => {
  const mapLayers = view.map.layers.filter((layer)=>{
    if (keepLayers.some(kl=> !!layer.originalId ? kl.originalId === layer.originalId : kl.originalId === layer.layerConfigId)) {
      layer.effect = undefined;
      return true
    }
    if (layer.id === graphicsLayer.id) {
      layer.effect = undefined;
      return true
    }
    
    if (layer.title === 'wld_bnd_adm0') {
      layer.effect = undefined;
      return true
    }
    
    layer.effect = LAYER_EFFECT;
    return false
  })
}

export const removeLayerEffects = (keepLayers = []) => {
  const mapLayers = view.map.layers.filter((layer)=>{
    if (keepLayers.some(kl=> !!layer.originalId ? kl.originalId === layer.originalId : kl.originalId === layer.layerConfigId)) {
      layer.effect = LAYER_EFFECT;
      return true
    }
    if (layer.id === graphicsLayer.id) {
      layer.effect = LAYER_EFFECT;
      return true
    }
    
    if (layer.title === 'wld_bnd_adm0') {
      layer.effect = LAYER_EFFECT;
      return true
    }
    
    layer.effect = undefined;
    return false
  })
}

export const getFeatureNameField = (editableLayer) => {
  const titleTemplate = editableLayer?.layerConfig?.titleTemplate;
  const name = titleTemplate?.substring(1, titleTemplate.length-1)?.replace('feature.', '');
  if (name){
    return name;
  }
  
  return null;
}

export const zoomToFeaturesExtent = (features) => {
  loadModules(["esri/geometry/Polyline"]).then(([Polyline])=>{
    const paths = [];
  
    try {
      features.forEach((f) => {
        if (f.geometry?.type === 'point') {
          paths.push([f.geometry.longitude, f.geometry.latitude]);
        } else if (f.geometry.type === 'polyline' && Array.isArray(f.geometry.paths[0]) && f.geometry.paths[0].length > 0) {
          paths.push(f.geometry.paths[0][0]);
        }
      });
    
      if (paths.length > 0) {
        const polyline = new Polyline({
          paths: paths
        });
        view.goTo(polyline.extent.expand(1.2), {
          duration: 100,
          zoom: features.length === 1 ? 10 : undefined
        });
      }
    } catch (error) {
      console.error('Error processing features:', error);
    }
  })
}

export const createFeatureGraphic = async (feature, editableLayer)=>{
  try {
    let editingSymbol;
    
    if (editableLayer.layerConfig.titleLabel === 'conops') {
      editingSymbol = pointSymbol;
    } else {
      editingSymbol = await feature.layer?.renderer?.getSymbolAsync(feature);
    }
    
    const nGraphic = feature.clone()
    nGraphic.symbol = editingSymbol ?? pointSymbol
    return nGraphic;
  } catch (err){
    return feature;
  }
}

export const getSituationalBatchFields = ({
  editableLayer,
  config,
})=>{
  if (!editableLayer || !editableLayer?.layerConfig) return [];
  const symbology = getLayerSymbology(editableLayer, config) || {}
  const lc = editableLayer.layerConfig;
  
  let fields = getFieldsByPattern(editableLayer, lc.situationalFields);
  
  //filtering fields for batch editor
  const {colorMap} = symbology;
  const symbologyFields = [];
  
  if (colorMap?.fields){
    symbologyFields.push(...colorMap.fields);
  } else if (colorMap?.field) {
    symbologyFields.push(colorMap.field);
  }
  
  fields = fields.filter(field=> batchUpdateFields.includes(field.name) || symbologyFields.includes(field.name));
  
  if (fields.length === 0){
    fields = getFieldsByPattern(editableLayer, lc.situationalFields);
  }
  
  return fields;
}
