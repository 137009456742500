import {
  ChecklistSidebarBox,
  ChecklistSidebarBoxContent,
  ChecklistSidebarBoxIcon,
  ChecklistSidebarBoxTitle, ChecklistSidebarCheckboxWrapper
} from "../../Monitoring-styled";
import ModuleIcon from "../../../../Modules/ModuleIcon";
import React, {useCallback, useMemo, useState} from "react";
import {view} from "../../../../../utils/API";
import LayerIcon from "../../../../Icons/LayerIcon";
import {useDispatch, useSelector} from "react-redux";
import {setActiveModule} from "../../../../../redux/action/ActiveModule-actions";
import Message from "../../../Editor/Message/Message";
import {calculateUpToDateFeaturesPercentage} from "../../../../../redux/reducers/cycleManager";

export const Checkbox = (props) => {
  return (
    <ChecklistSidebarCheckboxWrapper>
      <input readOnly id={props.id} type="checkbox" style={{ display: 'none' }} {...props} />
      <label htmlFor={props.id}>
        <span>
          <svg width="12px" height="9px" viewBox="0 0 12 9">
            <polyline points="1 5 4 8 11 1"></polyline>
          </svg>
        </span>
      </label>
    </ChecklistSidebarCheckboxWrapper>
  );
};

const SidebarModule = ({module, moduleName, moduleLayers, editableLayers, selected, onClick, onLayerSelect, editableLayer, config, t}) => {
  const activeModule = useSelector(state=>state.activeModule);
  const {layersUpdates} = useSelector(state=>state.cycleManagerReducer);
  const dispatch = useDispatch();
  const layers = useMemo(() =>
      view.map.layers.filter(layer =>
        layer.layerConfig && moduleLayers.includes(layer.layerConfig.alias) && editableLayers.some(l=>l.layerConfig?.id === layer.layerConfig.id)
      ),
    [moduleLayers, editableLayers]
  )
  
  const handleModuleSelection = useCallback(()=>{
    dispatch(setActiveModule(module))
    if (layers.length > 0) {
      const firstLayer = layers.toArray()[0]
      onLayerSelect(firstLayer);
    }
  },[module, onLayerSelect])
  
  const isSelected = useMemo(()=>activeModule === module, [module, activeModule])
  
  const completion = useMemo(()=>{
    const completed = layers.filter(layer=>{
      const {outdated} = layersUpdates[layer.layerConfig?.id] || {}
      return Array.isArray(outdated) && outdated.length === 0
    })
    
    return `${completed.length}/${layers.length}`
  },[layers])
  
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4
        }}
        onClick={handleModuleSelection}
      >
        <ChecklistSidebarBox
          style={{
            background:'transparent'
            // boxShadow: isSelected ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' : ''
          }}
        >
          <ChecklistSidebarBoxContent>
            <ModuleIcon
              style={{
                padding:6
              }}
              module={module}
              opsColor={config.opsColor}
            />
            <ChecklistSidebarBoxTitle>
              <p>
                {moduleName}
              </p>
              <span>
              {completion} completed
            </span>
            </ChecklistSidebarBoxTitle>
            <ChecklistSidebarBoxIcon isSelected={isSelected}>
              <svg
                style={{
                  transform:`rotate(${isSelected ? 90 : 0}deg)`,
                  transition:'all 0.3s',
                  transitionDelay:100
                }}
                stroke="currentColor"
                fill="#909090"
                strokeWidth="0"
                viewBox="0 0 320 512"
                height="14px"
                width="14px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
              </svg>
            </ChecklistSidebarBoxIcon>
          </ChecklistSidebarBoxContent>
        </ChecklistSidebarBox>
        <div style={{marginLeft:14, display:isSelected ? 'flex' : 'none', flexDirection:'column', gap:4, }}>
          {
            layers.map(layer=>{
              const {features=[], outdated} = layersUpdates[layer.layerConfig?.id] || {}
              const percent = calculateUpToDateFeaturesPercentage({
                [layer.layerConfig?.id]: layersUpdates[layer.layerConfig?.id]
              })
              
              const isCompleted = outdated ? outdated.length === 0 : false;
              
              return (
                <ChecklistSidebarBox
                  key={layer.id}
                  onClick={(e)=>{
                    e.stopPropagation();
                    onLayerSelect(layer);
                  }}
                  bg={editableLayer === layer ? undefined : 'transparent'}
                  style={{
                    border:`1px solid #eee`,
                    boxShadow: editableLayer === layer ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' : ''
                }}
                >
                  <ChecklistSidebarBoxContent>
                    <Checkbox
                      id={layer.id}
                      checked={isCompleted}
                      disabled={isCompleted}
                      // onChange={(e)=>{
                      //   const {checked} = e.target;
                      //   if (!checked) return;
                      //   e.preventDefault();
                      //   setShowMessage(true);
                      // }}
                    />
                    <ChecklistSidebarBoxTitle elipsis isCompleted={isCompleted}>
                      <p>
                        {layer.getLayerTitle(t)}
                      </p>
                      <span>
                    {percent}% completed
                  </span>
                    </ChecklistSidebarBoxTitle>
      
                    <LayerIcon
                      config={config}
                      layer={layer}
                      t={t}
                      width={24}
                      height={24}
                      textColor="#344054"
                    />
                  </ChecklistSidebarBoxContent>
                </ChecklistSidebarBox>
              )
            })
          }
        </div>
      </div>
    </>
  );
};

export default SidebarModule;
