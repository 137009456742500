import styled, {css, keyframes} from "styled-components";
import { CalciteSwitch } from "@esri/calcite-components-react";
import Filter from "../Filter";
import {CalciteTabTitle} from "@esri/calcite-components-react"

const popup = keyframes`
  0% {
    flex-basis:0;
    background: #dcdbdb80;
  }
  50% {
    flex-basis:16px;
    background: #dcdbdb80;
  }
  100% {
    flex-basis:0;
    background: transparent;
  }
`

const scaleAnimate = keyframes`
  0% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 0;
  }
  50% {
    transform: scale(1.1) translate(-46%, -50%);
    opacity: 1;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
`

const animationRule = css(
  ['', ' 1s ease-in-out'],
  popup
)

export const FeatureTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({isExpanded})=>isExpanded ? '3px 0' : '0px 0px'};
  position: relative;
  flex-basis: ${({ isExpanded, isDisclaimerShown }) => isExpanded ? `${window.innerHeight / 2.8}px` : isDisclaimerShown ? '0px' : '13px'};
  z-index: 2; //${({ isExpanded, isDisclaimerShown }) => (isExpanded && isDisclaimerShown) ? 2 : 2};
  width: ${({ isExpanded, isDisclaimerShown }) => (!isExpanded && isDisclaimerShown)? 'calc(100% - 22px)' : '100%'};
  background: #FFFFFF;
  //show disclaimer when table is expanded
  // &+ #disclaimer {
    //   z-index: ${({ isExpanded, isDisclaimerShown }) => (isExpanded && isDisclaimerShown)? 0 : 1};
    //   padding-left: ${({ isExpanded, isDisclaimerShown }) => (!isExpanded && isDisclaimerShown)? '91px' : '0px'};
    //   width: ${({ isExpanded, isDisclaimerShown }) => (!isExpanded && isDisclaimerShown)? 'calc(100% - 91px)' : '100%'};
  //   transition: all 0.5s;
  // }
  animation: ${({animate})=> animate ? animationRule : ''};
  #tabs {
    transition: opacity 0.5s linear;
    transition-delay: 0.4s;
    transition-property: opacity;
    opacity: ${({ isExpanded }) => isExpanded ? 1 : 0};
  }

  @media screen and (max-width: 863px) {
    flex-basis: ${({ isExpanded }) => isExpanded ? `${window.innerHeight / 2.8}px` : '20px'};
    overflow: ${({ isExpanded }) => isExpanded ? 'auto' : 'hidden'};
    //&+ #disclaimer {
    //  width: 100%;
    //  padding-left: 0;
    //}
  }

  .wfp--tab-content {
    flex:1
  }

  .wfp--tabs__nav-item {
    display: flex;
    align-items: center;


    .wfp--tabs__nav-link{
      color: black;
      .tab__title {
        display: flex;
        align-items: center;
        font-weight: 400;
        gap: 4px;

        img {
          width: 22px;
          height: 22px;
          filter: invert(1);
        }
      }
    }
  }

  .wfp--tabs--no-animation .wfp--tabs__nav-item--selected:after {
    background: var(--opsColor);
  }
`

export const FeatureIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Open Sans' sans-serif !important;
  transition: all 0.3s;
  opacity: ${({isTableFullScreen})=> isTableFullScreen ? 0 : 1};
  pointer-events: ${({isTableFullScreen})=> isTableFullScreen ? 'none' : 'auto'};

  .wfp--toggle__label {
    margin: 0;
    span {
      font-size: 12px;
    }
  }

  .wfp--toggle:checked + .wfp--toggle__label .wfp--toggle__appearance:after {
    transform: translateX(17px);
  }

  .wfp--toggle__appearance {
    width: 33px;

    :before{
      height: 16px;
      top: -8px;
    }

    :after {
      top: -8px;
      width: 16px;
      height: 16px;
    }
  }
`

export const FeatureIcon = styled.div`
  margin: 0.1em 1em 0 0.4em;
  height: 1em;
  width: 1em;
  cursor: pointer;
`

export const StyledSwitch = styled(CalciteSwitch)`
  margin: 0.2em 1em 0 0.4em;
  border: 0 !important;
  &.container {
    border: 0 !important;
  }
`

export const CustomTabTitle = styled(CalciteTabTitle)`
  &.kzpobj {
    border-bottom-color: var(--opsColor) !important;
  }
  &:hover {
    border-bottom-color: ${({opsColor}) => `${opsColor}99`};
  }

  &:focus {
    border-bottom-color: ${({opsColor}) => opsColor};
  }
`

export const StyledFilter = styled(Filter)``

export const StyledTableTopBar = styled.div`
  cursor:row-resize;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  padding: 2px 0.6rem;
  box-shadow: ${({ isExpanded }) => isExpanded ? `0 -0.04px 0px #a9a9a9, 0px -1px 1px #ccc` : ''};
  background: ${({isExpanded})=>isExpanded ? '#dcdbdb80' : ''};
  min-height: 24px;

  :hover {
    box-shadow: 0 -0.04rem 0px #a9a9a9, 0px -3px 3px #ccc
  }

  span {
    cursor: default;
  }
`

export const StyledButton = styled.button`
  margin-right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;

  svg {
    transition: all 0.5s;
    transform: ${({ isExpanded }) => isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'};
    path{
      stroke-width: 2px;
    }
  }
`

export const StyledTableContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  --calcite-color-brand: var(--opsColor);
  --calcite-color-brand-hover: transparent;

  calcite-tab .content {
    padding-bottom: 0 !important;
  }

  .esri-grid.esri-widget {
    padding-bottom: 0;
  }
`

export const StyledCellToggle = styled.div`
  justify-content: center;
  height: 100%;
  label {
    margin: 0;
  }

  span {
    font-size: ${({small})=>small ? '10px' : '12px'};
  }

  &.wfp--form-item {
    flex-direction: row;
    flex: none;
    align-items: center;
  }


  .wfp--toggle:checked + .wfp--toggle__label .wfp--toggle__appearance:after {
    transform: translateX(20px);
  }

  .wfp--toggle__appearance {
    width: ${({small})=>small ? '33px' : '38px'};

    :before {
      height: ${({small})=>small ? '13px' : '18px'};
      top: -8px;
    }

    :after {
      height: ${({small})=>small ? '13px' : '18px'};
      width: ${({small})=>small ? '13px' : '18px'};
      top:-8px;
    }
  }
`

export const StyledCellCheckboxWrapper = styled.div`
  height: 100%;
  align-items: center;
  display: flex;

  .wfp--form-item.wfp--checkbox-wrapper{
    margin-bottom: 0;
  }
`


export const FeatureTableTooltip = styled.div`
  position: absolute;
  left: 50%;
  top: calc(50% - 50px);
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  width: max-content;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  animation: ${scaleAnimate} 1s ease-in-out;


  :after {
    content: "";
    transform: rotate(45deg) translate(-18px, 3px);
    background: #FFFFFF;
    top: 117%;
    left: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: -1;
  }

  span {
    font-size: 12px;
  }

  button {
    display: flex;
    border: none;
    background: #FFFFFF;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.3s;

    svg {
      fill: #525252;
    }

    :hover {
      background: #e2f1fb;
    }
  }
`

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: -23px;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  //box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  width: 23px;
  height: 48px;
  cursor: pointer;
  border-left: 1px solid #dadce0;
  border-radius: 0 8px 8px 0;
  background: #fff;
  outline: none;
  
  //for tag manager
  svg {
    pointer-events: none;
  }
`

export const SectionItem = styled.div`
  color: ${({selected = true})=> selected ? '#525252' : '#8a8a8a'};
  display: flex;
  height: 40px;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  padding: 0 10px;
  gap:12px;
  transition: all 0.2s;
  background: ${({selected, opsColor = '#8080801a'})=>selected ? `${opsColor}` : ''};
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  //border: 1px solid #80808033;

  .icon_holder {
    background-color: rgb(158 158 158 / 15%);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }

  ${({selected})=> !selected ? `
    :hover {
     background: #8080801a;
    }
  ` : ''};

  svg {
    border-radius: 2px;
    height: 18px;
    width: 18px;

    path {
      fill: ${({fill = true, selected = true, opsColor = '#525252'})=> fill ? selected ? `${opsColor}` : '#8a8a8a' : ''};
    }
  }

  p {
    flex:1;
    margin-top: 0;
  }

  p, .wfp--user__title {
    font-weight: 500;
    font-size: 12px;
    word-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: ${({expand = true})=> expand ? 'block' : 'none'};
    transition: all 0.3s;
  }

  .wfp--user__icon {
    margin-right: 0px;
  }
`

export const FeatureTableTab = styled.div`
  padding: 8px 5px 4px 2px;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  min-width:100px;
  align-items: center;

  span {
    line-height: normal;
    font-size: 14px;
  }

  :first-child{
    margin-left: 10px;
  }

  border-bottom: 3px solid ${({selected})=> selected ? 'var(--opsColor)' : 'transparent'};
`

export const CellRendererWrapper = styled.span`
  button {
    display: flex;
    gap: 8px;
    font-style: normal;
    color:#525252;
    letter-spacing: 0;
    height: 1.8rem;
    padding: 0 0.35rem;
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    transition-duration: 50ms;
    transition-timing-function: ease-in;
    white-space: nowrap;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`
