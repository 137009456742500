import React, { useCallback, useMemo, useState } from "react";
import { FeatureIcon, FeatureIconContainer } from "../FeatureTable-styled";
import { DownloadIcon } from "../../Icons/Feature-TableIcons";
import Dropdown, { DropdownMenu, DropdownMenuItem } from "../../Dropdown";
import { getOpsColor, renderCell, ROLE_EDITOR } from "../../../utils/helper";
import { getLayerTitle } from "../../../esri/custom-popup-content";
import { arcgisToGeoJSON } from "@terraformer/arcgis";
import shpwrite from "@mapbox/shp-write";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import download from "downloadjs";
import Tooltip from "../../UI/Tooltip";
import { BiMapAltIcon, BsTableIcon, FaFileArchiveIcon } from "../../Icons";

function getValue(inputSelector) {
  let text = document.querySelector(inputSelector).value;
  switch (text) {
    case "none":
      return;
    case "tab":
      return "\t";
    default:
      return text;
  }
}

function getParams() {
  return {
    columnSeparator: getValue("#columnSeparator"),
  };
}

function convertTimestampToDateString(timestamp) {
  if (typeof timestamp == "number") {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");

    // Format the components into the "yyyy-mm-dd hh:mm" string
    return `${year}-${month}-${day} ${hour}:${minute}`;
  }
  return timestamp;
}

const isDateAttribute = (key, value) => {
  const regex = /.*date.*/i;
  return typeof value === "number" && key.match(regex);
};

function getDataFromResults(resultFeatures) {
  return resultFeatures.map((row) => {
    const { attributes, geometry } = row;
    for (const [key, value] of Object.entries(attributes)) {
      if (isDateAttribute(key, value)) {
        attributes[key] = convertTimestampToDateString(value);
      } else if (
        attributes[key] === null ||
        attributes[key] === "Unspecified"
      ) {
        attributes[key] = "";
      }
    }

    if (geometry && geometry.latitude && geometry.longitude) {
      attributes.latitude = geometry.latitude;
      attributes.longitude = geometry.longitude;
    }

    return attributes;
  });
}

const convertToCSV = (objArray, fields, t) => {
  const csvData = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  console.log(csvData);

  for (let x = 0; x < csvData.length; x++) {
    let line = "";
    const attr = fields.length > 0 ? fields : Object.keys(csvData[x]);
    attr.sort((a, b) =>
      fields.indexOf(a) >= 0 && fields.indexOf(b) >= 0
        ? fields.indexOf(a) - fields.indexOf(b)
        : 0
    );
    for (let y of attr) {
      if (line !== "") {
        line += ",";
      }

      const text =
        csvData[x][y] !== null
          ? t(`layer.domain.${y}.${csvData[x][y]}`, csvData[x][y])
          : "";

      csvData[x][y] = text.search(/layer.domain/) === -1 ? text : csvData[x][y];
      csvData[x][y] =
        typeof csvData[x][y] == "string"
          ? csvData[x][y]
          : csvData[x][y] == null
          ? ""
          : String(csvData[x][y]);
      line += `"${csvData[x][y].replace(/"/g, '""')}"`;
    }

    str += line + "\r\n";
  }

  return str;
};

const exportCSVFile = (headers, csvData, fileName, fields, t) => {
  if (headers) {
    csvData.unshift(headers);
  }

  let jsonCsvData = JSON.stringify(csvData);
  const csv = convertToCSV(jsonCsvData, fields, t);

  const csvBlob = new Blob(["\uFEFF" + csv], {
    type: "text/csv;charset=utf-8;",
  });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(csvBlob, fileName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(csvBlob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const convertToGeoJSON = (
  resultFeatures,
  fields,
  currLayer,
  t,
  isDateAttribute,
  convertTimestampToDateString
) => {
  const validFeatures = resultFeatures.filter(
    (feature) => feature.geometry != null
  );

  // Create a consistent property structure
  const propertyStructure = {};
  fields.forEach((field) => {
    propertyStructure[field] = ""; // Default empty value
  });

  const geoJSON = {
    type: "FeatureCollection",
    features: validFeatures
      .map((item) => {
        // Ensure we have both geometry and attributes
        if (!item.geometry || !item.attributes) {
          console.warn("Feature missing geometry or attributes:", item);
          return null;
        }

        try {
          const geoJSONFeature = arcgisToGeoJSON(item, currLayer.objectIdField);
          // Handle different geometry types
          if (item.geometry) {
            switch (item.geometry.type) {
              case "point":
                geoJSONFeature.geometry = {
                  type: "Point",
                  coordinates: [
                    item.geometry.longitude,
                    item.geometry.latitude,
                  ],
                };
                break;

              case "polyline":
                if (!item.geometry.paths || !item.geometry.paths.length) {
                  console.warn("Invalid polyline geometry:", item.geometry);
                  return null;
                }
                geoJSONFeature.geometry = {
                  type:
                    item.geometry.paths.length === 1
                      ? "LineString"
                      : "MultiLineString",
                  coordinates:
                    item.geometry.paths.length === 1
                      ? item.geometry.paths[0]
                      : item.geometry.paths,
                };
                break;

              case "polygon":
                if (!item.geometry.rings || !item.geometry.rings.length) {
                  console.warn("Invalid polygon geometry:", item.geometry);
                  return null;
                }
                geoJSONFeature.geometry = {
                  type:
                    item.geometry.rings.length === 1
                      ? "Polygon"
                      : "MultiPolygon",
                  coordinates:
                    item.geometry.rings.length === 1
                      ? [item.geometry.rings[0]]
                      : item.geometry.rings.reduce((polygons, ring) => {
                          const area = ring.reduce((a, [x1, y1], i) => {
                            const [x2, y2] = ring[(i + 1) % ring.length];
                            return a + (x2 - x1) * (y2 + y1);
                          }, 0);

                          if (area > 0) {
                            polygons.push([ring]);
                          } else if (polygons.length > 0) {
                            polygons[polygons.length - 1].push(ring);
                          }
                          return polygons;
                        }, []),
                };
                break;

              case "multipoint":
                if (!item.geometry.points || !item.geometry.points.length) {
                  console.warn("Invalid multipoint geometry:", item.geometry);
                  return null;
                }
                geoJSONFeature.geometry = {
                  type: "MultiPoint",
                  coordinates: item.geometry.points,
                };
                break;

              case "extent":
                const { xmin, ymin, xmax, ymax } = item.geometry;
                if (
                  xmin == null ||
                  ymin == null ||
                  xmax == null ||
                  ymax == null
                ) {
                  console.warn("Invalid extent geometry:", item.geometry);
                  return null;
                }
                geoJSONFeature.geometry = {
                  type: "Polygon",
                  coordinates: [
                    [
                      [xmin, ymin],
                      [xmax, ymin],
                      [xmax, ymax],
                      [xmin, ymax],
                      [xmin, ymin],
                    ],
                  ],
                };
                break;

              default:
                console.warn(
                  `Unsupported geometry type: ${item.geometry.type}`
                );
                return null;
            }
          }

          // Start with the consistent property structure
          const newProperties = { ...propertyStructure };

          // Process attributes
          Object.keys(geoJSONFeature.properties || {}).forEach((k) => {
            if (fields.includes(k)) {
              let field = t(`layer.fieldAlias.${k}`, k);
              if (field.search(/layer.fieldAlias/) === 0) {
                field = k;
              }

              const attributeValue = geoJSONFeature.properties[k];
              let propertiesValue = !isDateAttribute(k, attributeValue)
                ? t(`layer.domain.${k}.${attributeValue}`, attributeValue)
                : convertTimestampToDateString(attributeValue);

              if (propertiesValue.search(/layer.domain/) === 0) {
                propertiesValue = geoJSONFeature.properties[k];
              }

              newProperties[field] =
                propertiesValue != null ? String(propertiesValue) : "";
            }
          });

          geoJSONFeature.properties = newProperties;
          return geoJSONFeature;
        } catch (error) {
          console.warn("Error processing feature:", error);
          return null;
        }
      })
      .filter((feature) => feature !== null), // Remove any invalid features
  };

  if (geoJSON.features.length === 0) {
    throw new Error("No valid features to export");
  }

  return geoJSON;
};

const DownloadButton = ({ config, layer, t, fields, gridApi }) => {
  const [show, setShow] = useState(false);
  const [openSnackbar] = useCustomSnackbar();

  const exportGeoJSON = useCallback(
    (currLayer, resultFeatures, fileName, fields) => {
      const geoJSON = convertToGeoJSON(
        resultFeatures,
        fields,
        currLayer,
        t,
        isDateAttribute,
        convertTimestampToDateString
      );

      // Convert to string and create blob
      const geoJSONString = JSON.stringify(geoJSON, null, 2);
      const blob = new Blob([geoJSONString], {
        type: "application/json",
      });

      // Download file
      download(blob, `${fileName}.geojson`, "application/json");
    },
    [t]
  );

  const exportShapeFile = useCallback(
    (currLayer, resultFeatures, fileName, fields) => {
      // Filter out features with null geometries
      const geoJSON = convertToGeoJSON(
        resultFeatures,
        fields,
        currLayer,
        t,
        isDateAttribute,
        convertTimestampToDateString
      );

      const options = {
        outputType: "blob",
        compression: "STORE",
        types: {
          point: "POINT",
          polygon: "POLYGON",
          line: "POLYLINE",
        },
      };

      shpwrite
        .zip(geoJSON, options)
        .then((content) => {
          download(content, `${fileName}.zip`, "application/zip");
        })
        .catch((error) => {
          console.error("Error generating shapefile:", error);
          throw new Error("Failed to generate shapefile");
        });
    },
    [t]
  );

  const handleDownload = useCallback(
    async (downloadType) => {
      let message;
      const layerName = getLayerTitle(layer, t);
      //fetching current features to get latest data
      const res = await layer.queryFeatures();

      //getting current features visible in table after table filters were applied
      const featuresId = [];
      gridApi.forEachNodeAfterFilter((node) => {
        featuresId.push(node.data[layer.objectIdField]);
      });

      const features = res.features.filter((feat) =>
        featuresId.includes(feat.attributes[layer.objectIdField])
      );
      features.sort((a, b) => {
        const indexOfA = featuresId.indexOf(a.attributes[layer.objectIdField]);
        const indexOfB = featuresId.indexOf(b.attributes[layer.objectIdField]);
        return indexOfA - indexOfB;
      });

      if (features.length) {
        const fileTitle = `${
          config.alias ? `${config.alias}_` : ""
        }${layerName}`;
        let date = new Date().toISOString().split("T")[0].replace(/-/g, "");
        const exportedFilename =
          fileTitle !== ""
            ? `${fileTitle}_${String(date)}`
            : `logie_data_${String(date)}`;
        if (downloadType === "shapefile") {
          //export shapefile
          exportShapeFile(layer, features, exportedFilename, fields);
        } else if (downloadType === "geojson") {
          exportGeoJSON(layer, features, exportedFilename, fields);
        } else {
          //export csv
          const columnsOrder = gridApi
            .getAllGridColumns()
            .filter((col) => col.colId !== "featureSelect")
            .map((col) => col.colId);

          // gridApi.exportDataAsCsv();
          // export to csv
          console.log(features);

          const data = getDataFromResults(features);
          const headers = {};
          const entry = data[0];

          Object.keys(data).forEach((attr) => {
            Object.keys(data[attr]).forEach((k) => {
              const field = layer.fields.find((f) => f.name === k);

              if (field && field.domain) {
                data[attr][k] = renderCell(field, data[attr][k], t, config);
              }
            });
          });

          for (let key in entry) {
            if (entry.hasOwnProperty(key)) {
              headers[key] = t("layer.fieldAlias." + key, key);
            }
          }

          fields.sort((a, b) =>
            columnsOrder.indexOf(a) >= 0 && columnsOrder.indexOf(b) >= 0
              ? columnsOrder.indexOf(a) - columnsOrder.indexOf(b)
              : 0
          );

          console.log(data);

          exportCSVFile(headers, data, exportedFilename, fields, t);
        }

        message = t("screen.message.downloadSuccess", {
          title: layerName,
        });
      } else {
        message = t("screen.message.noData", { title: layerName });
      }

      openSnackbar(message, 15000);

      setShow(false);
    },
    [fields, t, config, gridApi]
  );

  const allowedExports = useMemo(() => {
    const { layerConfig } = layer || {};
    if (!layerConfig) return [];

    const isEditor = config.role === ROLE_EDITOR;
    const { featureTable } = layerConfig;

    return [
      {
        type: "csv",
        disabled: false,
        allowed: isEditor
          ? featureTable.allowedExtractionCSVForEditors
          : featureTable.allowedExtractionCSVForPublic,
        Icon: BsTableIcon,
      },
      {
        type: "shapefile",
        disabled: layer.geometryType !== "point",
        allowed: isEditor
          ? featureTable.allowedExtractionCSVForEditors
          : featureTable.allowedExtractionSHPForPublic &&
            featureTable.exportCoordinates,
        Icon: FaFileArchiveIcon,
      },
      {
        type: "geojson",
        disabled: false,
        allowed: isEditor
          ? featureTable.allowedExtractionGEOForEditors
          : featureTable.allowedExtractionGEOForPublic &&
            featureTable.exportCoordinates,
        Icon: BiMapAltIcon,
      },
    ].filter(({ allowed }) => allowed);
  }, [layer, config]);

  const opsColor = useMemo(() => getOpsColor(config), [config]);

  if (allowedExports.length === 0) return null;

  return (
    <Dropdown setShow={setShow} show={show}>
      <FeatureIconContainer>
        {t("screen.download.title")}
        <FeatureIcon>
          <DownloadIcon />
        </FeatureIcon>
      </FeatureIconContainer>
      <DropdownMenu style={{ minWidth: 80 }}>
        {allowedExports.map(({ type, disabled, Icon }) => (
          <Tooltip
            showOnHover={!disabled}
            content={t(`screen.download.${type}.description`)}
            placement="left"
          >
            <DropdownMenuItem
              opsColor={opsColor}
              onClick={() => handleDownload(type)}
              disabled={!show || disabled}
            >
              <Icon width="14" height="14" />
              <span style={{ fontSize: 12 }}>
                {t(`screen.download.${type}.label`)}
              </span>
            </DropdownMenuItem>
          </Tooltip>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DownloadButton;
