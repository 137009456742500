import URLSearchParams from "@ungap/url-search-params";
import { allCountryCodes } from "../data/countryISO3Codes";
import configFile from "../data/config.json";
import symbologies from "../data/symbologies.json";
import { timezones } from "../data/timezones";
import { loadModules } from "esri-loader";
import { view, initialExtent, currAsOfDateFieldName } from "./API";
import store from "../redux/store";
import {
    setActiveModule_actionType,
    setFilters_actionType,
} from "../redux/constants";
import React from "react";
import {
    LogisticsClusterIcon,
    LogisticsClusterSmallIcon,
    WFPIcon,
} from "../components/Icons/Header-Icons";
import bngrc from "../components/Icons/img/bngrc.jpg";
import { getLayerSymbology } from "./symbologies";
import InputField from "../components/Dashboard/Editor/EditorFields/AttributeFields/Fields/InputField";
import { borderColor } from "./Theme";
import Input from "../components/Report/new/Input/Input";
import Comment from "../components/Report/new/Comment/Comment";

const _ = require("lodash");

// Operation related configuration options:
// webmap, title, subtitle, basemap, modules, activeModules, defaultModule,	unFilteredLayers, filteredLayers,
// center, country, zoom, iso3, printServiceUrl, mapTitle, mapAuthor, mapCpr, ops, bsColor, opsColor, lang, sens,
// headerZoomButtonOptions, surveyUrl, surveyHideDetails, surveyISOField, interactive, widgets

export const getConfig = (t, openSnackbar) => {
    try {
        const config = fetchConfigOptions(t, openSnackbar);
        if (config && config.title)
            document.title = `${config.role === ROLE_EDITOR ? "Editor: " : ""}${
                config.title
            }`;

        return config;
    } catch (err) {
        console.warn(err);
    }
};

export const ROLE_EDITOR = "editor";

export const EMBED_VARIANTS = {
    WEBSITE: "lc-website",
    APP: "lc-app",
};

const fetchConfigOptions = (t, openSnackbar) => {
    const searchParamsInstance = new URLSearchParams(
        window.location.search.toLowerCase()
    );

    const ops = configFile.ops;

    //Read the configuration id from the url parameter 'op'
    let config = null;

    let opParam = searchParamsInstance.get("op");
    if (opParam) {
        ops.forEach((op) => {
            const isMultiAlias =
                Array.isArray(op.alias) && op.alias.includes(opParam);
            if (
                op.id.toLowerCase() === opParam ||
                (typeof op.alias === "string" &&
                    op.alias.toLowerCase() === opParam) ||
                isMultiAlias
            ) {
                if (isMultiAlias) {
                    config = {
                        ...op,
                        alias: opParam,
                    };
                } else {
                    config = op;
                }
            }
        });
        if (!config) {
            openSnackbar(
                t("screen.message.pageNotFound", { op: opParam }),
                45000
            );
        } else if (config.opStatus === "inactive") {
            config = null;
            openSnackbar(
                t("screen.message.inactiveOp", { op: opParam }),
                15000
            );
        } else if (config.opStatus === "phasingout") {
            openSnackbar(t("screen.message.phasingOutOp"), 15000);
        } else if (config.opStatus === "planning") {
            config = null;
        } else {
            config.opStatus = "active";
        }
    }

    if (!config) config = ops[0];

    config.role = searchParamsInstance.get("role");
    config.embed = searchParamsInstance.get("embed");

    if (config.role && config.role !== ROLE_EDITOR) {
        openSnackbar(t("screen.message.urlError", { 1: config.role }), 15000);
        config.role = null;
    }

    if (
        config.embed &&
        config.embed !== EMBED_VARIANTS.APP &&
        config.embed !== EMBED_VARIANTS.WEBSITE
    ) {
        openSnackbar(t("screen.message.urlError", { 1: config.embed }), 15000);
        config.embed = null;
    }

    config.allowOverride =
        configFile.allowURLParamOverride !== undefined
            ? configFile.allowURLParamOverride
            : false;
    config.opDefaults = configFile.opDefaults;
    config.layerConfig = extend(
        configFile.layerDefinition,
        config.layerDefinition
    );
    config.reportDefinition = extend(
        configFile.reportDefinition,
        config.reportDefinition
    );
    config.layerDefaults = configFile.layerDefaults;
    config.widgetGroups = configFile.widgetGroups;
    config.layerSymbologies = symbologies;

    //extending opDefaults for print widget;
    config.printMap = extend(
        configFile.opDefaults.printMap || {},
        config.printMap || {}
    );

    Object.keys(config.layerConfig).forEach((layerAlias) => {
        if (config.layerConfig[layerAlias].extends) {
            const layerToExtend = config.layerConfig[layerAlias].extends;
            const layerConfigToExtend = config.layerConfig[layerToExtend];
            if (layerConfigToExtend) {
                const layerConfigToExtendsWithDefaults = extend(
                    configFile.layerDefaults,
                    layerConfigToExtend
                );
                config.layerConfig[layerAlias] = extend(
                    layerConfigToExtendsWithDefaults,
                    config.layerConfig[layerAlias]
                );
            } else
                config.layerConfig[layerAlias] = extend(
                    configFile.layerDefaults,
                    config.layerConfig[layerAlias]
                );
        } else {
            config.layerConfig[layerAlias] = extend(
                configFile.layerDefaults,
                config.layerConfig[layerAlias]
            );
        }
    });

    if (config.role === ROLE_EDITOR) {
        Object.keys(config.layerConfig).forEach((layerAlias) => {
            if (config.layerConfig[layerAlias].editable) {
                config.layerConfig[layerAlias].viewer =
                    config.layerConfig[layerAlias].id;
                config.layerConfig[layerAlias].id =
                    config.layerConfig[layerAlias].editable;
            }
        });
    }

    if (config.id && Array.isArray(config.activeModules)) {
        const { operationPreferences } = getOperationPreferences(config.id);
        const languageConfig = localStorage.getItem("languageConfig");

        if (
            !config.activeModules.includes(config.defaultModule) &&
            config.activeModules.length > 0
        ) {
            config.defaultModule = config.activeModules[0];
        }

        if (
            operationPreferences.activeModule &&
            config.activeModules.includes(operationPreferences.activeModule)
        ) {
            config.defaultModule = operationPreferences.activeModule;
        }

        if (languageConfig) {
            const { languageModule } = JSON.parse(languageConfig);
            if (languageModule) {
                config.defaultModule = languageModule;
            }
        }
    }

    return compileConfigOptions(searchParamsInstance, config, t, openSnackbar);
};

export const getDisclaimerText = (config, activeModule, t) => {
    if (!config || !activeModule || typeof t !== "function") {
        return "";
    }

    const operationDisclaimer = t(
        "ops." + (config.id || "") + ".disclaimer",
        t("screen.message.disclaimer", config.disclaimer || "")
    );
    const configModule = config.modules ? config.modules[activeModule] : null;
    const configModuleDisclaimer =
        configModule?.disclaimer ??
        config.opDefaults?.modules?.[activeModule]?.disclaimer;
    const moduleDisclaimer = t(
        "screen.widget.ModuleSwitcher." + activeModule + ".disclaimer",
        configModuleDisclaimer
    );

    return moduleDisclaimer || operationDisclaimer || "";
};

/**
 * Merge two objects. Similar to jQuery's extend function
 */
export const extend = (a, b) => {
    let ret = {};

    if (typeof a === "object")
        Object.keys(a).forEach((key) => {
            if (a.hasOwnProperty(key)) ret[key] = a[key];
        });

    if (typeof b === "object")
        Object.keys(b).forEach((key) => {
            if (b.hasOwnProperty(key)) {
                const aVal = a[key];
                const bVal = b[key];
                //console.log(aVal, typeof a[key])
                if (isObject(aVal) && isObject(bVal))
                    ret[key] = extend(aVal, bVal);
                else ret[key] = bVal;
            }
        });

    return ret;
};

/**
 * Is Object? return false for arrays
 */
const isObject = (val) => {
    return typeof val === "object" && !Array.isArray(val);
};
const compileConfigOptions = (
    searchParamsInstance,
    config,
    t,
    openSnackbar
) => {
    if (!config) return null;

    let settings = { ...config.opDefaults, ...config };
    settings.modules = { ...config.opDefaults.modules, ...config.modules };

    if (config.allowOverride) {
        //let doForward = false
        searchParamsInstance.forEach((currVal, currName) => {
            const key = currName.toLowerCase(),
                val = currVal.toLowerCase();
            //if (key !== "op" && key !== "role")
            //	doForward = true

            const urlParam = getUrlParam(key, val, t, openSnackbar);
            if (urlParam) settings[key] = urlParam;
        });

        /** Module url parameter will overwrite the defaultModule config option */
        if (settings["module"]) {
            const activeModule = settings["module"].toUpperCase();
            if (
                settings.activeModules &&
                settings.activeModules.includes(activeModule)
            )
                settings.defaultModule = activeModule;
        }
        /*
		if (doForward){
			const suffix = (settings.op ? "op=" + settings.op : "") +
				(settings.role ? "&role=" + settings.role : "")
			//window.history.replaceState({}, document.title, window.location.origin + "/?" + suffix)
		}
*/
    }

    if (
        !settings.defaultModule &&
        settings.activeModules &&
        settings.activeModules.length > 0
    ) {
        settings.defaultModule = settings.activeModules[0];
    }

    settings.activeModules = settings.activeModules?.filter((moduleName) => {
        const module = settings.modules[moduleName];
        return (
            (!module.onlyForEditors || config.role === ROLE_EDITOR) &&
            Array.isArray(module.layers) &&
            module.layers.length > 0
        );
    });

    /**
     * Filter customization: each module can define its set of filters
     */
    const filters = {};
    settings.activeModules &&
        settings.activeModules.forEach((moduleName) => {
            const moduleFilters = settings.modules[moduleName]?.filterFields;
            const layerFilters = [];
            if (!moduleFilters && settings.modules[moduleName]?.layers) {
                settings.modules[moduleName]?.layers.forEach((layer) => {
                    if (
                        Array.isArray(settings.layerConfig[layer]?.filterFields)
                    ) {
                        layerFilters.push(
                            ...settings.layerConfig[layer].filterFields
                        );
                    }
                });

                if (settings.modules[moduleName]?.optionalLayers) {
                    settings.modules[moduleName]?.optionalLayers.forEach(
                        (layer) => {
                            if (
                                Array.isArray(
                                    settings.layerConfig[layer]?.filterFields
                                )
                            ) {
                                layerFilters.push(
                                    ...settings.layerConfig[layer].filterFields
                                );
                            }
                        }
                    );
                }
            }

            const filterDefinition = moduleFilters
                ? moduleFilters
                : settings.filterFields;
            if (filterDefinition)
                filters[moduleName] = getFilterObject(
                    [...filterDefinition, ...layerFilters],
                    config
                );
        });

    if (settings.filterFields) {
        filters[""] = getFilterObject(settings.filterFields, config);
    }
    settings.defaultFilters = filters;
    store.dispatch({ type: setFilters_actionType, payload: filters });

    const defaultModule = settings.defaultModule
        ? settings.defaultModule
        : settings.modules[0]
        ? settings.modules[0]
        : "";
    store.dispatch({
        type: setActiveModule_actionType,
        payload: defaultModule,
    });

    const wg = settings.widgetGroups;
    settings.widgets = replaceWidgetGroups(settings.widgets, wg);
    settings.widgetsForEditors = replaceWidgetGroups(
        settings.widgetsForEditors,
        wg
    );
    settings.widgetsForSmallScreen = replaceWidgetGroups(
        settings.widgetsForSmallScreen,
        wg
    );
    settings.widgetsForEmbed = replaceWidgetGroups(
        settings.widgetsForEmbed,
        wg
    );
    settings.widgetsForAppEmbed = replaceWidgetGroups(
        settings.widgetsForAppEmbed,
        wg
    );
    return settings;
};

const getFilterObject = (filterFields, config) => {
    const filterObject = {};
    filterFields.forEach((filterField) => {
        if (!filterField.onlyForEditors || config.role === ROLE_EDITOR)
            filterObject[filterField.name] = filterField.defaultValue
                ? filterField.defaultValue
                : [];
    });
    return filterObject;
};
/**
 * Widget arrays might contain widget group references. these references will be replaced by
 * the widgets from the group definition
 */
const replaceWidgetGroups = (widgetArray, widgetGroups) => {
    let newWidgets = [];
    widgetArray &&
        widgetArray.forEach((widget) => {
            if (widgetGroups && widgetGroups[widget])
                newWidgets.push(...widgetGroups[widget]);
            else newWidgets.push(widget);
        });
    return newWidgets;
};

export const translateConfig = (config, t) => {
    if (!config) return null;

    //Translations
    const translationOpPrefix = "ops." + config.id + ".";
    config.title = t(translationOpPrefix + "title", config.title);
    config.subtitle = t(translationOpPrefix + "subtitle", config.subtitle);
    config.mapTitle = t(translationOpPrefix + "mapTitle", config.mapTitle);

    //Translate zoom buttons
    if (config.headerZoomButtonOptions)
        config.headerZoomButtonOptions.forEach((zoomButton, index) => {
            config.headerZoomButtonOptions[index].name = t(
                translationOpPrefix +
                    "headerZoomButtonOptions." +
                    index +
                    ".name",
                config.headerZoomButtonOptions[index].name
            );
        });

    return config;
};

export const getUrlParam = (name, val, t, openSnackbar) => {
    switch (name) {
        case "country":
            if (allCountryCodes.includes(val.toUpperCase())) return val;

            openSnackbar(t("screen.message.urlError", { 1: name }), 15000);
            return;

        case "center":
            let centerParam = val.split(",").map((coord) => parseFloat(coord));
            if (centerParam.includes(NaN)) {
                openSnackbar(t("screen.message.urlError", { 1: name }), 15000);
                return;
            }

            return centerParam;

        case "zoom":
            const zoom = parseInt(val);
            if (isNaN(zoom)) {
                openSnackbar(t("screen.message.urlError", { 1: name }), 15000);
                return;
            }

            return zoom;

        case "bsColor":
        case "opsColor":
            return "#" + val;

        // Links
        case "oid":
        case "lid":
        case "op":
        case "role":
        // These params appear after the authentication screen redirect:
        case "state":
        case "code":
        case "persist":
        case "module":
        case "datacollection":
            return val;
        case "lang":
            return val;
        case "embed":
            return val;
        case "widget-open":
            return val;

        default:
            console.log(":" + name + ":");
            openSnackbar(t("screen.message.urlError", { 1: name }), 15000);
            return null;
    }
};

export const getConfigErrors = (config) => {
    let configKeys = Object.keys(config);
    const errors = configKeys.reduce((acc, paramKey) => {
        if (config[paramKey] === "error") {
            acc.push(`Invalid ${paramKey} entered in URL.`);
        }
        return acc;
    }, []);
    return _.uniq(errors);
};

export const formatDate = (config, value) => {
    return getDateString(config, value).slice(0, 10);
};

export const formatDateTime = (config, value) => {
    let dateString = getDateString(config, value);
    return dateString.slice(0, 10) + " " + dateString.slice(11, 16);
};

const getDateString = (config, value) => {
    const iso3 = getConfigISO(config);

    return iso3 && timezones[iso3]
        ? new Date(value).toISOString("en-GB", { timeZone: timezones[iso3][0] })
        : new Date(value).toISOString("en-GB");
};

export const renderCell = (field, value, t, config) => {
    if (!field) return;

    const cellValue =
        field.type === "date"
            ? formatDate(config, value)
            : field.domain
            ? renderDomain(field, value, t)
            : field.type === "string"
            ? linkify(value)
            : field.type === "small-integer"
            ? t("layer.domain." + field.name + "." + value, `${value}`)
            : value;

    return typeof cellValue === "string" ? cellValue.trim() : cellValue;
};

export const renderMobileCell = (f, value, onChangeHandler) => {
    const border = standardizeColor(borderColor);
    switch (f.type) {
        case "double":
            return (
                <Input
                    key={"i" + f.name}
                    noMargin
                    type="number"
                    step="any"
                    defaultValue={value}
                    placeholder="0.00"
                    borderColor={border}
                    field={f}
                    onChange={onChangeHandler}
                />
            );
        case "integer":
            return (
                <Input
                    key={"i" + f.name}
                    noMargin
                    step="1"
                    type="number"
                    defaultValue={value}
                    placeholder="0"
                    borderColor={border}
                    field={f}
                    onChange={onChangeHandler}
                />
            );

        case "date":
            return (
                <Input
                    key={"i" + f.name}
                    noMargin
                    type="date"
                    defaultValue={value}
                    field={f}
                    borderColor={border}
                    onChange={onChangeHandler}
                />
            );
        default:
            if (f.length > 100) {
                return (
                    <Comment
                        key={"i" + f.name}
                        noMargin
                        rows="4"
                        maxLength={f.length}
                        height={100}
                        defaultValue={value}
                        borderColor={border}
                        field={f}
                        onChange={onChangeHandler}
                    />
                );
            } else {
                return (
                    <Input
                        key={"i" + f.name}
                        noMargin
                        type="text"
                        maxLength={f.length}
                        defaultValue={value}
                        borderColor={border}
                        field={f}
                        onChange={onChangeHandler}
                    />
                );
            }
    }
};

export const renderCellInEditor = (f, value, onChangeHandler) => {
    if (f.domain) {
        const domain = f.domain;

        return (
            <select
                onChange={onChangeHandler}
                data-field-name={f.name}
                className="esri-input esri-feature-form__input"
                defaultValue={value}
            >
                {f.nullable && <option key={null} value={""}></option>}
                {domain.codedValues.map((cv) => (
                    <option key={cv.code} value={cv.code}>
                        {cv.name}
                    </option>
                ))}
            </select>
        );
    }

    switch (f.type) {
        case "double":
            return (
                <input
                    key={"i" + f.name}
                    type="number"
                    step="any"
                    className="esri-input"
                    data-field-name={f.name}
                    onChange={onChangeHandler}
                    defaultValue={value}
                    placeholder="0.00"
                />
            );
        case "integer":
            return (
                <input
                    key={"i" + f.name}
                    type="number"
                    step="1"
                    className="esri-input"
                    data-field-name={f.name}
                    onChange={onChangeHandler}
                    defaultValue={value}
                    placeholder="0"
                />
            );
        case "date":
            return (
                <input
                    key={"i" + f.name}
                    type="date"
                    onChange={onChangeHandler}
                    className="esri-input"
                    data-field-name={f.name}
                    defaultValue={value}
                />
            );
        default:
            if (f.length > 60)
                return (
                    <textarea
                        key={"i" + f.name}
                        rows="4"
                        maxLength={f.length}
                        data-field-name={f.name}
                        onChange={onChangeHandler}
                        defaultValue={value}
                    />
                );
            else
                return (
                    <input
                        key={"i" + f.name}
                        type="text"
                        className="esri-input"
                        maxLength={f.length}
                        data-field-name={f.name}
                        onChange={onChangeHandler}
                        defaultValue={value}
                    />
                );
    }
};

export const renderDomain = (field, value, t) => {
    if (Array.isArray(field))
        return field
            .map((f, idx) => renderSingleDomain(f, value[idx], t))
            .join(" ");

    return renderSingleDomain(field, value, t);
};

export const renderSingleDomain = (field, value, t) => {
    if (!field || !field.domain || field.domain.length < 1)
        return t(
            "layer.domain." + field.name + "." + value,
            t("screen.domain.unspecified", "Unspecified")
        );

    const codedValues = field.domain.codedValues;
    if (field.nullable && value === null) {
        return t("screen.domain.unspecified", "Unspecified");
    }
    const codedVal = codedValues.filter((codedVal) => {
        return typeof codedVal.code === "number"
            ? codedVal.code === Number(value)
            : codedVal.code === value;
    })[0];

    return t(
        "layer.domain." + field.name + "." + value,
        codedVal ? codedVal.name : t("screen.domain.unspecified", "Unspecified")
    );
};

export const renderFieldTitle = (field, t) => {
    return t("layer.fieldAlias." + field.name, field.alias);
};

const linkify = (value) => {
    if (!value) return;

    const urlPattern =
        /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim;
    return value.replace(
        urlPattern,
        '<a target="_blank" href="$&" rel="noreferrer" style="word-break: break-all">$&</a>'
    );
};

export const setAsyncError = (err, setError, EsriClass) => {
    let errorMessage =
        "An internal error occurred. Please check URL and try again.";
    console.warn(`Error${EsriClass ? `: Check ${EsriClass}` : ""}`);
    console.warn(err);
    setError([errorMessage]);
};

// The target point is a new camera obtained by shifting the current camera 30 degrees to the east
export const goToRegion = (center) => {
    loadModules(["esri/geometry/Point"]).then(([Point]) => {
        let pt = new Point({
            latitude: center.y,
            longitude: center.x,
        });

        view.goTo({ target: pt, zoom: center.zoom }, { duration: 2000 }).catch(
            function (error) {
                if (error.name !== "AbortError") console.error(error);
            }
        );
    });
};

export const clickEventOnFeature = async (feature, zoomOptions = {}) => {
    try {
        const {
            zoom = true,
            duration = 2000,
            showPopup = true,
            scale = initialExtent.width / 3,
        } = zoomOptions;

        view.closePopup();

        const options = {
            target: feature.geometry,
        };

        if (zoom) {
            options.scale = scale;
        }

        let center = feature.geometry;

        if (feature.geometry?.extent && feature.geometry.extent.center) {
            center = feature.geometry.extent.center;
        }

        if (showPopup) {
            view.openPopup({
                location: center,
                features: [feature],
            });
        }

        if (feature.geometry?.type === "polyline" && feature.geometry.extent) {
            await view.goTo(feature.geometry.extent.expand(1.5), {
                duration: duration,
            });
        } else {
            await view.goTo(options, { duration: duration });
        }
    } catch (error) {
        if (error.name !== "AbortError") {
            console.error(error);
        }
    }
};

export const isTouchScreen = () => {
    return (
        "ontouchstart" in window || // html5 browsers
        navigator.maxTouchPoints > 0 || // future IE
        navigator.msMaxTouchPoints > 0
    ); // current IE10
};

export const appendParamToURL = (url, param, val) => {
    return url + (url.includes("?") ? "&" : "?") + param + "=" + val;
};

export const getExcludeFields = (layer) => {
    let excludeFields = [
        layer.objectIdField,
        layer.globalIdField,
        //layer.geometryFieldsInfo?.shapeLengthField,
        layer.geometryFieldsInfo?.shapeAreaField,
        layer.editFieldsInfo?.creationDateField,
        layer.editFieldsInfo?.creatorField,
    ];

    if (
        layer.layerConfig?.customPopupOps?.excludeFields &&
        Array.isArray(layer.layerConfig?.customPopupOps?.excludeFields)
    )
        excludeFields = excludeFields.concat(
            layer.layerConfig.customPopupOps.excludeFields
        );

    return excludeFields;
};

/**
 * Match fields by pattern(s). excluded fields are not included in the result
 */
export const getFieldsByPattern = (
    layer,
    fieldPatterns,
    excludeFields = false
) => {
    if (!fieldPatterns) return [];

    const excludedFields = excludeFields ? getExcludeFields(layer) : [];
    let ret = [];
    fieldPatterns.forEach((pattern) => {
        ret = ret.concat(
            layer.fields
                .filter((field) => !excludedFields.includes(field.name))
                .filter((field) => {
                    const fieldMatches = field.name.match("^" + pattern + "$");
                    return fieldMatches && fieldMatches.length > 0;
                })
        );
    });
    return ret;
};

export const createSharableURL = (feature, config, activeModule) => {
    let url = window.location.href.split("?")[0] + "?";

    if (config.alias) url += "op=" + config.alias;
    else url += "op=" + config.id;

    if (
        feature.geometry &&
        feature.geometry.latitude &&
        feature.geometry.longitude
    )
        url +=
            "&center=" +
            feature.geometry.longitude +
            "," +
            feature.geometry.latitude;

    if (view.zoom) url += "&zoom=" + view.zoom;

    if (activeModule) url += "&module=" + activeModule;

    const layer = feature.sourceLayer || feature.layer;

    const globalIdField = layer.objectIdField;
    url += "&oid=" + feature.attributes[globalIdField];
    url += "&lid=" + layer.layerConfig.id;

    return url;
};

export const togglePopupEditor = ({ view, expand }) => {
    let isCluster;

    view.map.layers?.map((layer) => {
        if (
            layer.type === "feature" &&
            layer.layerConfig &&
            layer.layerConfig.cluster &&
            layer.layerConfig.cluster.isClustered &&
            layer.visible
        ) {
            isCluster = true;
        }
    });

    if (expand.viewModel.expanded) {
        view.popupEnabled = false;
        view.closePopup();
    } else {
        view.popupEnabled = true;
    }
};

export const getHeaderIcon = (config) => {
    switch (config.header.icon) {
        case "wfp":
            return <WFPIcon />;
        case "bngrc":
            return <img src={bngrc} alt={"Bngrc"} height={50} width={50} />;
        default:
            return (
                <a
                    href="https://logcluster.org/en"
                    target="_blank"
                    style={{ display: "flex" }}
                >
                    <LogisticsClusterIcon key="lci" />
                    <LogisticsClusterSmallIcon key="lcsi" />
                </a>
            );
    }
};

export const locales = {
    en: "en-GB",
    fr: "fr-FR",
    es: "es-ES",
    tr: "tr-TR",
    ru: "ru-RU",
    ar: "ar-SA",
    zh: "zh-CN",
    pt: "pt-PT",
};

export const timeAgo = (timestamp, languageCode = "en", showTime = true) => {
    const locale = locales[languageCode] ?? languageCode;
    let value;
    const now = new Date();
    const diff = (now.getTime() - timestamp) / 1000;
    const minutes = Math.floor(diff / 60);
    const hours = Math.floor(minutes / 60);
    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });

    const options = {
        timeZone: "UTC",
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    if (showTime) {
        options.hour = "numeric";
        options.minute = "numeric";
    }

    if (now.toDateString() !== timestamp.toDateString()) {
        const formattedDate = formatIntlDateTime({
            languageCode,
            options,
            date: timestamp,
        });
        return `${formattedDate} (UTC)`;
    }

    if (hours > 0) {
        value = rtf.format(0 - hours, "hour");
    } else if (minutes > 0) {
        value = rtf.format(0 - minutes, "minute");
    } else {
        value = "now";
    }
    return value;
};

export const formatIntlDateTime = ({
    date,
    languageCode = "en",
    options = {},
    separator = "/",
}) => {
    if (!date) return "";
    const locale = locales[languageCode] ?? languageCode;
    let formattedDate = Intl.DateTimeFormat(locale, {
        year: options?.year ?? "numeric",
        month: options?.month ?? "numeric",
        day: options?.day ?? "numeric",
        ...options,
    }).format(date);

    if (separator !== "/") {
        formattedDate = formattedDate.split("/").join(separator);
    }
    return formattedDate;
};

let applied = {};

export const applyZoomLevelFilter = (l, config) => {
    const batchEditor = view.ui.find("BatchEditor");
    const editor = view.ui.find("Edtior");

    if (
        (batchEditor && batchEditor.viewModel.expanded) ||
        (editor && editor.viewModel.expanded)
    ) {
        return;
    }

    const sizeMap = getLayerSymbology(l, config)?.sizeMap;

    if (!!sizeMap && sizeMap.zoom && l.visible) {
        view.whenLayerView(l).then((lv) => {
            if (sizeMap?.zoom) {
                const zoom = view.zoom;
                const showSymbolsCodeValues = [];
                Object.keys(sizeMap.zoom).forEach((k) => {
                    if (zoom >= k) {
                        const values = sizeMap.zoom[k];
                        showSymbolsCodeValues.push(...values);
                    }
                });

                if (
                    showSymbolsCodeValues.length > 0 &&
                    applied[l.id]?.length !== showSymbolsCodeValues.length
                ) {
                    applied[l.id] = showSymbolsCodeValues;
                    const where = showSymbolsCodeValues.map(
                        (v) => sizeMap.field + (v === null ? " is " : " = ") + v
                    );
                    lv.filter = {
                        where: where.join(" or "),
                    };
                }
            }
        });
    }
};

export const removeZoomLevelFilter = (lv) => {
    applied = {};
    lv.filter = undefined;
};

export const getFeatureEditDate = (feature) => {
    const editDateFieldName = feature.layer.editFieldsInfo?.editDateField;
    if (!editDateFieldName) return;

    return feature.attributes[editDateFieldName];
};

export const getFeatureLatestUpdateDate = (feature) => {
    const layer = feature.layer;
    if (layer.fields.some((f) => f.name === currAsOfDateFieldName)) {
        return feature.attributes[currAsOfDateFieldName];
    }

    return feature.attributes[layer?.editFieldsInfo?.editDateField];
};

export const getConfigISO = (config) => {
    try {
        const activeModule = store.getState().activeModule;
        const moduleConfig = activeModule ? config.modules[activeModule] : {};
        if (moduleConfig?.iso3) {
            return moduleConfig.iso3;
        }
    } catch (err) {
        return config.iso3;
    }

    return config.iso3;
};

export const observerCallback = (entries, handler) => {
    window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
            return;
        }
        handler(entries);
    });
};

export const createObserver = (handler) => {
    return new ResizeObserver((entries) => observerCallback(entries, handler));
};

export const convertRGBAToHexA = (rgba, forceRemoveAlpha = false) => {
    return (
        "#" +
        rgba
            .replace(/^rgba?\(|\s+|\)$/g, "") // Get's rgba / rgb string values
            .split(",") // splits them at ","
            .filter((string, index) => !forceRemoveAlpha || index !== 3)
            .map((string) => parseFloat(string)) // Converts them to numbers
            .map((number, index) =>
                index === 3 ? Math.round(number * 255) : number
            ) // Converts alpha to 255 number
            .map((number) => number.toString(16)) // Converts numbers to hex
            .map((string) => (string.length === 1 ? "0" + string : string)) // Adds 0 when length of one number is 1
            .join("")
    ); // Puts the array to togehter to a string
};

//converts rgba and color names (green, red, etc.) to HEX color code
export const standardizeColor = (str) => {
    try {
        const ctx = document.createElement("canvas").getContext("2d");
        if (str && str.includes("rgb")) {
            str = convertRGBAToHexA(str);
        }

        ctx.fillStyle = str;
        let result = ctx.fillStyle;

        if (result && result.includes("rgb")) {
            result = convertRGBAToHexA(result);
        }
        return result;
    } catch (err) {
        return "#000";
    }
};

export const hasUrlPattern = (value) => {
    // const newPattern = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    const urlPattern =
        /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim;
    return urlPattern.test(value);
};

export const compateFeaturesByDate = (a, b, sort = "descending") => {
    const editDateA = getFeatureEditDate(a);
    if (!editDateA) return 1;

    const editDateB = getFeatureEditDate(b);
    if (!editDateB) return 1;

    if (sort === "descending") {
        return editDateA > editDateB ? -1 : 1;
    } else {
        return editDateA < editDateB ? -1 : 1;
    }
};

export const isYesNoDomain = (field) => {
    const check = {
        yes: false,
        no: false,
        unknown: false,
    };

    if (field?.domain) {
        field.domain.codedValues.forEach((cv) => {
            const lowerName = cv.name.toLowerCase();
            if (lowerName in check) {
                check[lowerName] = true;
            }
        });
    }

    return Object.values(check).filter((isTrue) => !isTrue).length === 0;
};

export const getOperationPreferences = (id) => {
    const storedPreferences = localStorage.getItem("savedPreferences");
    let preferences = {};
    if (storedPreferences) {
        preferences = JSON.parse(storedPreferences);
    }

    const operationPreferences = preferences[id] || {};
    return {
        allPreferences: preferences,
        operationPreferences,
    };
};

export const getVisibleFilters = (config, activeModule, filters) => {
    let fields = {};
    const filterArray = config.filterFields.map((item) => item.name);
    if (!view) return fields;
    view.map.layers.forEach((layer) => {
        layer.fields &&
            layer.visible &&
            filters[activeModule] &&
            Object.keys(filters[activeModule]).forEach((filterField) => {
                layer.fields &&
                    layer.fields
                        .filter((field) => field.name === filterField)
                        .forEach((field) => {
                            if (filterArray.includes(field.name)) {
                                fields[field.name] = {
                                    field,
                                    layer,
                                };
                            }
                        });
            });
    });

    return fields;
};

export const getOpsColor = (config, module = null) => {
    let selectedModule = module;
    if (!selectedModule) {
        const { activeModule } = store.getState();
        selectedModule = activeModule;
    }

    if (
        config?.modules &&
        config.modules[selectedModule] &&
        config.modules[selectedModule].moduleColor
    ) {
        return config.modules[selectedModule].moduleColor;
    }

    return config.opsColor;
};

export const toggleEditorWidget = (expand) => {
    const editorWidget = view.ui.find("Editor");
    if (editorWidget) {
        editorWidget.expanded = expand;
    }
};
