import React, {useContext, useEffect, useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import EditorSituationalFields from "../../Editor/EditorFields/EditorSituationalFields";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {EditorContext, UPDATE_STATUS_TYPE, UpdateType} from "../../Editor/EditorContextProvider";
import BatchFieldsHeader from "../../Editor/EditorFields/BatchFieldsHeader";
import {StyledArrowButton, StyledSubmitButton} from "../../../Report/new/Footer/Footer-styled";
import {LeftArrowIcon, RightArrowIcon} from "../../../Panel/components/Pagination/helpers";
import useEditor from "../../../../hooks/useEditor";
import {currAsOfDateFieldName} from "../../../../utils/API";
import {EditType} from "../../Editor/EditorSwiper/EditorSwiper";
import Loader from "../../Editor/Loader/Loader";
import {MonitoringBatchUpdateWrapper} from "../Monitoring-styled";
import {CloseIcon} from "../../../Icons";
import {StyledFiltersBackButton} from "../../../MobileApp/MobileFilter/MobileFilter-styled";
import SnackbarProvider from '../../../SnackBar';

const MonitoringBatchUpdate = ({handlePrev, onSave, openSnackbar}) => {
  const {config} = useContext(ConfigContext);
  const {batchUpdateFeatures, editableLayer, setUpdateType, situationalUpdateType} = useContext(EditorContext);
  const {doEdit, loading, methods} = useEditor({
    editType: EditType.edit ,
    config,
    onSave: ()=>{
      handlePrev();
      if (onSave){
        onSave();
      }
    },
    openSnackbar
  })
  const {formState:{isValid}, handleSubmit, getValues} = methods;
  
  useEffect(()=>{
    //for do edit function
    setUpdateType(UpdateType.situational);
  },[])
  
  const isSubmitDisabled = useMemo(()=>{
    const formValues = getValues();
    return situationalUpdateType === UPDATE_STATUS_TYPE.CURRENT ? !isValid || !formValues[currAsOfDateFieldName] : !isValid
  },[isValid, situationalUpdateType])
  
  return (
    <FormProvider {...methods}>
      <SnackbarProvider>
        <MonitoringBatchUpdateWrapper>
          {
            loading && <Loader position="absolute" />
          }
          <div style={{
            display:'flex',
            gap:4,
            padding:'0px 10px 10px 10px',
          }}>
            <BatchFieldsHeader
              editableLayer={editableLayer}
              features={batchUpdateFeatures}
              config={config}
              activeColor={config.opsColor}
              style={{
                padding:0,
                flex:1
              }}
            />
            <StyledFiltersBackButton
              onClick={handlePrev}
              style={{marginLeft:'auto'}}
            >
              <CloseIcon />
            </StyledFiltersBackButton>
          </div>
          <EditorSituationalFields />
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop:'auto',
            padding:8
          }}>
            <StyledSubmitButton
              style={{marginLeft:'auto'}}
              disable={isSubmitDisabled}
              onClick={handleSubmit(doEdit)}
              bg={config.opsColor}>
              Update {batchUpdateFeatures.length > 0 ? `(${batchUpdateFeatures.length})` : ''}
            </StyledSubmitButton>
            {/*<div style={{borderRadius: "4px", overflow: "hidden", display: "flex", height: "40px"}}>*/}
            {/*  <StyledArrowButton*/}
            {/*    bg={config.opsColor}*/}
            {/*    style={{*/}
            {/*      borderRadius: 8,*/}
            {/*      overflow: "hidden",*/}
            {/*      width: 34,*/}
            {/*      justifyContent: "center"*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <LeftArrowIcon*/}
            {/*      width={18}*/}
            {/*      color="#FFFFFF"*/}
            {/*    />*/}
            {/*  </StyledArrowButton>*/}
            {/*</div>*/}
          </div>
        </MonitoringBatchUpdateWrapper>
      </SnackbarProvider>
    </FormProvider>
  );
};

export default MonitoringBatchUpdate;
