import React, { useContext, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { TableGridWrapper } from "../FeatureTable/TableGrid/TableGrid-styled";
import {
  hasUrlPattern,
  isYesNoDomain,
  renderCell,
  ROLE_EDITOR,
  standardizeColor,
} from "../../utils/helper";
import {
  CellDateRenderer,
  CellDomainRenderer,
  CellFeatureSelectRenderer,
  CellRenderer,
  CellRendererSwitch,
} from "../FeatureTable/TableGrid/CustomCells";
import { DomainFilter } from "../FeatureTable/TableGrid/CustomFilters";
import { getLayerSymbology } from "../../utils/symbologies";
// import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-balham.css";
import { ConfigContext } from "../../utils/ConfigContext";

const rowSelection = "multiple";

const defaultColDef = {
  flex: 1,
};

const autoSizeStrategy = {
  type: "fitGridWidth",
  defaultMinWidth: 100,
  columnLimits: [
    {
      colId: "country",
      minWidth: 900,
    },
  ],
};

export const formatToDate = (time) => {
  const date = new Date(time);
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // month is zero-based
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formatted = yyyy + "-" + mm + "-" + dd;
  return formatted;
};

const getRowStyle = (params) => {
  let background;

  Object.keys(params.data).forEach((k) => {
    if (params.data.rowColorMap[k]) {
      background =
        params.data.rowColorMap[k][
          params.data[k] === "NULL" ? "NULL" : params.data[k]
        ];
    }
  });
  return {};
  return {
    background: background ? standardizeColor(background) + "14" : undefined,
  };
};

const getCellRenderer = (field, params, layer) => {
  if (isYesNoDomain(field)) {
    return <CellRendererSwitch {...params} layer={layer} />;
  }

  if (field.domain) {
    return <CellDomainRenderer {...params} />;
  }

  if (field.name === "featureSelect") {
    return <CellFeatureSelectRenderer layer={layer} {...params} />;
  }
  switch (field.type) {
    case "string": {
      return <CellRenderer {...params} />;
    }
    case "date": {
      return <CellDateRenderer {...params} />;
    }
    default: {
      return <CellRenderer {...params} />;
    }
  }
};

export const generateColumn = ({
  field,
  config,
  layer,
  t,
  isEditable = true,
  maxWidth,
  minWidth,
}) => {
  const defaultColumn = {
    field: field.name,
    headerName: field.alias
      ? t("layer.fieldAlias." + field.name, field.alias)
      : "",
    minWidth: minWidth,
    maxWidth: maxWidth,
    filter: !!field.alias,
    // floatingFilter:true,
    editable:
      config.role === ROLE_EDITOR && !isYesNoDomain(field) && isEditable,
    wrapText: true,
    // autoHeight: false,
    cellDataType: field.type === "date" ? "date" : undefined,
    cellRenderer: (params) => {
      return getCellRenderer(field, params, layer);
    },
  };

  //showing select with values
  if (field.domain && !isYesNoDomain(field)) {
    defaultColumn.cellEditor = "agSelectCellEditor";
    const values = [];
    let refData = {};
    field.domain.codedValues.forEach((cv) => {
      values.push(cv.code);
      refData = {
        ...refData,
        [cv.code]: renderCell(field, cv.code, t, config),
      };
    });

    if (field.nullable) {
      refData["NULL"] = " "; //t('screen.domain.unspecified', "Unspecified");
      values.push("NULL");
    }
    //Implemented custom dropdown filter for domains
    defaultColumn.filter = (params) => (
      <DomainFilter layer={layer} field={field} {...params} />
    );

    defaultColumn.refData = refData;

    if (config.role === ROLE_EDITOR) {
      defaultColumn.cellEditorParams = {
        values,
      };
    }
  }

  if (isYesNoDomain(field)) {
    const refData = {
      1: renderCell(field, 1, t, config),
      2: renderCell(field, 2, t, config),
    };

    //Implemented custom dropdown filter for domains
    defaultColumn.filter = (params) => (
      <DomainFilter layer={layer} field={field} {...params} />
    );
    defaultColumn.refData = refData;
  }

  return defaultColumn;
};

export const getCellValue = ({ field, value, config, t }) => {
  if (hasUrlPattern(value)) {
    return value;
  } else {
    //if field is domain we have refData to show label
    return field.domain ? value : renderCell(field, value, t, config) ?? "";
  }
};

export const generateRows = ({ features, layer, config, fields, t }) => {
  const row = [];
  const { colorMap } = getLayerSymbology(layer, config) || {};
  const rowColorMap = {};

  if (colorMap?.field) {
    rowColorMap[colorMap.field] = colorMap.default;
  }

  if (Array.isArray(colorMap?.fields)) {
    colorMap.fields.forEach((field) => {
      rowColorMap[field] = colorMap.default;
    });
  }

  features.forEach((feature) => {
    const rowData = {
      featureSelect: false,
      [layer.objectIdField]: feature.attributes[layer.objectIdField],
      objectIdField: layer.objectIdField,
      rowColorMap,
      feature: feature.clone(),
    };

    fields.forEach((field) => {
      let cellValue = getCellValue({
        field,
        value: feature.attributes[field.name],
        config,
        t,
      });
      if (field.type === "date" && !feature.attributes[field.name]) {
        cellValue = "";
      }

      if (hasUrlPattern(feature.attributes[field.name])) {
        cellValue = feature.attributes[field.name];
      }

      rowData[field.name] = cellValue === null ? "NULL" : cellValue;
    });

    row.push(rowData);
  });

  return row;
};

const LayerTable = ({ rows, columns, layer, setGridApi, ...props }) => {
  const { config } = useContext(ConfigContext);
  const gridApi = useRef();
  const autoSizeDescriptionColumn = () => {
    if (gridApi.current) {
      const { colorMap = {} } = getLayerSymbology(layer, config) || {};
      const fields = [];

      if (colorMap.field) {
        fields.push(colorMap.field);
      } else if (Array.isArray(colorMap.fields)) {
        fields.push(...colorMap.fields);
      }

      if (fields.length > 0) {
        setTimeout(() => {
          gridApi.current.autoSizeColumns(fields);
        }, 100);
      }
    }
  };

  const onFirstDataRendered = () => {
    autoSizeDescriptionColumn();
  };

  const onRowDataUpdated = () => {
    autoSizeDescriptionColumn();
  };

  return (
    <TableGridWrapper
      className="ag-theme-balham" // applying the grid theme
    >
      <AgGridReact
        key={`ag-grid-key${layer.id}`}
        rowData={rows}
        columnDefs={columns}
        defaultColDef={defaultColDef}
        rowSelection={rowSelection}
        getRowStyle={getRowStyle}
        reactiveCustomComponents
        alwaysShowHorizontalScroll
        scrollBarWidth={8}
        onGridReady={(event) => {
          const { api } = event;
          setGridApi(api);
          gridApi.current = api;
          // const allColumnIds = [];
          // api.getAllColumns().forEach((column) => {
          //   allColumnIds.push(column.getId());
          // });
          // console.log(allColumnIds)
          // api.autoSizeColumns(allColumnIds);
        }}
        onFilterChanged={(event) => {
          const { api } = event;
          setGridApi(api);
          gridApi.current = api;
        }}
        onFirstDataRendered={onFirstDataRendered}
        onRowDataUpdated={onRowDataUpdated}
        // autoSizeStrategy={autoSizeStrategy}
        {...props}
      />
    </TableGridWrapper>
  );
};

export default LayerTable;
