import styled from 'styled-components';
import {StyledModuleIcon} from "../../Modules/Modules-styled";
import {StyledFiltersBackButton} from "../../MobileApp/MobileFilter/MobileFilter-styled";

export const StyledStatusBarWrapper = styled.div`
  flex:1;
  padding: 4px 8px;
`

export const StyledStatusBar = styled.div`
  position: relative;

  span {
    position: absolute;
    font-size: 12px;
    left: 0;
    top: -4px;
  }
`

export const StyledChecklistHeader = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledCheckListTotalInformationWrapper = styled.div`
  width: 100%;
  margin: 4px 0;
  padding: 0 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 14px;
`

export const StyledCheckListProgressWrapper = styled.div`
  position: relative;
  //top: -10px;
  width: 100%;
  height: 100%;
  min-height: 5px;
  border-radius: 100px;
  overflow: hidden;
`

export const StyledCheckListProgressInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
    gap: 50px;
  
  p {
    margin: 0;
    font-size: 24px;
  }
`

export const CheckListTabWrapper = styled.div`
  min-height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 44px;
  overflow: auto;
  flex:2;
  padding: 10px;
  margin-bottom: 52px;
  justify-content: center;
`

export const StyledCheckListTab = styled.div`
  position: relative;
  cursor: pointer;
  min-width: 32px;
  min-height: 32px;
  // border: 2px solid ${({selected, isUpToDate})=> selected ? 'var(--opsColor)' : isUpToDate ? 'transparent' : '#eee' };;
   // box-shadow:  ${({isUpToDate, selected})=> selected ? 'green 0px 0px 0px 2px' :  isUpToDate ? 'green 0px 0px 0px 2px' : '' };
  display: flex;
  border-radius: 100px;

  // :not(:last-child)::after {
  //   content: '';
  //   height: 2px;
  //   background: ${({isUpToDate})=> isUpToDate ? '#eee' : '#eee'};
  //   width: ${({isUpToDate})=> isUpToDate ? '54px' : '50px'};
  //   position: absolute;
  //   top: 50%;
  //   left: ${({isUpToDate})=> isUpToDate ? '100%'  : 'calc(100% + 2px)'};
  //   opacity: 1;
  //   -webkit-transition: all 0.15s;
  //   transition: all 0.15s;
  //   transform: translateY(-50%);
  //   z-index: 0;
  // }
    
    :not(:last-child)::after {
        content: '';
        height: 44px;
        background: #eee;
        width: 2px;
        position: absolute;
        top: 100%;
        opacity: 1;
        -webkit-transition: all 0.15s;
        transition: all 0.15s;
        -ms-transform: translateY(-50%);
        transform: translateX(-50%);
        z-index: 0;
        left: 50%;
    }
  
  .tab__check {
    
    ${({isUpToDate})=> isUpToDate ? `
      position: absolute;
      top: -10px;
      right: -13px;
      // width: 160%;
      // height: 160%;
      opacity: 1;
    ` : `
        position: absolute;
        top:-8px;
        right: -8px;
        width: 14px;
        height: 14px;
    `}
  }
  
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: ${({selected})=> selected ? 1 : 1};
    
    svg {
      //width: 40px !important;
      //height: 40px !important;
    }
  }
`

export const StyledCheckListTableWrapper = styled.div`
  flex:1;
  
  .ag-root-wrapper {
    border: 1px solid #9e9e9e30;
  }
  
  .ag-cell-dirty {
    border: 1px solid #1E90FFFF;
  }
    
    .ag-cell {
        //text-align: left;
    }
`

export const StyledCheckListFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 4px 8px;
  height: 30px;
  align-items: center;
`

export const BreakdownCard = styled.div`
  display:flex;
  flex-direction:column;
  gap:10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  align-items:center;
  
  span {
    color: #525252;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  background: ${({checked})=> checked ? '#6cbe4521'  : '#fff'}; //6cbe4521
  border: 2px solid ${({checked})=> checked ? 'green'  : 'var(--opsColor)'}; //6cbe45
  padding: 2px 2px 2px 12px;
  border-radius: 100px;
  
  span {
    font-size: 18px;
    color: ${({checked})=> checked ? 'green'  : 'var(--opsColor)'};
  }
  
  //.checkbox-wrapper-31 {
  //  position: relative;
  //  display: inline-block;
  //  width: 32px;
  //  height: 32px;
  //}
  //.checkbox-wrapper-31 .background {
  //  fill: #ccc;
  //  transition: ease all 0.6s;
  //  -webkit-transition: ease all 0.6s;
  //}
  //.checkbox-wrapper-31 .stroke {
  //  fill: none;
  //  stroke: #fff;
  //  stroke-miterlimit: 10;
  //  stroke-width: 2px;
  //  stroke-dashoffset: 100;
  //  stroke-dasharray: 100;
  //  transition: ease all 0.6s;
  //  -webkit-transition: ease all 0.6s;
  //}
  //.checkbox-wrapper-31 .check {
  //  fill: none;
  //  stroke: #fff;
  //  stroke-linecap: round;
  //  stroke-linejoin: round;
  //  stroke-width: 2px;
  //  stroke-dasharray: 22;
  //  transition: ease all 0.6s;
  //  -webkit-transition: ease all 0.6s;
  //  stroke-dashoffset: 0;
  //}
  //.checkbox-wrapper-31 input[type=checkbox] {
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  left: 0;
  //  top: 0;
  //  margin: 0;
  //  opacity: 0;
  //  -appearance: none;
  //  -webkit-appearance: none;
  //}
  //.checkbox-wrapper-31 input[type=checkbox]:hover {
  //  cursor: pointer;
  //}
  //.checkbox-wrapper-31 input[type=checkbox]:checked + svg .background {
  //  fill: #6cbe45;
  //}
  //.checkbox-wrapper-31 input[type=checkbox]:checked + svg .stroke {
  //  stroke-dashoffset: 0;
  //}
  //.checkbox-wrapper-31 input[type=checkbox]:checked + svg .check {
  //  stroke-dashoffset: 0;
  //}
`;

export const MonitoringBatchUpdateWrapper = styled.div`
  position: absolute;
  height: calc(100% - 50px);
  width: 320px;
  text-align: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 25px;
  right: 0;
  background: #fff;
  border-radius: 8px;
  padding: 10px 0px;
  box-sizing: border-box;
  pointer-events: auto;
  transform: translateX(calc(100% + 4px));
`


export const StyledArrowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s;
  opacity: ${({disable, show})=> show ? 0 : disable ? 0.5 : 1};
  pointer-events: ${({disable, show})=> (show || disable )? 'none' : 'auto'};
  border-radius: 100px;
  background: none;
  color: #fff;
  padding: 10px 8px;
  
  &:hover {
    background: #eeeeee;
  }

  :disabled {
    background-color:${({ bg })=> bg ? `${bg}7f` : '#ffffff7f' };
  }
`

export const CheckListSidebarWrapper = styled.div`
    height: calc(100% - 20px);
    width: 300px;
    box-sizing: border-box;
    padding: 0px  0px 2px 0px;
    border-right: 1px solid #eee;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    //position: relative;
    //top: -8px;
    //margin: 10px;
    border-radius: 20px;
`

export const ChecklistSidebarModuleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
    padding: 4px 14px 4px 0px;
    flex: 1;
`

export const ChecklistSidebarBox = styled.div`
    background: ${({bg = '#f5f7f7'})=> bg};
    border-radius: 100px;
    padding: 12px 12px;
    cursor: pointer;
    transition: all 0.3s;
`

export const ChecklistSidebarBoxContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    
    svg {
        width: 20px;
        height: 20px;
    }
`

export const ChecklistSidebarBoxTitle = styled.div`
    display: flex;
    flex-direction: column;
    color: #000000;
    margin-right: auto;
    text-align: left;
    gap: 4px;
    
    p {
        position: relative;
        transition: all 0.3s;
        font-size: 13px;
        margin: 0;

      max-width: 164px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width:max-content;
        // ${({elipsis})=> elipsis ? `
        //     max-width: 140px;
        //     white-space: nowrap;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     width:max-content;
        // `  : ''}
        
        
        :after {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            height: 1px;
            width: 100%;
            background: #000000;
            transform-origin: 0 0;
            transform: ${({isCompleted})=> isCompleted ? 'scaleX(1)' : 'scaleX(0)'};
            transition: all 0.3s ease;
        }
    }
    
    span {
        color:#6f6e6e;
        font-size: 10px;
    }
`
export const ChecklistSidebarBoxIcon = styled.div`
  svg {
      //width: 14px;
      //height: 14px;
  }
`

export const ChecklistSidebarOverallBox = styled.div`
    cursor: default;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 0px 7px;
`

export const ChecklistSidebarOverallHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 14px 14px 14px;
    
    p {
        font-size: 16px;
        margin: 0;
        text-align: left;
        color: #212121;
        //color: #FFFFFF;
        z-index: 1;
    }
    
    span {
        font-size: 14px;
        z-index: 1;
        color: #4b4b4b;
        //color: #FFFFFF;
    }
`

const lightBgColor = '#eeeeee';
const bgColor = '#909090';


export const ChecklistSidebarCheckboxWrapper = styled.div`
  pointer-events: none;
  label {
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }

  label span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }

  label span:first-child {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #c6c6c6;
    transition: all 0.2s ease;
  }

  label span:first-child svg {
    width: 14px;
    height: 14px;
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 5px;
    fill: none;
    stroke: white;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }

  label span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: ${lightBgColor};
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    transition-delay: 0.2s;
  }

  label span:last-child:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    height: 1px;
    width: 100%;
    background: #B9B8C3;
    transform-origin: 0 0;
    transform: scaleX(0);
  }

  label:hover span:first-child {
    border-color: ${bgColor};
  }

  input:checked + label span:first-child {
    border-color: ${bgColor};
    background: ${bgColor};
    animation: check-15 0.6s ease;
  }

  input:checked + label span:first-child svg {
    stroke-dashoffset: 0;
  }

  input:checked + label span:first-child:before {
    transform: scale(2.2);
    opacity: 0;
    transition: all 0.6s ease;
  }

  input:checked + label span:last-child {
    color: #B9B8C3;
    transition: all 0.3s ease;
  }

  @keyframes check-15 {
    50% {
      transform: scale(1.2);
    }
  }
`;
