import useLayersChange from "./useLayersChange";
import {useEffect, useMemo, useState} from "react";
import {currAsOfDateFieldName, view} from "../utils/API";
import {isEditable} from "../components/Dashboard/Editor/helpers/helpers";

const useEditableLayers = (config, includeOnlyVisible = true) => {
  const [editableLayers, setEditableLayers] = useState([]);
  const {visibleLayersIds, layerCount} = useLayersChange(config);
  
  // const groupLayers = (layers) => {
  //   const layersByLabel = {};
  //
  //   layers.forEach(l=>{
  //     const label = l.layerConfig?.titleLabel;
  //     if (label && (includeOnlyVisible ? l.visible : !includeOnlyVisible)) {
  //       if (layersByLabel[label]) {
  //         layersByLabel[label].groupedIds.push(l.id)
  //       } else {
  //         layersByLabel[label] = l;
  //         layersByLabel[label].groupedIds = [l.id];
  //       }
  //     }
  //   })
  //   return layersByLabel;
  // }
  //
  // const groupedLayers = useMemo(()=>{
  //   return groupLayers(editableLayers)
  // },[editableLayers])
  
  useEffect(()=>{
    let timer;
    timer = setTimeout(()=>{
      updateLayers(view.map.layers);
    },200)
    return ()=>{
      clearTimeout(timer);
    }
  },[layerCount])
  
  const updateLayers = (newLayers) => {
    const newEditableLayers = newLayers.filter((l) => isEditable(l) && (includeOnlyVisible ? visibleLayersIds[l.id] : !includeOnlyVisible))
    
    if (newEditableLayers.length) {
      setEditableLayers(newEditableLayers)
    }
  }
  
  const situationalLayers = useMemo(()=>{
    return editableLayers.filter(l=>{
      return l.fields.some(f=>f.name.includes(currAsOfDateFieldName))
        && l.layerConfig?.situationalFields?.length > 0
    })
  },[editableLayers])
  
  return {
    editableLayers,
    setEditableLayers,
    situationalLayers
  }
};

export default useEditableLayers;
