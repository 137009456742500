import React from "react";
import {StyledOverlay} from "./Message-styled";
import {StyledSubmitButton} from "../../../Report/new/Footer/Footer-styled";

const Message = ({color, onSubmit, onCancel, children, title, subtitle, position, bg}) => {
  return (
    <StyledOverlay
      position={position}
      bg={bg}
    >
      <div style={{padding: 20, background:'#fff', borderRadius:8}}>
        {(title || subtitle) ? (
          <>
            {title && <p className="title" dangerouslySetInnerHTML={{__html: title}} />}
            {subtitle && <p className="subtitle">{subtitle}</p>}
          </>
        ) : children}
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", gap: 20}}>
          <StyledSubmitButton
            bg={color}
            onClick={onCancel}>
            No
          </StyledSubmitButton>
          <StyledSubmitButton
            bg={color}
            onClick={onSubmit}>
            Yes
          </StyledSubmitButton>
        </div>
      </div>
    </StyledOverlay>
  );
};

export default Message;
