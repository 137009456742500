import { loadModules, setDefaultOptions } from "esri-loader";
import React, { lazy, Suspense } from "react";
import CEPULegend from "../components/CEPULegend";
import CEPUPacificLegend from "../components/CEPUPacificLegend";
import LatestUpdates from "../components/LatestUpdates";
import ReportManager from "../components/ReportManager";
import {
  EMBED_VARIANTS,
  getDisclaimerText,
  isTouchScreen,
  ROLE_EDITOR,
  togglePopupEditor,
} from "../utils/helper";
import { createPrint } from "./widgets/print.js";
import {
  createAutoCollapseExpand,
  isWidgetDisplayed,
  removeWidget,
} from "./widgets/expandUtils";
import { createLegend } from "./widgets/legend";
import BatchEditorWidget from "../components/BatchEditor";
import EditorWidget from "../components/Editor";
import Filter from "../components/Filter";
import Analytics from "../components/Analytics";
import { view } from "../utils/API";
import { Provider } from "react-redux";
import store from "../redux/store";
import SymbologyLegend from "../components/SymbologyLegend";
import EditorToggle from "../components/Editor/EditorToggle";
// import {ReportButton} from "../components/Report/new/ReportButton.js/ReportButton";
import Banner from "../components/Banner";
import { ConfigContext } from "../utils/ConfigContext";
// import ManualWidget from "../components/ManualWidgets/ManualWidget";
// import Basemap from "../components/Basemap/Basemap";
import { createRoot } from "react-dom/client";
import { setLatestUpdates } from "../redux/action/setLatestUpdates";
import LayerList from "../components/LayerListWidget/LayerList";
import DesktopHeader from "../components/Header/DesktopHeader";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
// import LayerListWidget from "../components/LayerListWidget/LayerList";
// import SearchWidget from "../components/SearchWidget/SearchWidget";
// import TrackLocation from "../components/TackLocation/TrackLocation";

const ReportButton = lazy(() =>
  import("../components/Report/new/ReportButton.js/ReportButton")
);
const ManualWidget = lazy(() =>
  import("../components/ManualWidgets/ManualWidget")
);
const Basemap = lazy(() => import("../components/Basemap/Basemap"));
const LayerListWidget = lazy(() =>
  import("../components/LayerListWidget/LayerList")
);
const SearchWidget = lazy(() =>
  import("../components/SearchWidget/SearchWidget")
);
const TrackLocation = lazy(() =>
  import("../components/TackLocation/TrackLocation")
);

// setDefaultOptions({css: true});

/**
 * Create all widgets on the page
 */
export const createWidgets = (
  view,
  config,
  graphicsLayer,
  activeModule,
  setActiveLanguage,
  setSideBarIFrame,
  setFeatureTableActive,
  i18n,
  t,
  openSnackbar
) => {
  return loadModules(["esri/widgets/Expand", "esri/core/reactiveUtils"]).then(
    async ([Expand, reactiveUtils]) => {
      //Manual
      // createHeader(view,t,config, setActiveLanguage);
      createMapFilter(view, t, config);
      // createOptionalLayers(view, t, config)

      //Top left
      loadModules(["esri/widgets/LayerList"]).then(([LayerList]) => {
        // createSearchWidget(view, config, t, 0);
        createLayerList(LayerList, Expand, view, t, config, 1);
        // createFilter(Expand, view, activeModule, t, config, 3)
        // createAnalytics(Expand, reactiveUtils, view, t, config, 4)
        // createBanner(Expand, reactiveUtils, view, t, config)
      });

      //Bottom left widgets:
      loadModules([
        "esri/widgets/Legend",
        "esri/widgets/CoordinateConversion",
      ]).then(([Legend, CoordinateConversion]) => {
        createLegend(Legend, reactiveUtils, Expand, view, t, config, 0);
        createSymbologyLegend(Expand, reactiveUtils, view, t, config, 1);
        createCEPULegend(Expand, t, config, 2);
        createCEPUPacificLegend(Expand, t, config, 3);
        // createReportManager(Expand, t, config, 4)
        createCoordinateConversion(
          CoordinateConversion,
          Expand,
          view,
          t,
          config,
          5
        );
      });

      //Top right widgets
      loadModules(["esri/widgets/Sketch", "esri/widgets/Print"]).then(
        ([Sketch, Print]) => {
          createReport(
            reactiveUtils,
            Expand,
            config,
            t,
            setSideBarIFrame,
            2,
            openSnackbar
          );
          createLatestUpdates(Expand, activeModule, t, config, 3);
          createFeatureTable(config, setFeatureTableActive, t, 6);
          createSketch(Sketch, Expand, view, graphicsLayer, t, config, 7);
          // createPrint(Print, reactiveUtils, Expand, view, t, config, 8, openSnackbar)
        }
      );

      //Bottom right
      loadModules([
        "esri/widgets/Zoom",
        "esri/widgets/ScaleBar",
        "esri/widgets/Measurement",
        "esri/geometry/Polyline",
        "esri/geometry/Polygon",
        "esri/widgets/BasemapGallery",
        "esri/widgets/BasemapGallery/support/PortalBasemapsSource",
        "esri/widgets/Locate",
        "esri/widgets/Track",
      ]).then(
        ([
          Zoom,
          ScaleBar,
          Measurement,
          Polyline,
          Polygon,
          BasemapGallery,
          PortalBasemapsSource,
          Locate,
          Track,
        ]) => {
          createLocateWidget(view, t, config, 0, Locate, Track, openSnackbar);
          createZoom(Zoom, Expand, view, 0);
          createBasemapGallery(
            BasemapGallery,
            PortalBasemapsSource,
            Expand,
            view,
            t,
            config,
            1
          );
          createScaleBar(ScaleBar, Expand, view, t, config, 2);
          createMeasurement(
            Measurement,
            reactiveUtils,
            Expand,
            view,
            t,
            config,
            3
          );
          createCustomEditor(
            reactiveUtils,
            Expand,
            Polyline,
            Polygon,
            view,
            t,
            config,
            4,
            openSnackbar
          );
          createBatchEditor(
            reactiveUtils,
            Expand,
            view,
            t,
            config,
            5,
            openSnackbar
          );
          createPanelEditor(view, t, config, 6);
        }
      );

      createDisclaimer(activeModule, view, config, t);
    }
  );
};

export const createSketch = (
  Sketch,
  Expand,
  view,
  graphicsLayer,
  t,
  config,
  pos
) => {
  const widgetId = "Sketch";
  if (!isWidgetDisplayed(config, widgetId)) return;

  const widget = new Sketch({
    view,
    layout: "vertical",
    title: t("screen.widget.Sketch.name"),
    layer: graphicsLayer,
    creationMode: "update",
  });

  const expand = createAutoCollapseExpand(
    Expand,
    view,
    config,
    t,
    widgetId,
    widget,
    "esri-icon-edit"
  );
  view.ui.add({ component: expand, position: "top-right", index: pos });
};

export const createDisclaimer = (activeModule, view, config, t) => {
  const widgetId = "Disclaimer";
  if (!isWidgetDisplayed(config, widgetId)) return;

  const disclaimerNode = document.getElementById("disclaimer");
  if (!disclaimerNode) return;

  const textNode = disclaimerNode.querySelector("#disclaimerText");
  const expandNode = disclaimerNode.querySelector("#disclaimerExpand");
  textNode.innerHTML = getDisclaimerText(config, activeModule, t);

  createOrGetDisclaimerNode(view, disclaimerNode, textNode, expandNode);
};

const createOrGetDisclaimerNode = (
  view,
  disclaimerNode,
  textNode,
  expandNode
) => {
  const upIcon = "esri-icon-up";
  const downIcon = "esri-icon-down";

  const isCompressed = () => expandNode.classList.contains(upIcon);

  const toggleExpand = () => {
    const compressed = isCompressed();
    expandNode.classList.toggle(downIcon);
    expandNode.classList.toggle(upIcon);
    textNode.style.whiteSpace = compressed ? "unset" : "nowrap";
    //disclaimerNode.style.height = compressed ? "unset" : "20px"
  };

  const toggleExpandNode = () => {
    expandNode.style.display =
      textNode.scrollWidth <= textNode.clientWidth ? "none" : null;
  };
  //Resize event on disclaimer text: toggle arrow icon visibility
  new ResizeObserver(() => {
    if (isCompressed()) toggleExpandNode();
  }).observe(disclaimerNode);

  //Text changed in the disclaimer, toggle visibility, and uncompress
  new MutationObserver(() => {
    if (!isCompressed(expandNode)) toggleExpand();

    toggleExpandNode();
  }).observe(textNode, { childList: true });

  //Click anywhere on the page will close the expand, click on the expand button will toggle
  document.body.addEventListener(
    "click",
    (el) => {
      if (
        (el.target !== expandNode && !isCompressed()) ||
        el.target === expandNode
      )
        toggleExpand();
    },
    true
  );
};

const createCoordinateConversion = (Expand, view, t, config, pos) => {
  const widgetId = "CoordinateConversion";
  if (!isWidgetDisplayed(config, widgetId)) return;

  let widget = new CoordinateConversion({
    id: widgetId,
    view: view,
    multipleConversions: false,
  });
  view.ui.add({ component: widget, position: "bottom-left", index: pos });
};

const createLayerList = (LayerList, Expand, view, t, config, pos) => {
  const widgetId = "LayerList";
  if (!isWidgetDisplayed(config, widgetId)) return;

  const node = document.createElement("div");

  const root = createRoot(node);
  root.render(
    <Provider store={store}>
      <Suspense>
        <LayerListWidget
          view={view}
          t={t}
          LayerList={LayerList}
          config={config}
        />
      </Suspense>
    </Provider>
  );

  const expand = createAutoCollapseExpand(
    Expand,
    view,
    config,
    t,
    widgetId,
    node,
    "layers"
  );
  view.ui.add({ component: expand, position: "top-left", index: pos });

  expand.container.classList.add("onboarding-layer-list");
  expand.container.classList.add("layer-list-widget");
  setTimeout(() => {
    const widgetButton = expand.container.querySelector(".esri-widget--button");
    if (widgetButton) {
      widgetButton.classList.add("gtag");
      widgetButton.setAttribute("data-tag-name", "LayerList");
    }
  }, 100);

  expand.container.id = "onboarding-widget";
};

export const createAnalytics = (
  Expand,
  reactiveUtils,
  view,
  t,
  config,
  pos
) => {
  const widgetId = "Analytics";
  if (!isWidgetDisplayed(config, widgetId)) return;

  const node = document.createElement("div");
  const expand = createAutoCollapseExpand(
    Expand,
    view,
    config,
    t,
    widgetId,
    node,
    "esri-icon-chart"
  );
  view.ui.add({ component: expand, position: "top-left", index: pos });

  const root = createRoot(node);
  root.render(
    <Analytics
      view={view}
      config={config}
      t={t}
      expand={expand}
      reactiveUtils={reactiveUtils}
    />
  );
};

export const createBanner = (Expand, reactiveUtils, view, t, config) => {
  //const widgetId = "Banner"
  //if (!isWidgetDisplayed(config, widgetId))
  //	return

  const node = document.createElement("div");
  view.ui.add(node);
  node.style.inset = "15px 60px";
  node.style.height = "max-content";

  const root = createRoot(node);
  root.render(
    <Banner view={view} config={config} t={t} reactiveUtils={reactiveUtils} />
  );
};

const createCEPULegend = (Expand, t, config, pos) => {
  const widgetId = "CEPULegend";
  if (!isWidgetDisplayed(config, widgetId)) return;

  const node = document.createElement("div");

  const root = createRoot(node);
  root.render(<CEPULegend t={t} />);
  createCEPUExpand(widgetId, Expand, node, t, config, pos);
};

const createCEPUPacificLegend = (Expand, t, config, pos) => {
  const widgetId = "CEPUPacificLegend";
  if (!isWidgetDisplayed(config, widgetId)) return;

  const node = document.createElement("div");
  const root = createRoot(node);
  root.render(<CEPUPacificLegend t={t} />);
  createCEPUExpand(widgetId, Expand, node, t, config, pos);
};

const createCEPUExpand = (widgetId, Expand, node, t, config, pos) => {
  const expand = createAutoCollapseExpand(
    Expand,
    view,
    config,
    t,
    widgetId,
    node,
    "esri-icon-legend"
  );
  view.ui.add({ component: expand, position: "bottom-left", index: pos });
};

const createScaleBar = (ScaleBar, Expand, view, t, config, pos) => {
  const widgetId = "ScaleBar";
  if (!isWidgetDisplayed(config, widgetId)) return;

  view.ui.remove(widgetId);
  const widget = new ScaleBar({ id: widgetId, view: view, unit: "dual" });
  view.ui.add(widget, { position: "bottom-right", index: pos });
};

const createLatestUpdates = (Expand, activeModule, t, config, pos) => {
  // const widgetId = "LatestUpdates"
  const newWidgetId = "LatestUpdates";
  if (config.embed === EMBED_VARIANTS.APP || window.isSmall) return;

  if (!isWidgetDisplayed(config, newWidgetId)) {
    store.dispatch(setLatestUpdates(false));
  } else if (isWidgetDisplayed(config, newWidgetId)) {
    store.dispatch(setLatestUpdates(true));
  }

  return;
  // if (!isWidgetDisplayed(config, widgetId)) {
  // 	return;
  // }

  const node = document.createElement("div");
  const expand = createAutoCollapseExpand(
    Expand,
    view,
    config,
    t,
    widgetId,
    node,
    "esri-icon-notice-round"
  );
  const root = createRoot(node);
  root.render(
    <LatestUpdates
      t={t}
      config={config}
      expand={expand}
      activeModule={activeModule}
    />
  );
  view.ui.add(expand, { position: "top-right", index: pos });
};

export const createMeasurement = (
  Measurement,
  reactiveUtils,
  Expand,
  view,
  t,
  config,
  pos
) => {
  const widgetId = "Measurement";
  if (!isWidgetDisplayed(config, widgetId)) return;

  let measurement = new Measurement({
    view: view,
    title: t("screen.widget.Measurement.name", "Measurement"),
    id: "measurement",
  });
  const expand = createAutoCollapseExpand(
    Expand,
    view,
    config,
    t,
    widgetId,
    measurement
  );
  view.ui.add(expand, { position: "bottom-right", index: pos });

  /* Stop measurement widget when expand is closed */
  reactiveUtils.watch(
    () => expand.viewModel.expanded,
    () => {
      if (!expand.viewModel.expanded) {
        /* Clearing */
        measurement.clear();
      } else {
        /* Activating */
        measurement.activeTool = "distance";
      }
    }
  );
};

const createReportManager = (Expand, t, config, pos) => {
  const widgetId = "ReportManager";
  if (!isWidgetDisplayed(config, widgetId) || config.role !== ROLE_EDITOR)
    return;

  const node = document.createElement("div");
  const root = createRoot(node);
  root.render(<ReportManager t={t} config={config} />);

  const expand = createAutoCollapseExpand(
    Expand,
    view,
    config,
    t,
    widgetId,
    node,
    "esri-icon-notice-round"
  );
  view.ui.add({ component: expand, position: "bottom-left", index: pos });
  view.map.layers.forEach((layer) => {
    layer.watch("visible", () => {
      toggleReportManager(expand);
    });
  });
  view.map.layers.on("change", (event) => {
    if (event.added)
      event.added.forEach((layer) => {
        layer.load().then((layer) => {
          layer.watch("visible", () => {
            toggleReportManager(expand);
          });
        });
      });
  });

  toggleReportManager(expand);
};

const toggleReportManager = (expand) => {
  const reportLayers = view.map.layers.filter(
    (l) => l.visible && l.layerConfig && l.layerConfig.isShownInReportManager
  );
  expand.container.style.display = reportLayers.length === 0 ? "none" : "block";
};

export const createBasemapGallery = (
  BasemapGallery,
  PortalBasemapsSource,
  Expand,
  view,
  t,
  config,
  pos
) => {
  const widgetId = "BasemapGallery";
  if (!isWidgetDisplayed(config, widgetId)) return;

  const basemapDiv = document.createElement("div");
  basemapDiv.setAttribute("id", widgetId);
  removeWidget(widgetId);
  const root = createRoot(basemapDiv);
  root.render(
    <Provider store={store}>
      <Suspense>
        <Basemap
          view={view}
          config={config}
          t={t}
          BasemapGallery={BasemapGallery}
          PortalBasemapsSource={PortalBasemapsSource}
        />
      </Suspense>
    </Provider>
  );
  view.ui.add({ component: basemapDiv, position: "bottom-right", index: pos });
};

export const createZoom = (Zoom, Expand, view, pos) => {
  if (isTouchScreen()) return;

  const widgetId = "Zoom";
  removeWidget(widgetId);
  const widget = new Zoom({
    id: widgetId,
    view: view,
    layout: "vertical",
    container: document.createElement("div"),
  });
  view.ui.add(widget, { position: "bottom-right", index: pos });
};

export const createFilter = (Expand, view, activeModule, t, config, pos) => {
  const widgetId = "Filter";
  if (
    !isWidgetDisplayed(config, widgetId) ||
    window.isSmall ||
    config.embed === EMBED_VARIANTS.APP
  )
    return;

  const node = document.createElement("div");
  node.classList.add("onboarding-filter");
  const expand = createAutoCollapseExpand(
    Expand,
    view,
    config,
    t,
    widgetId,
    node,
    "esri-icon-filter"
  );

  const root = createRoot(node);
  root.render(
    <Provider store={store}>
      <Filter t={t} config={config} expand={expand} />
    </Provider>
  );
  view.ui.add(expand, { position: "top-left", index: pos });
  expand.container.classList.add("onboarding-filter");
};

export const createMapFilter = (view, t, config) => {
  const widgetId = "Filter";
  if (config.embed === EMBED_VARIANTS.WEBSITE) return;
  // if (!isWidgetDisplayed(config, widgetId))
  // 	return

  const toggleDiv = document.createElement("div");
  const mapFilterId = `Map${widgetId}`;
  toggleDiv.setAttribute("id", mapFilterId);
  removeWidget(mapFilterId);

  const root = createRoot(toggleDiv);
  root.render(
    <Provider store={store}>
      <Suspense>
        <ManualWidget t={t} config={config} view={view} />
      </Suspense>
    </Provider>
  );
  view.ui.add({ component: toggleDiv, position: "manual" });
};

export const createHeader = (
  view,
  t,
  config,
  setActiveLanguage,
  openSnackbar
) => {
  const toggleDiv = document.createElement("div");
  const id = `Header`;
  toggleDiv.setAttribute("id", id);
  removeWidget(id);

  const root = createRoot(toggleDiv);
  root.render(
    <ConfigContext.Provider value={{ config }}>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <DesktopHeader
            t={t}
            config={config}
            view={view}
            setActiveLanguage={setActiveLanguage}
            openSnackbar={openSnackbar}
          />
        </I18nextProvider>
      </Provider>
    </ConfigContext.Provider>
  );
  view.ui.add({ component: toggleDiv, position: "manual" });
};

export const createSymbologyLegend = (
  Expand,
  reactiveUtils,
  view,
  t,
  config,
  pos
) => {
  const widgetId = "SymbologyLegend";
  if (!isWidgetDisplayed(config, widgetId)) return;

  const node = document.createElement("div");
  node.id = "legendWrapper";
  removeWidget(node.id);
  const root = createRoot(node);
  root.render(
    <Provider store={store}>
      <SymbologyLegend t={t} config={config} reactiveUtils={reactiveUtils} />
    </Provider>
  );

  view.ui.add(node, { position: "bottom-left", index: pos });

  const innerContainer = document.getElementsByClassName(
    "esri-ui-inner-container"
  )[0];
  const bottomRightWidgetContainer = innerContainer.querySelector(
    ".esri-ui-bottom-right"
  );

  const resizeEvent = (entries) => {
    if (entries.length === 0) return;

    const padding = 15;
    const bottomRightWidgetWidth = bottomRightWidgetContainer
      ? bottomRightWidgetContainer.clientWidth
      : 0;
    const availableSpace =
      innerContainer.clientWidth - bottomRightWidgetWidth - padding;
    node.style.maxWidth = availableSpace + "px";

    const availableHeight = innerContainer.clientHeight;

    if (availableHeight < 800) {
      node.style.maxHeight =
        (availableHeight > 400 ? availableHeight / 3 : availableHeight / 1.5) +
        "px";
      node.style.overflowY = "auto";
    } else {
      node.style.maxHeight = "unset";
      node.style.overflowY = "unset";
      node.style.overflowY = "auto";
    }
  };

  //Resize when new widgets added to the bottom right, or when the window is resized
  new ResizeObserver((entries) => resizeEvent(entries)).observe(innerContainer);
  new ResizeObserver((entries) => resizeEvent(entries)).observe(
    bottomRightWidgetContainer
  );
};

export const createFeatureTable = (config, setFeatureTableActive) => {
  const widgetId = "FeatureTable";
  setFeatureTableActive(isWidgetDisplayed(config, widgetId));
};

export const createReport = (
  reactiveUtils,
  Expand,
  config,
  t,
  setSideBarIFrame,
  pos
) => {
  const widgetId = "Report";
  if (
    !isWidgetDisplayed(config, widgetId) ||
    config.embed === EMBED_VARIANTS ||
    !window.isSmall
  )
    return;

  const toggleDiv = document.createElement("div");
  toggleDiv.setAttribute("id", widgetId);
  removeWidget(widgetId);

  const root = createRoot(toggleDiv);
  root.render(
    <Provider store={store}>
      <Suspense>
        <ReportButton config={config} t={t} />
      </Suspense>
    </Provider>
  );
  view.ui.add({
    component: toggleDiv,
    position: window.isSmall ? "bottom-right" : "top-right",
    index: pos,
  });
};

export const createBatchEditor = (
  reactiveUtils,
  Expand,
  view,
  t,
  config,
  pos,
  openSnackbar
) => {
  const widgetId = "BatchEditor";
  if (!isWidgetDisplayed(config, widgetId) || config.role !== ROLE_EDITOR)
    return;

  const batchEditorDiv = document.createElement("div");
  const expand = createAutoCollapseExpand(
    Expand,
    view,
    config,
    t,
    widgetId,
    batchEditorDiv,
    "line"
  );
  const root = createRoot(batchEditorDiv);
  root.render(
    <BatchEditorWidget
      expand={expand}
      config={config}
      t={t}
      openSnackbar={openSnackbar}
      reactiveUtils={reactiveUtils}
    />
  );

  view.ui.add(expand, "bottom-right", pos);
  expand.container.classList.add("editor");

  reactiveUtils.watch(
    () => expand.viewModel.expanded,
    () => {
      const editorWidget = view.ui.find("Editor");
      if (!editorWidget?.expanded) {
        togglePopupEditor({ view, expand });
      }
    }
  );
};

export const createCustomEditor = (
  reactiveUtils,
  Expand,
  Polyline,
  Polygon,
  view,
  t,
  config,
  pos,
  openSnackbar
) => {
  const widgetId = "Editor";
  if (config.role !== ROLE_EDITOR) return;

  const batchEditorDiv = document.createElement("div");
  const expand = createAutoCollapseExpand(
    Expand,
    view,
    config,
    t,
    widgetId,
    batchEditorDiv,
    "pencil"
  );
  const root = createRoot(batchEditorDiv);
  root.render(
    <EditorWidget
      expand={expand}
      config={config}
      t={t}
      openSnackbar={openSnackbar}
      reactiveUtils={reactiveUtils}
      Polyline={Polyline}
      Polygon={Polygon}
    />
  );

  view.ui.add(expand, "bottom-right", pos);
  expand.container.classList.add("editor");

  reactiveUtils.watch(
    () => expand.viewModel.expanded,
    () => {
      togglePopupEditor({ view, expand });
    }
  );
};

export const createPanelEditor = (view, t, config, pos) => {
  const widgetId = "PanelEditor";
  if (!isWidgetDisplayed(config, widgetId)) return;

  const editableLayers = view.map.layers.filter(
    (l) => l.type && l.type === "feature" && l.layerConfig?.editable
  );
  if (!editableLayers || editableLayers.length === 0) return;

  const toggleDiv = document.createElement("div");
  toggleDiv.setAttribute("id", widgetId);
  removeWidget(widgetId);

  const root = createRoot(toggleDiv);
  root.render(
    <Provider store={store}>
      <EditorToggle t={t} />
    </Provider>
  );
  view.ui.add({ component: toggleDiv, position: "bottom-right", index: pos });
};

const createLocateWidget = (
  view,
  t,
  config,
  pos,
  Locate,
  Track,
  openSnackbar
) => {
  const widgetId = "LocateWidget";
  if (!isWidgetDisplayed(config, widgetId)) return;

  const node = document.createElement("div");
  node.id = widgetId;
  node.className = "esri-widget--button";
  removeWidget(node.id);

  // const locateBtn = new Locate({
  // 	view: view,
  // 	id: widgetId,
  // 	container: node
  // });

  // // Add the locate widget
  // view.ui.add(locateBtn, {
  // 	position: "bottom-right"
  // }, pos);

  // will implement in future to track user location
  const root = createRoot(node);
  root.render(
    <Provider store={store}>
      <Suspense>
        <TrackLocation
          view={view}
          Track={Track}
          widgetId={widgetId}
          Locate={Locate}
          openSnackbar={openSnackbar}
        />
      </Suspense>
    </Provider>
  );

  view.ui.add({ component: node, position: "bottom-right", index: pos });
};
const createOptionalLayers = (view, t, config) => {
  if (isWidgetDisplayed(config, "Filter")) return;

  const toggleDiv = document.createElement("div");
  const id = `optionalLayers`;
  toggleDiv.setAttribute("id", id);
  //const div = document.querySelector('#MapFilter')
  // removeWidget(mapFilterId)

  const root = createRoot(toggleDiv);
  root.render(
    <Provider store={store}>
      <ManualWidget t={t} config={config} view={view} />
    </Provider>
  );
  view.ui.add({ component: toggleDiv, position: "manual" });
};

const createSearchWidget = (view, config, t, pos) => {
  const widgetId = "Search";

  if (
    !isWidgetDisplayed(config, widgetId) ||
    config.embed === EMBED_VARIANTS.APP ||
    window.isSmall
  )
    return;

  const div = document.createElement("div");
  div.setAttribute("id", widgetId);

  const root = createRoot(div);
  root.render(
    <Provider store={store}>
      <Suspense>
        <SearchWidget t={t} config={config} view={view} />
      </Suspense>
    </Provider>
  );
  view.ui.add({ component: div, position: "top-left", index: pos });
};
