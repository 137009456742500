import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import ChecklistHeader from "./ChecklistHeader/ChecklistHeader";
import ChecklistTable from "./ChecklistTable";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {addLayerEffect} from "../../Editor/helpers";
import {EditorContext} from "../../Editor/EditorContextProvider";
import {currAsOfDateFieldName, graphicsLayer} from "../../../../utils/API";
import ProgressBar from "../../../Report/new/ProgressBar/ProgressBar";
import {useDispatch, useSelector} from "react-redux";
import MonitoringBatchUpdate from "../MonitoringBatchUpdate/MonitoringBatchUpdate";
import Message from "../../Editor/Message/Message";
import ChecklistSidebar from "./CheckListSidebar/ChecklistSidebar";
import {addLayersUpdate} from "../../../../redux/action/CycleManager-action";
import Loader from "../../Editor/Loader/Loader";

const MonitoringChecklist = ({layers, editedFeatures, setEditedFeatures, openSnackbar}) => {
  const {editableLayer, setEditableLayer, setBatchUpdateFeatures, batchUpdateFeatures} = useContext(EditorContext);
  const {layersUpdates} = useSelector(state=>state.cycleManagerReducer)
  const {config} = useContext(ConfigContext);
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [showBatchUpdate, setShowBatchUpdate] = useState(false);
  const [saveMessage, setSaveMessage] = useState({
    newLayer: null,
    show: false
  });
  const [saved, setSaved] = useState(false);
  
  const dispatch = useDispatch();
  
  useEffect(()=>{
    if (editableLayer){
      setBatchUpdateFeatures([]);
      graphicsLayer.removeAll();
      if (showBatchUpdate){
        setBatchUpdateFeatures([]);
        setShowBatchUpdate(false);
      }
    }
  },[editableLayer])
  
  useEffect(()=>{
    if (layers.length > 0 && editableLayer === null) {
      setEditableLayer(layers.toArray()[0])
    }
  },[layers]);
  
  useEffect(()=>{
    if (editableLayer && batchUpdateFeatures.length === 0){
      addLayerEffect([editableLayer]);
    }
  },[editableLayer])
  
  const toggleBatchUpdate = useCallback(()=>{
    setShowBatchUpdate(prev=>!prev);
  },[showBatchUpdate]);
  
  const showSaveMessage = useCallback((layer) =>{
    setSaveMessage({
      show:true,
      newLayer: layer
    })
  },[])
  
  
  const handleLayerSelection = useCallback((layer)=>{
    if (Object.keys(editedFeatures).length > 0) {
      showSaveMessage(layer)
      return
    }
    
    setEditableLayer(layer);
  },[editedFeatures]);
  
  const handleNextLayer = useCallback(()=>{
    if (!editableLayer) return;
    const layersArray = layers.toArray();
    const index = layersArray.findIndex(l=>l.id === editableLayer.id)
    if (index !== -1 && index < layersArray.length - 1) {
      const nextLayer = layersArray[index+1];
      if (Object.keys(editedFeatures).length > 0) {
        showSaveMessage(nextLayer)
        return
      }
      
      setEditableLayer(nextLayer);
    }
  },[layers, editableLayer, editedFeatures])
  
  const handlePrevLayer = useCallback(()=>{
    if (!editableLayer) return;
    const layersArray = layers.toArray();
    const index = layersArray.findIndex(l=>l.id === editableLayer.id)
    if (index !== -1 && index > 0) {
      const prevLayer = layersArray[index-1];
      if (Object.keys(editedFeatures).length > 0) {
        showSaveMessage(prevLayer)
        return;
      }
      setEditableLayer(prevLayer);
    }
  },[layers, editableLayer, editedFeatures])
  
  const handleSave = useCallback(()=>{
    const updateFeatures = [];
    const objectIdField = editableLayer.objectIdField;
    
    const newFeatures = features.map(feat=>{
      const newAttributes = editedFeatures[feat.attributes[objectIdField]] || {};
      
      if (Object.keys(newAttributes).length > 0){
        const newFeature = feat.clone();
        newAttributes[objectIdField] = feat.attributes[objectIdField];
        
        if (!newAttributes[currAsOfDateFieldName]) {
          newAttributes[currAsOfDateFieldName] = new Date().getTime();
        }
        newFeature.attributes = newAttributes;
        Object.keys(newAttributes).forEach(k=>{
          feat.attributes[k] = newAttributes[k]
        });
        
        updateFeatures.push(newFeature);
      }
      return feat.clone();
    })
    
    setLoading(true);
    editableLayer.applyEdits({
      updateFeatures: updateFeatures
    }).then(res=>{
      editableLayer.refresh();
      gridApi.refreshCells();
      setEditedFeatures({});
      setFeatures(newFeatures);
      setSaved(true);
      setLoading(false);
    })
  },[editableLayer, editedFeatures, features, gridApi])
  
  const handleUpdateAll = useCallback(async ()=>{
    try {
      setLoading(true);
      const layerUpdate = {...layersUpdates[editableLayer.layerConfig.id]};
      const featureToSave = [];
      
      
      layerUpdate.features.forEach(feat=>{
        const clone = feat.clone();
        clone.attributes[currAsOfDateFieldName] = new Date().getTime();
        featureToSave.push(clone);
      })
      
      const res = await editableLayer.applyEdits({
        updateFeatures: featureToSave
      })
      editableLayer.refresh();
      layerUpdate.features = featureToSave;
      layerUpdate.outdated = [];
      
      dispatch(addLayersUpdate({id: editableLayer.layerConfig.id, layerUpdate}))
    } catch (err){
      console.error(err);
    } finally {
      setSaved(true);
      setLoading(false);
    }
  },[layersUpdates, editableLayer, handleNextLayer])
  
  return (
    <>
      {saveMessage.show && (
        <Message
          position="absolute"
          title="Are you sure?"
          subtitle="You have unsaved changes if you change the layer they will be lost"
          onSubmit={() => {
            setEditableLayer(saveMessage.newLayer);
            setSaveMessage({
              show: false,
              newLayer: null
            })
          }}
          onCancel={() => {
            setSaveMessage({
              show: false,
              newLayer: null
            })
          }}
          confirmText="Save"
        />
      )}
      {(editableLayer && showBatchUpdate) && (
        <MonitoringBatchUpdate openSnackbar={openSnackbar} handlePrev={toggleBatchUpdate} onSave={()=>{
          setLoading(true)
          setTimeout(()=>{
            setSaved(true);
            setLoading(false);
          },100)
        }}/>
      )}
      <div style={{
        display:'flex',
        flexDirection:'row',
        height:'100%',
      }}>
        <ChecklistSidebar
          editableLayer={editableLayer}
          layers={layers}
          onLayerSelect={handleLayerSelection}
        />
        <div style={{display:'flex', flexDirection:'column', height:'100%', flex:1, position:'relative'}}>
          <ChecklistHeader
            layers={layers}
            selectedLayer={editableLayer}
            onSelect={handleLayerSelection}
            editedFeatures={editedFeatures}
            handleBatchUpdateShow={toggleBatchUpdate}
            handleSave={handleSave}
            handleUpdateAll={handleUpdateAll}
          />
          <div style={{flex:1}}>
            {editableLayer && (
              <ChecklistTable
                layers={layers}
                layer={editableLayer}
                editedFeatures={editedFeatures}
                setEditedFeatures={setEditedFeatures}
                handleNextLayer={handleNextLayer}
                handlePrevLayer={handlePrevLayer}
                loading={loading}
                setLoading={setLoading}
                gridApi={gridApi}
                setGridApi={setGridApi}
                setFeatures={setFeatures}
                features={features}
                onSelect={handleLayerSelection}
                saved={saved}
              />
            )}
          </div>
        </div>
      </div>
    </>
    
  );
};

export default MonitoringChecklist;
