import MonitoringChecklist from "../MonitoringChecklist/MonitoringChecklist";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {ConfigContext} from "../../../../utils/ConfigContext";
import {removeLayerEffects} from "../../Editor/helpers";
import {EditorContext} from "../../Editor/EditorContextProvider";
import Message from "../../Editor/Message/Message";
import {useDispatch} from "react-redux";
import {setCycleManagerOpen} from "../../../../redux/action/CycleManager-action";
import {CloseIcon} from "../../../Icons";
import {StyledFiltersBackButton} from "../../../MobileApp/MobileFilter/MobileFilter-styled";
import {view} from "../../../../utils/API";
import useCycleManager from "../../../../hooks/CycleManager/useCycleManager";
import Loader from "../../Editor/Loader/Loader";

const MonitoringSlides = ({openSnackbar}) => {
  const [editedFeatures, setEditedFeatures] = useState({});
  const [showCloseMessage, setShowCloseMessage] = useState(false);
  const {resetEditor, batchUpdateFeatures, editableLayer, setBatchUpdateFeatures} = useContext(EditorContext);
  const {config} = useContext(ConfigContext);
  const {situationalLayers:layers, layersUpdates} = useCycleManager(config);
  const swiperRef = useRef();
  const animationFrame = useRef(-1);
  
  const dispatch = useDispatch();
  // Function to animate the padding smoothly
  function animatePadding(targetPadding) {
    const currentPadding = view.padding.left || 0;
    const step = (targetPadding - currentPadding) / 20; // Adjust the divisor for smoothness
    
    function animate() {
      const newPadding = view.padding.left + step;
      
      // Stop animation when close enough to the target
      if ((step > 0 && newPadding >= targetPadding) || (step < 0 && newPadding <= targetPadding)) {
        view.padding = { left: targetPadding };
        cancelAnimationFrame(animationFrame.current);
        return;
      }
      
      // Update padding and request the next frame
      view.padding = { left: newPadding };
      animationFrame.current = requestAnimationFrame(animate);
    }
    
    animate();
  }
  
  useEffect(()=>{
    view.popupEnabled = false;
    
    const initialLeftPadding = view.padding.left;
    const cycleManager = document.getElementById('cycle-manager');
    if (cycleManager){
      const {right = 0} = cycleManager?.getBoundingClientRect() || {};
      animatePadding(right)
    }
    
    return () => {
      view.popupEnabled = true;
      view.padding = initialLeftPadding;
    }
  },[])
  
  const closeCycleManager = useCallback(()=>{
    removeLayerEffects();
    dispatch(setCycleManagerOpen(false));
    resetEditor();
  },[resetEditor])
  
  const handleClose = useCallback(() => {
    if (Object.values(editedFeatures).length > 0 || batchUpdateFeatures.length > 0){
      setShowCloseMessage(true);
    } else {
      closeCycleManager();
    }
  },[editedFeatures, batchUpdateFeatures, closeCycleManager])
  
  
  /**
   * layersUpdates is an object that includes properties features and missing which are Array of arcgis features
   * @param {Object<string, {missing: Array<Graphic>, features: Array<Graphic>}>} payload - The object with the layer id as key and
   */
  // const handleSetLayersUpdates = useCallback((layersUpdates)=>{
  //   dispatch(setLayersUpdates(layersUpdates))
  // },[])
  
  if (Object.keys(layersUpdates).length === 0) return <Loader position="absolute" />;
  
  return (
    <div
      style={{
        display:'flex',
        flexDirection:'column',
        height:'100%'
      }}>
      {
        showCloseMessage && (
          <Message
            position="absolute"
            title="Are you sure you want to stop editing? Unsaved progress will be lost."
            onSubmit={()=>{
              closeCycleManager();
            }}
            onCancel={()=>{
              setShowCloseMessage(false);
            }}
          />
        )
      }
      <div
        style={{
          marginBottom:0,
          display:'flex',
          padding:'8px',
        }}>
        
        <div style={{marginLeft:'auto', display:'flex', gap:4, alignItems:'center'}}>
          <StyledFiltersBackButton
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledFiltersBackButton>
        </div>
      </div>
      <div style={{flex:1, overflow:'hidden', padding: '2px 0px 2px 14px'}}>
        <MonitoringChecklist
          layers={layers}
          editedFeatures={editedFeatures}
          setEditedFeatures={setEditedFeatures}
          openSnackbar={openSnackbar}
        />
      </div>
    </div>
  );
};

export default MonitoringSlides;
