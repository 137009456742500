import { useState, useEffect, useCallback } from "react";
import { EMBED_VARIANTS } from "../utils/helper";

const useViewLayers = (view, config) => {
    let [localModule, setLocalModule] = useState();

    useEffect(() => {
        if (localModule) return;
        let module = config.defaultModule;
        if (
            Array.isArray(config.activeModules) &&
            !config.activeModules.includes(module)
        ) {
            module = config.activeModules
                ? config.activeModules[0]
                : config.modules[0];
        }
        setLocalModule(module);
    }, [config]);

    const setLayerVisibility = useCallback(
        (layer) => {
            if (layer.layerModules && layer.layerModules.length > 0) {
                layer.visible =
                    layer.layerModules.includes(localModule) &&
                    !(
                        config.modules[localModule]?.optionalLayers?.includes(
                            layer.layerConfig?.alias
                        ) ||
                        config.modules[localModule]?.optionalLayers?.includes(
                            layer.layerConfigAlias
                        )
                    );
            }
        },
        [localModule, config]
    );

    const handleMapLayersVisibility = useCallback(
        async (layers) => {
            for (const layer of layers) {
                await new Promise((resolve) => {
                    setLayerVisibility(layer);
                    resolve();
                });
            }
        },
        [view, setLayerVisibility]
    );

    useEffect(() => {
        if (!view || !localModule || !config.modules[localModule]) return;
        const zoom = config.modules[localModule].zoom ?? config.zoom;
        const center = config.modules[localModule].center ?? config.center;
        const minZoom = config.modules[localModule].minZoom ?? config.minZoom;
        const maxZoom = config.modules[localModule].maxZoom ?? config.maxZoom;

        if (Number.isInteger(zoom) || Array.isArray(center)) {
            view.goTo(
                {
                    zoom:
                        (zoom ?? view.zoom) -
                        (config.embed === EMBED_VARIANTS.WEBSITE ? 1 : 0),
                    center: center ?? view.center,
                },
                {
                    duration: 500,
                }
            )
                .then((res) => {})
                .catch((err) => {
                    console.log(err);
                });
        }

        if (Number.isInteger(minZoom)) {
            view.constraints.minZoom = minZoom;
        }

        if (Number.isInteger(maxZoom)) {
            view.constraints.maxZoom = maxZoom;
        }

        handleMapLayersVisibility(view.map.layers);

        // view.map.layers.forEach((layer) => {
        // 	setLayerVisibility(layer);
        // });
        // Handle layer added event
        view.map.layers.on("change", (event) => {
            if (event.added) {
                handleMapLayersVisibility(event.added);
                // event.added.forEach((layer) => {
                // 	setLayerVisibility(layer);
                // });
            }
        });
    }, [localModule, config, view]);

    return { localModule, setLocalModule };
};

export default useViewLayers;
