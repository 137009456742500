import {setActiveModule_actionType} from "../constants";

/**
 * Creates an action to set the active module.
 *
 * @param {string} module - Module (shorthand) to set as active.
 * @returns {Object} The action object.
 */
export const setActiveModule = (module) => {
  return {
    type: setActiveModule_actionType,
    payload: module
  }
}
