import styled from "styled-components";

export const StyledReportButton = styled.div`
    background-color: var(--opsColor);
    color: white;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    outline: none;
    display: flex;
    align-items: center;
    gap: 6px;
    height: 40px;
    box-sizing: border-box;
    justify-content: center;
    padding: 10px 13px;
    border-radius: 100px;
    
    span {
        pointer-events: none;
        font-size: 14px;
    }
  
    svg {
      pointer-events: none;
    }
`
