import { getField } from "../../../Report/new/helpers";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  StyledCard,
  StyledCardDescription,
  StyledCardLegendText,
  StyledCardTitle,
} from "../Insights-styled";
import CardHeader from "../InsightsCard/CardHeader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ConfigContext } from "../../../../utils/ConfigContext";
import { COLORS } from "../Insights";
import StockChart from "../Charts/StockChart";
import { information16 } from "@esri/calcite-ui-icons";
import Dropdown, { DropdownBody } from "../../../Dropdown";
import { timeAgo } from "../../../../utils/helper";
import Tooltip from "../../../UI/Tooltip";

const DOMESTIC_CHART_DESCRIPTION = {
  PRP: {
    overall: (
      <span>
        This graph shows the national existing
        <span> per item quantities </span>
        (in green) compared to an ideal national stock level (in red) should the
        country decide to reach a stock level capable of supporting 70% of the
        disasters impacting the country (on the basis of the disasters passed in
        the last 20 years) with no additional assistance (suppliers or regional
        hubs).
      </span>
    ),
    area: (
      <span>
        This graph shows the national existing
        <span> per province quantities </span>
        (in green) compared to an ideal national stock level (in red) should the
        country decide to reach a stock level capable of supporting 70% of the
        disasters impacting the country (on the basis of the disasters passed in
        the last 20 years) with no additional assistance (suppliers or regional
        hubs).
      </span>
    ),
  },
  ANT: {
    overall: (
      <span>
        This graph shows the national existing
        <span> per item quantities </span>
        (in green) compared to the forecasted needs (in red) on the basis of the
        disaster alert received.
      </span>
    ),
    area: (
      <span>
        This graph shows the existing stocks (in green) compared to forecasted
        needs (in red)
        <span> per province </span>
        on the basis of the disaster alert received.
      </span>
    ),
  },
  RES: {
    overall: (
      <span>
        This graph shows the national existing
        <span> per item quantities </span>
        (in green) compared to the needs assessment conducted in response to the
        disaster.
      </span>
    ),
    area: (
      <span>
        This graph shows the existing stocks (in green) compared to the needs
        assessment (in red)
        <span> per province </span>
        conducted in response to the disaster.
      </span>
    ),
  },
};

const Legend = ({
  quantity,
  expected,
  children,
  t,
  lastUpdateDate,
  style = {},
}) => {
  const [show, setShow] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const { i18n } = useTranslation("common");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 6,
        }}
      >
        {quantity && (
          <StyledCardLegendText bg={COLORS.green}>
            {quantity}
          </StyledCardLegendText>
        )}
        {expected && (
          <StyledCardLegendText bg={COLORS.yellow}>
            {expected}
            <Dropdown setShow={setShow} show={show} leftOffset={0.3}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 16 16"
                  fill="#6F7072"
                >
                  <path d={information16} />
                </svg>
              </div>
              <DropdownBody style={{ display: "flex" }}>
                <StyledCardDescription>
                  <span>{t("screen.widget.Insights.stp.quantityTooltip")}</span>
                </StyledCardDescription>
              </DropdownBody>
            </Dropdown>
          </StyledCardLegendText>
        )}
      </div>
      <div style={{ display: "flex", gap: 0 }}>
        {children}
        {/* {lastUpdateDate && (
          <div>
            <Dropdown
              setShow={setShowDate}
              show={showDate}
              leftOffset={0.3}
              hasMinWidth={false}
              top={0}
            >
              <div style={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 16 16"
                  fill="#6F7072"
                >
                  <path d={information16} />
                </svg>
              </div>
              <DropdownBody style={{ display: "flex" }}>
                <StyledCardDescription style={{ width: "auto", fontSize: 10 }}>
                  {timeAgo(new Date(lastUpdateDate), i18n.language, false)}
                </StyledCardDescription>
              </DropdownBody>
            </Dropdown>
          </div>
        )} */}
      </div>
    </div>
  );
};

const InsightsStockPrepositioning = ({
  expand,
  onFilterChange,
  statsData,
  layer,
}) => {
  const [barItemgroup, setBarItemgroup] = useState([]);
  const [barLocation, setBarLocation] = useState([]);

  const [domestic, setDomestic] = useState({
    itemgroup: [],
    location: [],
    lastUpdateDate: null,
  });

  const [international, setInternational] = useState({
    itemgroup: [],
    location: [],
    lastUpdateDate: null,
  });

  const [legend, setLegend] = useState({
    quantity: "",
    expected: "",
  });

  const { t } = useTranslation("common");
  const selectedFilters = useRef([]);

  const {
    activeModule,
    filters: mapFilters,
    mobileReducer,
  } = useSelector((state) => state);
  const { config } = useContext(ConfigContext);

  const handleCloseSidebar = useCallback(() => {
    setBarItemgroup([]);

    setLegend({
      quantity: "",
      expected: "",
    });

    setInternational({
      itemgroup: [],
      location: [],
      lastUpdateDate: null,
    });

    setDomestic({
      itemgroup: [],
      location: [],
      lastUpdateDate: null,
    });

    const filter = {};
    selectedFilters.current.forEach((key) => {
      const filterField = config.filterFields.find((item) => item.name === key);
      filter[key] = filterField?.defaultValue;
    });

    onFilterChange(filter);

    selectedFilters.current = [];
  }, [onFilterChange]);

  useEffect(() => {
    if (!expand) {
      handleCloseSidebar();
    }

    return () => {
      handleCloseSidebar();
    };
  }, [expand]);

  //improved with filtering by domestic and international values
  const handleBarSelect = useCallback(
    (params, data) => {
      const index = params.activeTooltipIndex;
      if (index === undefined) return;

      const filter = {};
      const payload = params.activePayload[0].payload;

      const filterField = config.filterFields.find(
        (item) => item.name === payload.key
      );
      const codedValue =
        payload.key === "itemgroup"
          ? Number(payload.codedValue)
          : payload.codedValue;
      let filteredValue = mapFilters[activeModule][payload.key] || [];

      const internationalSelected = selectedFilters.current.includes(
        "international-locationname"
      );
      const domesticSelected = selectedFilters.current.includes(
        "domestic-locationname"
      );
      const currentPayloadLocation = payload.locationType;
      const domesticLocations = domestic.location.map((loc) => loc.codedValue);
      const internationalLocations = international.location.map(
        (loc) => loc.codedValue
      );

      if (payload.key === "locationname") {
        //removing locations based on active filters
        if (
          currentPayloadLocation === "domestic" &&
          !internationalSelected &&
          filteredValue.length > 0
        ) {
          filteredValue = filteredValue.filter(
            (item) => !internationalLocations.includes(item)
          );
        } else if (
          currentPayloadLocation === "international" &&
          !domesticSelected &&
          filteredValue.length > 0
        ) {
          filteredValue = filteredValue.filter(
            (item) => !domesticLocations.includes(item)
          );
        } else if (
          currentPayloadLocation === "international" &&
          domesticSelected &&
          !internationalSelected &&
          filteredValue.length > 0
        ) {
          filteredValue = filteredValue.filter(
            (item) => !internationalLocations.includes(item)
          );
        } else if (
          currentPayloadLocation === "domestic" &&
          !domesticSelected &&
          internationalSelected &&
          filteredValue.length > 0
        ) {
          filteredValue = filteredValue.filter(
            (item) => !domesticLocations.includes(item)
          );
        }

        selectedFilters.current = selectedFilters.current.filter(
          (key) => key !== `${payload.locationType}-${payload.key}`
        );
      } else {
        selectedFilters.current = selectedFilters.current.filter(
          (key) => key !== payload.key
        );
      }

      if (
        !filteredValue ||
        (filteredValue && !filteredValue.includes(codedValue)) ||
        (payload.key === "itemgroup" &&
          filteredValue.includes(codedValue) &&
          filteredValue.length > 1)
      ) {
        if (payload.key === "locationname") {
          selectedFilters.current.push(
            `${payload.locationType}-${payload.key}`
          );
          if (
            (internationalSelected && currentPayloadLocation === "domestic") ||
            (domesticSelected && currentPayloadLocation === "international")
          ) {
            const currLocations =
              currentPayloadLocation === "domestic"
                ? domesticLocations
                : internationalLocations;

            filter[payload.key] = [
              ...filteredValue.filter((item) => !currLocations.includes(item)),
              codedValue,
            ];
          } else {
            filter[payload.key] = [codedValue];
          }
        } else {
          selectedFilters.current.push(payload.key);
          filter[payload.key] = [codedValue];
        }
      } else if (filteredValue && filteredValue.includes(codedValue)) {
        if (payload.key === "locationname") {
          filter[payload.key] = filteredValue.filter(
            (item) => item !== codedValue
          );
        } else {
          filter[payload.key] = filterField?.defaultValue ?? [];
        }
      }

      //adding locations based on active filters
      if (payload.key === "locationname" && filter[payload.key].length > 0) {
        const internationalSelectedAfter = selectedFilters.current.includes(
          "international-locationname"
        );
        const domesticSelectedAfter = selectedFilters.current.includes(
          "domestic-locationname"
        );
        if (
          currentPayloadLocation === "domestic" &&
          !internationalSelectedAfter
        ) {
          filter[payload.key].push(...internationalLocations);
        } else if (
          currentPayloadLocation === "international" &&
          !domesticSelectedAfter
        ) {
          filter[payload.key].push(...domesticLocations);
        } else if (
          currentPayloadLocation === "domestic" &&
          internationalSelectedAfter &&
          !domesticSelectedAfter
        ) {
          filter[payload.key].push(...domesticLocations);
        } else if (
          currentPayloadLocation === "international" &&
          domesticSelectedAfter &&
          !internationalSelectedAfter
        ) {
          filter[payload.key].push(...internationalLocations);
        }
      }

      onFilterChange(filter);
    },
    [mapFilters, activeModule, onFilterChange, international, domestic]
  );

  useEffect(() => {
    const groupData = (statsData, expectedKey) => {
      const data = {
        itemgroup: {},
        location: {},
        hscode: "",
        lastUpdateDate: null,
      };

      if (statsData.length === 0) {
        setBarItemgroup([]);
        setBarLocation([]);
        return;
      }

      statsData.forEach((feautre) => {
        const { itemgroup, locationname, unit, quantity_sum } =
          feautre.attributes;
        data.itemgroup[itemgroup] = {
          ...(data.itemgroup[itemgroup] || {}),
          [unit]: {
            quantity_sum: 0,
            expected_sum: 0,
            hscode: "",
          },
        };

        data.location[locationname] = {
          ...(data.location[locationname] || {}),
          [unit]: {
            quantity_sum: 0,
            expected_sum: 0,
          },
        };
      });

      statsData.forEach((feautre) => {
        const {
          itemgroup,
          locationname,
          unit,
          quantity_sum,
          [`${expectedKey}_sum`]: expected,
          hscode_min = "",
          asofdate_max = null,
        } = feautre.attributes;
        const itemgroupKey = itemgroup;
        const locationKey = locationname;

        data.itemgroup[itemgroupKey][unit].quantity_sum += quantity_sum || 0;
        data.itemgroup[itemgroupKey][unit].expected_sum += expected || 0;
        data.itemgroup[itemgroupKey][unit].locationname = locationKey;
        data.itemgroup[itemgroupKey][unit].hscode = hscode_min;

        data.location[locationKey][unit].quantity_sum += quantity_sum || 0;
        data.location[locationKey][unit].expected_sum += expected || 0;
        data.location[locationKey][unit].itemgroup = itemgroup;
        data.lastUpdateDate = asofdate_max;
      });

      return data;
    };

    const quantityTitle = getTranslatedText(
      "layer.fieldAlias.quantity",
      "Quantity"
    );
    const expectedTitle = getTranslatedText(
      `layer.fieldAlias.${statsData.expectedKey}`,
      ""
    );

    if (expectedTitle) {
      setLegend({
        quantity: quantityTitle,
        expected: expectedTitle,
      });
    }

    if (statsData.domestic.length > 0) {
      const domesticData = groupData(statsData.domestic, statsData.expectedKey);
      handleStockData(domesticData, "domestic", statsData.expectedKey);
    }

    if (statsData.international.length > 0) {
      const internationalData = groupData(statsData.international);
      handleStockData(internationalData, "international");
    }
  }, [statsData]);

  const getTranslatedText = (path, defaultValue) => {
    return t(path) === path ? defaultValue : t(path);
  };
  const handleStockData = (data, type, expectedKey) => {
    const itemgroupValuesMap = new Map();
    const unitValues = new Map();

    getField(layer, "itemgroup").forEach((value) => {
      itemgroupValuesMap.set(`${value.code}`, value.name);
    });

    getField(layer, "unit").forEach((value) => {
      unitValues.set(`${value.code}`, value.name);
    });

    const { itemgroup, location } = data;

    const itemGroupBarArray = [];
    const locationBarArray = [];

    const quantityTitle = getTranslatedText(
      "layer.fieldAlias.quantity",
      "Quantity"
    );
    const expectedTitle = getTranslatedText(
      `layer.fieldAlias.${expectedKey}`,
      ""
    );
    const hsCodeTitle = getTranslatedText(`layer.fieldAlias.hscode`, "HS Code");

    Object.keys(itemgroup).forEach((key) => {
      const data = {
        name: getTranslatedText(
          "layer.domain.itemgroup." + key,
          itemgroupValuesMap[key]
        ),
        key: "itemgroup",
        codedValue: Number(key),
        quantity: 0,
        "quantity-title": quantityTitle,
        "quantity-description": "",
        expected: 0,
        "expected-title": expectedTitle,
        "expected-description": "",
        hscode: "",
      };

      let maxQuantity = -1;
      Object.keys(itemgroup[key]).forEach((unit, index) => {
        if (itemgroup[key][unit].quantity_sum > maxQuantity) {
          maxQuantity = itemgroup[key][unit].quantity_sum;
          const description = getTranslatedText(
            "layer.domain.unit." + unit,
            unitValues.get(unit)
          );

          data["quantity"] = itemgroup[key][unit].quantity_sum;
          data[`quantity-description`] = description;

          data.expected = itemgroup[key][unit].expected_sum;
          data["expected-description"] = description;
          data.hscode = hsCodeTitle + " " + itemgroup[key][unit].hscode;
        }
      });

      itemGroupBarArray.push({
        ...data,
      });
    });

    Object.keys(location).forEach((key) => {
      const data = {
        name: getTranslatedText("layer.domain.locationname." + key, key),
        key: "locationname",
        codedValue: key,
        quantity: 0,
        "quantity-title": quantityTitle,
        "quantity-description": [],
        expected: 0,
        "expected-title": expectedTitle,
        "expected-description": [],
        locationType: type,
      };

      Object.keys(location[key]).forEach((unit, index) => {
        const description = getTranslatedText(
          "layer.domain.unit." + unit,
          unitValues.get(unit)
        );

        data["quantity"] += location[key][unit].quantity_sum;
        data["quantity-description"].push(description);

        data.expected += location[key][unit].expected_sum;
        data["expected-description"].push(description);
      });

      locationBarArray.push({
        ...data,
      });
    });

    if (!selectedFilters.current.includes("itemgroup")) {
      if (type === "domestic") {
        setDomestic((prev) => ({
          ...prev,
          itemgroup: itemGroupBarArray,
          lastUpdateDate: data.lastUpdateDate,
        }));
      } else {
        setInternational((prev) => ({
          ...prev,
          itemgroup: itemGroupBarArray,
          lastUpdateDate: data.lastUpdateDate,
        }));
      }
      // setBarItemgroup(itemGroupBarArray);
    }

    if (!selectedFilters.current.includes(type + "-locationname")) {
      if (type === "domestic") {
        setDomestic((prev) => ({
          ...prev,
          location: locationBarArray,
          lastUpdateDate: data.lastUpdateDate,
        }));
      } else {
        setInternational((prev) => ({
          ...prev,
          location: locationBarArray,
          lastUpdateDate: data.lastUpdateDate,
        }));
      }
    }
  };

  const chartDescription = useMemo(() => {
    return DOMESTIC_CHART_DESCRIPTION[activeModule] || {};
  }, [activeModule]);

  const hasInternationalStock = useMemo(
    () =>
      international.itemgroup.length > 0 || international.location.length > 0,
    [international]
  );
  const hasDomesticStock = useMemo(
    () => domestic.itemgroup.length > 0 || domestic.location.length > 0,
    [domestic]
  );
  const renderText = useCallback((text) => {
    const match = text.match(/{(.*?)}/);

    //check if we have text in brackets to put it in other span
    if (match) {
      return (
        <span>
          {text.slice(0, match.index)} <span>{match[1]}</span>{" "}
          {text.slice(match.index + match[0].length)}
        </span>
      );
    }

    // // If no match, return a span with the original text
    return <span>{text}</span>;
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 10,
          minHeight: window.innerHeight - 44 - 57 - 24 - 8 - 20,
        }}
      >
        {hasDomesticStock && (
          <StyledCard
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: hasInternationalStock ? 0 : 10,
            }}
          >
            <StyledCardTitle
              style={{ textAlign: "center", marginBottom: 8, fontSize: 18 }}
            >
              {t("screen.widget.Insights.stp.national")}
            </StyledCardTitle>
            <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection:
                  !hasInternationalStock || mobileReducer.isMobileApp
                    ? "column"
                    : "row",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: mobileReducer.isMobileApp ? "300px" : "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 170,
                }}
              >
                <CardHeader
                  title={t("screen.widget.Insights.stp.overall.title")}
                  description={chartDescription.overall}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                />
                <StockChart
                  data={domestic.itemgroup}
                  onSelect={handleBarSelect}
                  barValues={["quantity", "expected"]}
                  config={config}
                  t={t}
                  mapFilters={mapFilters[activeModule]}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: mobileReducer.isMobileApp ? "300px" : "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 170,
                }}
              >
                <CardHeader
                  title={t("screen.widget.Insights.stp.byArea.title")}
                  description={chartDescription.area}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                />
                <StockChart
                  data={domestic.location}
                  onSelect={handleBarSelect}
                  barValues={["quantity", "expected"]}
                  config={config}
                  t={t}
                  mapFilters={mapFilters[activeModule]}
                />
              </div>
            </div>
            <Legend
              style={{
                paddingTop: 10,
              }}
              quantity={legend.quantity}
              expected={legend.expected}
              t={t}
              lastUpdateDate={domestic.lastUpdateDate}
            >
              <StyledCardTitle
                style={{
                  fontSize: "11px",
                  lineHeight: 1,
                  display: "flex",
                  alignItems: "center",
                  color: "#6F7072",
                }}
              >
                {t("screen.widget.Insights.stp.dataSources.title")}:
                <a
                  style={{
                    color: "inherit",
                    display: "flex",
                    margin: "0px 4px",
                    alignItems: "center",
                    gap: 2,
                  }}
                  href="https://www.esups-stockholm.org/#/private/signin"
                  target="_blank"
                >
                  {t("screen.widget.Insights.stp.dataSources.stockholm")}
                </a>
                {activeModule === "RES" && "and"}
                {activeModule === "RES" && (
                  <a
                    style={{
                      color: "inherit",
                      display: "flex",
                      marginLeft: 4,
                      alignItems: "center",
                      gap: 2,
                    }}
                    href="https://www.asyrec.org/"
                    target="_blank"
                  >
                    Asyrec
                  </a>
                )}
                | {t("screen.widget.Insights.stp.dataSources.asOfDate")}:{" "}
                {timeAgo(new Date(domestic.lastUpdateDate), "en", false)}
              </StyledCardTitle>
            </Legend>
          </StyledCard>
        )}
        {hasInternationalStock && (
          <StyledCard
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: 10,
            }}
          >
            <StyledCardTitle
              style={{ textAlign: "center", marginBottom: 8, fontSize: 18 }}
            >
              {t("screen.widget.Insights.stp.international")}
            </StyledCardTitle>
            <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection: mobileReducer.isMobileApp ? "column" : "row",
              }}
            >
              {international.itemgroup.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    height: mobileReducer.isMobileApp ? "300px" : "100%",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: 170,
                  }}
                >
                  <CardHeader
                    title={t("screen.widget.Insights.stp.overall.title")}
                    description={renderText(
                      t("screen.widget.Insights.stp.overall.tooltip")
                    )}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 8,
                    }}
                  />
                  <StockChart
                    data={international.itemgroup}
                    onSelect={handleBarSelect}
                    barValues={["quantity"]}
                    config={config}
                    t={t}
                    mapFilters={mapFilters[activeModule]}
                  />
                </div>
              )}
              {international.location.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    height: mobileReducer.isMobileApp ? "300px" : "100%",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: 170,
                  }}
                >
                  <CardHeader
                    title={t("screen.widget.Insights.stp.byArea.title")}
                    description={
                      <span>
                        {t("screen.widget.Insights.stp.byArea.tooltip")}
                      </span>
                    }
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 8,
                    }}
                  />
                  <StockChart
                    data={international.location}
                    onSelect={handleBarSelect}
                    barValues={["quantity"]}
                    config={config}
                    t={t}
                    mapFilters={mapFilters[activeModule]}
                  />
                </div>
              )}
            </div>
            <Legend
              quantity={legend.quantity}
              t={t}
              lastUpdateDate={international.lastUpdateDate}
            >
              <StyledCardTitle
                style={{
                  fontSize: "11px",
                  lineHeight: 1,
                  display: "flex",
                  alignItems: "center",
                  color: "#6F7072",
                }}
              >
                {t("screen.widget.Insights.stp.dataSources.title")}:
                <a
                  style={{
                    color: "inherit",
                    display: "flex",
                    margin: "0px 4px",
                    alignItems: "center",
                    gap: 2,
                  }}
                  href="https://www.ihc.ae/databank/"
                  target="_blank"
                >
                  {t("screen.widget.Insights.stp.dataSources.dubai")}
                </a>
                | {t("screen.widget.Insights.stp.dataSources.asOfDate")}:{" "}
                {timeAgo(new Date(international.lastUpdateDate), "en", false)}
              </StyledCardTitle>
            </Legend>
          </StyledCard>
        )}
      </div>
    </div>
  );
};

export default InsightsStockPrepositioning;
