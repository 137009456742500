import {useEffect, useRef} from "react";
import {loadModules} from "esri-loader";
import {currAsOfDateFieldName, getLayerFromId, opidFieldName, showpublicFieldName} from "../../utils/API";
import {DEPLOYMENT_STATUS_LAYER_ID, LAYER_CYCLE_FIELDS} from "../../redux/reducers/cycleManager";
import {
  addLayersUpdate,
  setCycleManagerData,
} from "../../redux/action/CycleManager-action";
import {calculateMissingFeatures} from "../../components/Dashboard/Monitoring/MonitoringDashboard/MonitoringDashboard";
import useEditableLayers from "../useEditableLayers";
import {useDispatch, useSelector} from "react-redux";

/**
 * Custom hook to manage cycle information and features for a given configuration.
 *
 * @param {Object} config - Configuration object containing the ID of the layer to query.
 * @property {string} config.id - The ID of the layer to query.
 *
 * @returns {Object} An object containing the following properties:
 * @property {Array} situationalLayers - The situational layers.
 * @property {Array} editableLayers - The editable layers.
 * @property {number} lastCycleUpdate - The last cycle update date in milliseconds.
 * @property {number} cycleUpdateDueDate - The cycle update due date milliseconds.
 * @property {number} updatePercent - The update percentage.
 * @property {Object} layersUpdate - The layers update object.
 * @property {boolean} open - The open status.
 */
const useCycleManager = (config) => {
  const {editableLayers, situationalLayers} = useEditableLayers(config, false);
  const {lastCycleUpdate, cycleUpdateDueDate, updatePercent, layersUpdates, open} = useSelector(state=>state.cycleManagerReducer);
  const dispatch = useDispatch();
  const abortControllers = useRef({});
  const isAborted = useRef(false);
  
  useEffect(()=>{
    if (!config)return;
    const getCycleInformation = async ()=>{
      try {
        const [Layer] = await loadModules(['esri/layers/Layer'])
        
        const deploymentStatusLayer = await getLayerFromId(Layer, DEPLOYMENT_STATUS_LAYER_ID);
        
        if (deploymentStatusLayer){
          deploymentStatusLayer.layerConfig = {
            filteredForOpid: true
          };
          
          await deploymentStatusLayer.load();
          const layerCycleFields = deploymentStatusLayer.fields.filter(field=>LAYER_CYCLE_FIELDS.includes(field.name));
          
          abortControllers.current[deploymentStatusLayer.layerConfig.id] = new AbortController();
          const res = await deploymentStatusLayer.queryFeatures({
            outFields: ['*'],
            where: `${opidFieldName} = '${config.id}'`
          }, {
            signal: abortControllers.current[deploymentStatusLayer.layerConfig.id].signal
          })
          
          if (Array.isArray(res.features) && res.features.length > 0) {
            const feature = res.features[0];
            if (isAborted.current) return;
            
            dispatch(setCycleManagerData({
              cycleUpdateFrequency: feature.attributes.cycleupdate,
              lastCycleUpdate: feature.attributes.lastcycleupdate,
              deploymentStatus: {
                layer: deploymentStatusLayer,
                feature: feature
              }
            }))
          }
        }
      } catch (err){
        console.error(err);
      }
    }
    
    getCycleInformation();
    
    return () => {
      isAborted.current = true;
      Object.values(abortControllers.current).forEach(controller=>{
        controller.abort();
      })
    }
  },[config])
  
  useEffect(()=>{
    if (!lastCycleUpdate) return;
    
    const fetchLayerFeatures = async (situationalLayers) => {
      try {
        const promises = [];
        situationalLayers.forEach(l=>{
          abortControllers.current[l.layerConfig.id] = new AbortController();
          const query = {
            outFields: [currAsOfDateFieldName, showpublicFieldName],
            where: l.definitionExpression,
          }
          promises.push(l.queryFeatures(query, {signal:abortControllers.current[l.layerConfig.id].signal}));
  
          l.queryFeatures(query, {signal:abortControllers.current[l.layerConfig.id].signal}).then(res=>{
            
            if (res?.features.length > 0 && l.layerConfig) {
              const outdatedFeatures = calculateMissingFeatures(res.features, lastCycleUpdate, cycleUpdateDueDate);
              // outdatedFeaturesCount += outdatedFeatures.length;
              
              const id = l.layerConfig.id
              const layerUpdate = {
                features: res.features,
                outdated: outdatedFeatures,
              };
              
              dispatch(addLayersUpdate({id, layerUpdate}))
            }
          }).catch(err=>{
            console.log(err);
          })
          
        });
  
        // console.log('start')
        // const results = await Promise.all(promises);
        // const features = [];
        // const layersUpdates = {};
        // let outdatedFeaturesCount = 0;
        // console.log(results)
        // results.forEach((res, index)=>{
        //   const layer = situationalLayers.toArray()[index];
        //   if (res?.features.length > 0 && layer.layerConfig) {
        //     const outdatedFeatures = calculateMissingFeatures(res.features, lastCycleUpdate, cycleUpdateDueDate);
        //     outdatedFeaturesCount += outdatedFeatures.length;
        //
        //     layersUpdates[layer.layerConfig.id] = {
        //       features: res.features,
        //       outdated: outdatedFeatures,
        //     };
        //
        //     features.push(...res.features);
        //   }
        // })
        
        // if (features.length > 0){
        //   const percent = ((features.length - outdatedFeaturesCount) * 100) / features.length;
        //   // dispatch(setCycleManagerFeatures({
        //   //   allFeatures: features.length,
        //   //   updatedFeatures: features.length - outdatedFeaturesCount
        //   // }))
        //   // dispatch(setLayersUpdates(layersUpdates))
        //
        //   return Math.floor(percent);
        // } else {
        //   return 0;
        // }
      } catch (err) {
        console.log(err);
        return 0;
      }
    }
    
    fetchLayerFeatures(situationalLayers)
  },[situationalLayers, lastCycleUpdate])
  
  return {
    situationalLayers,
    editableLayers,
    lastCycleUpdate,
    cycleUpdateDueDate,
    updatePercent,
    layersUpdates,
    open
  }};

export default useCycleManager;
