import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {ConfigContext} from "../../utils/ConfigContext";
import MobileHeader from "./MobileHeader/MobileHeader";
import {useSelector} from "react-redux";

const Header = ({setActiveLanguage, setMobileScreen, mobileScreen, setShowFilters}) => {
	const {isMobileApp} = useSelector(state=>state.mobileReducer);
	const [showModules, setShowModules] = useState(false);
	
	const {t} = useTranslation("common");
	const {config} = useContext(ConfigContext);
	
	if (isMobileApp) {
		return (
			<MobileHeader
				setShowModules={setShowModules}
				showModules={showModules}
				config={config}
				setMobileScreen={setMobileScreen}
				mobileScreen={mobileScreen}
				setShowFilters={setShowFilters}
			/>
		);
	} else {
		return null;
	}
}

export default Header
