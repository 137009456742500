import {
  setCycleManagerData_actionType,
  setCycleManagerFeatures_actionType,
  setLayersUpToDate_actionType,
  setLayersUpdates_actionType,
  setCycleManagerOpen_actionType,
  addLayersUpdates_actionType
} from "../constants"
import {showpublicFieldName} from "../../utils/API";
export const LAYER_CYCLE_FIELDS = ['cycleupdate', 'lastcycleupdate'];
export const DEPLOYMENT_STATUS_LAYER_ID = 'c0748ea086b4490d92f5d7c8fe4298b6';


const initialState = {
  cycleUpdateFrequency: null,
  lastCycleUpdate: null,
  cycleUpdateDueDate: null,
  allFeatures: 0,
  updatedFeatures: 0,
  layersUpToDate: [],
  layersUpdates: {}, //{Object<string, {missing: Array<Graphic>, features: Array<Graphic>}>} payload - The object with the layerConfig id as key and,
  open: false,
  updatePercent: 0,
  deploymentStatus: null
};

function addTimeToDate(dateTime, type) {
  const date = new Date(dateTime);
  switch (type) {
    case 1:
      date.setDate(date.getDate() + 1);
      break;
    case 2:
      date.setDate(date.getDate() + 7);
      break;
    case 4:
      const day = date.getDate();
      date.setMonth(date.getMonth() + 1);
      if (date.getDate() !== day) {
        date.setDate(0);
      }
      break;
    default:
      throw new Error('Invalid type. Please use "1", "2", or "4".');
  }

  return date.getTime();
  // const now = new Date();
  // const isSameDay = date.toDateString() === now.toDateString();
  // const isTomorrow = date.toDateString() === new Date(now.setDate(now.getDate() + 1)).toDateString();
  // // let formattedDate;
  // // if (isSameDay) {
  // //   formattedDate = `${date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })}`;
  // // } else if (isTomorrow) {
  // //   formattedDate = 'Tomorrow';
  // // } else {
  // //
  // // }

  // const formattedDate = `${date.getDate()} ${date.toLocaleString('en-US', { month: 'long' })} ${date.getFullYear()}` + `${date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })}`;
  // return formattedDate;
}

// function addTimeToDate(dateTime, type) {
//   const date = new Date(dateTime);
//   switch (type) {
//     case 1:
//       date.setUTCDate(date.getUTCDate() + 1);
//       break;
//     case 2:
//       date.setUTCDate(date.getUTCDate() + 7);
//       break;
//     case 3:
//       const day = date.getUTCDate();
//       date.setUTCMonth(date.getUTCMonth() + 1);
//       if (date.getUTCDate() !== day) {
//         date.setUTCDate(0);
//       }
//       break;
//     default:
//       throw new Error('Invalid type. Please use 1, 2, or 3.');
//   }
//
//   return date.getTime();
// }

/**
 * Calculate the percentage of up-to-date features in a payload.
 *
 * @param {layersUpdates} payload - An object containing layer updates with outdated and features arrays.
 * @returns {number} The percentage of up-to-date features.
 */
export function calculateUpToDateFeaturesPercentage(payload) {
  if (typeof payload !== 'object') return 0;

  let featuresCount = 0, outdatedCount = 0, errorOccurred = false;
  Object.values(payload).forEach(layerUpdate => {
    if (!layerUpdate?.outdated || !layerUpdate?.features) {
      errorOccurred = true;
      return;
    }
    
    outdatedCount += layerUpdate.outdated.length
    featuresCount += layerUpdate.features.filter(feat=>(!(showpublicFieldName in feat.attributes) || feat.attributes[showpublicFieldName] === 1)).length
  })
  
  if (errorOccurred || featuresCount === 0) return 0;
  return Math.floor(((featuresCount - outdatedCount) * 100) / featuresCount);
}

/**
 * The initial state of the cycle update process.
 * @typedef {Object} initialState
 * @property {number|null} cycleUpdateFrequency - The frequency of cycle updates.
 * @property {number|null} lastCycleUpdate - The timestamp of the last cycle update.
 * @property {number|null} cycleUpdateDueDate - The timestamp when the next cycle update is due.
 * @property {number} allFeatures - The total number of features.
 * @property {number} updatedFeatures - The number of features that have been updated.
 * @property {Array<string>} layersUpToDate - A list of layer IDs that are up-to-date.
 * @property {Object<string, {missing: Array<Graphic>, features: Array<Graphic>}>} layersUpdates - An object mapping layer IDs to their update status.
 * @property {boolean} open - Whether the update process is currently open.
 * @property {number} updatePercent - The percentage of completion for the update process.
 */

/**
 * Reduces the cycle manager state based on the given action.
 *
 * @param {initialState} state - The current state of the cycle update process.
 * @param {Object} action - The action to handle.
 * @returns {initialState} The updated state of the cycle update process.
 */
const cycleManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case setCycleManagerData_actionType:{
      const { payload } = action
      const dueDate = addTimeToDate(payload.lastCycleUpdate, payload.cycleUpdateFrequency);
      return {
        ...state,
        cycleUpdateFrequency: payload.cycleUpdateFrequency,
        lastCycleUpdate: payload.lastCycleUpdate,
        cycleUpdateDueDate: dueDate,
        deploymentStatus: payload.deploymentStatus
      }
    }
    case setCycleManagerFeatures_actionType:{
      const { allFeatures = 0,updatedFeatures = 0 } = action.payload || {}
      return {
        ...state,
        allFeatures,
        updatedFeatures
      }
    }
    case setLayersUpToDate_actionType:{
      const { payload } = action
      return {
        ...state,
        layersUpToDate: payload
      }
    }
    case setLayersUpdates_actionType:{
      const { payload } = action
      
      const percent = calculateUpToDateFeaturesPercentage(payload)
      return {
        ...state,
        layersUpdates: payload,
        updatePercent: percent
      }
    }
    case addLayersUpdates_actionType:{
      const { id, layerUpdate } = action.payload || {}
      const newState = {
        ...state,
        layersUpdates: {
          ...state.layersUpdates,
          [id]: layerUpdate
        }
      }
      
      const percent = calculateUpToDateFeaturesPercentage(newState.layersUpdates)
      // const percent = calculateUpToDateFeaturesPercentage(payload)
      return {
        ...newState,
        updatePercent:percent
      }
    }
    case setCycleManagerOpen_actionType:{
      const { payload } = action
      return {
        ...state,
        open: payload
      }
    }
  }
  return state
}

export default cycleManagerReducer;
