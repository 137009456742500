import { loadModules } from "esri-loader";
import { view } from "../../utils/API";
import { countryCodes } from "../../data/countryCodes";
import { isWidgetDisplayed, removeWidget } from "./expandUtils";
import { getFeatureTitle, getLayerTitle } from "../custom-popup-content";
import { clickEventOnFeature } from "../../utils/helper";
import React from "react";
import { createRoot } from "react-dom/client";
import { StyledProgress } from "../../components/App/App-styled";

let initialExtent;
export let searchWidget;

export const addLayerToSearch = async (layer, view, config, t) => {
  if (!view || !view.extent || !isWidgetDisplayed(config, "Search")) return;

  const searchConfig = layer.layerConfig?.search;
  if (searchConfig && searchConfig.enabled === false) return;

  if (!initialExtent) initialExtent = view.extent;

  await createSearchWidget(config, t);

  loadModules([
    "esri/widgets/Search/LayerSearchSource",
    "esri/rest/support/Query",
    "esri/Graphic",
  ]).then(([LayerSearchSource, Query, Graphic]) => {
    layer.load().then(() => {
      if (layer.type !== "feature") return null;

      //exclude not feature layers and the global country layer
      if (!layer.fields || layer.title === "World Countries (Generalized)")
        return;

      let searchFields = layer.fields
        .filter((field) => field.type === "string")
        .map((field) => field.name);
      if (searchFields.length === 0) return;

      //Filter fields
      if (searchConfig && searchConfig.searchFields) {
        const fields = searchConfig.searchFields;
        if (!Array.isArray(fields) || fields.length === 0) {
          console.warn(
            "Layer configuration error found. Layer: " +
              layer.title +
              " Error: searchField attribute must be an array of strings with at least one value"
          );
        } else {
          searchFields = searchFields.filter((layerField) =>
            fields.includes(layerField)
          );
        }
      }

      //Is layer already loaded?
      if (
        searchWidget.sources.filter(
          (locator) => locator.layer && locator.layer === layer
        ).length > 0
      )
        return;

      let layerSearchSource = new LayerSearchSource({
        layer: layer,
        name: getLayerTitle(layer, t),
        popupEnabled: false,
        enableSuggestions: true,
        getSuggestions: (params) => {
          if (!layer.visible) return;

          const query = composeLayerQuery(
            params.suggestTerm,
            Query,
            config,
            layer,
            searchFields
          );
          return layer.queryFeatures(query).then((results) => {
            return results.features.map((feature) => {
              let suggestion = getFeatureTitle(feature, t, config);
              if (!suggestion)
                suggestion = t("screen.widget.Search.noTitle", "No title");

              return {
                key: feature.attributes[layer.objectIdField]
                  ? feature.attributes[layer.objectIdField]
                  : "key",
                text: suggestion,
                sourceIndex: params.sourceIndex,
                feature: feature,
              };
            });
          });
        },
        getResults: (params) => {
          if (!params.suggestResult || !params.suggestResult.feature)
            return null;

          return new Promise((resolve) => {
            const feature = params.suggestResult.feature;
            const graphic = new Graphic({
              geometry: feature.geometry,
              attributes: feature.attributes,
            });

            //Mark the result, that is coming from the layer search source
            return resolve([
              {
                feature: feature,
                target: graphic,
                isLayerSearchSourceResult: true,
              },
            ]);
          });
        },
        filter: layer.definitionExpression
          ? { where: layer.definitionExpression }
          : {},
        exactMatch: false,
        outFields: ["*"],
      });

      if (initialExtent) layerSearchSource.zoomScale = initialExtent.width / 3;

      searchWidget.sources.push(layerSearchSource);
      searchWidget.sources.sort((a, b) => {
        if (a.layer && !b.layer) return -1;
        else if (!a.layer && b.layer) return 1;

        return a.name.localeCompare(b.name);
      });
    });
  });
};

export const composeLayerQuery = (term, Query, config, layer, searchFields) => {
  let query = new Query();
  const termLower = term.replaceAll("'", "''").trim().toLowerCase();

  let lowerCond = "";
  searchFields.forEach((field) => {
    lowerCond += `(lower(${field}) LIKE '%${termLower}%') OR `;
  });

  if (lowerCond.length > 0)
    lowerCond = lowerCond.substring(0, lowerCond.length - 3);

  query.where = "(" + lowerCond + ")";
  if (layer.definitionExpression)
    query.where += " and (" + layer.definitionExpression + ")";

  query.num = 6;
  query.outFields = ["*"];
  query.returnGeometry = true;

  return query;
};
export const createSearchWidget = async (config, t) => {
  const widgetId = "Search";
  if (searchWidget) return;

  const [Search] = await loadModules(["esri/widgets/Search"]);

  searchWidget = new Search({
    id: widgetId,
    view: view,
    sources: [],
    title: t("screen.widget." + widgetId + ".name"),
    includeDefaultSources: false,
    locationEnabled: false,
    popupEnabled: false,
    minSuggestCharacters: 2,
  });

  const arcgisSource = {
    name: t("screen.widget.Search.geocoding", "ArcGIS World Geocoding Service"),
    url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
    singleLineFieldName: "SingleLine", //to search by coordinates on copy paste
    zoomScale: initialExtent.width / 3,
  };
  const country = countryCodes.find((country) => country.ISO3 === config.iso3);

  if (country) {
    arcgisSource.countryCode = country.ISO2;
  }

  searchWidget.sources.push(arcgisSource);

  removeWidget(widgetId);
  view.ui.add(searchWidget, { position: "top-left", index: 0 });
  searchWidget.container.classList.add("onboarding-search");

  window.lastSearch = null;

  searchWidget.when(() => {
    const input = searchWidget.container.querySelector("#Search-input");

    input.placeholder = t(
      "screen.widget.Search.placeholder",
      "Search on LogIE"
    );
    input.addEventListener("keydown", noEnterEventListener);
  });

  searchWidget.on("search-blur", () => {
    toggleNoResultDialog(searchWidget, false);
  });

  searchWidget.on("search-start", () => {
    toggleNoResultDialog(searchWidget, false);
    /** Replace yx search with xy */
    // const yx = searchWidget.searchTerm.split(",")
    // if (yx.length === 2 && !isNaN(yx[0]) && !isNaN(yx[1]))
    // 	searchWidget.searchTerm = yx[1] + ", " + yx[0]
    shiftCoordinates(searchWidget);
    window.lastSearch = searchWidget.searchTerm;
  });

  //Add progress bar under the search box
  searchWidget.on("suggest-start", () => {
    toggleNoResultDialog(searchWidget, false);
    addProgressBar(searchWidget, config);
  });

  //Suggestion search complete: remove progress bar and eventually show the "no results" dialog
  searchWidget.on("suggest-complete", (res) => {
    removeProgressBar(searchWidget);
    if (res.numResults === 0) toggleNoResultDialog(searchWidget, true);
  });

  searchWidget.goToOverride = (view, goToParams) => {
    return view.goTo({
      target: goToParams.target.target,
      scale: initialExtent.width / 3,
    });
  };

  searchWidget.on("select-result", (selectResult) => {
    const result = selectResult.result;
    if (
      result?.feature &&
      result.isLayerSearchSourceResult &&
      result?.feature?.layer?.layerConfig?.clickable
    )
      clickEventOnFeature(result.feature);
  });

  searchWidget.on("search-complete", () => {
    removeProgressBar(searchWidget);
    /*
		setTimeout(()=>{
			searchWidget.clear()
			searchWidget.searchTerm = window.lastSearch
			const latLon = window.lastSearch.replace(",", "").split(" ").reverse().map(a => parseFloat(a))
			const resultGraphic = new Graphic({
				geometry: {	type: "point", longitude: latLon[0], latitude: latLon[1]},
				symbol: { type: "simple-marker", color: [77, 77, 77], size: 6 }
			})


			view.graphics.add(resultGraphic)
			view.popup.open({
				title: t('screen.widget.Search.result'),
				content: window.lastSearch,
				location: resultGraphic.geometry
			});
			window.lastSearch = resultGraphic

			searchWidget.suggest()
			expand.expand()
		}, 100)
		 */
  });

  searchWidget.on("search-clear", () => {
    toggleNoResultDialog(searchWidget, false);
    view.graphics.remove(window.lastSearch);
    if (view.popup.visible) {
      view.closePopup();
    }
  });

  //Shift + S pressed, open widget
  document.addEventListener("keydown", (evt) => {
    if (evt.code === "KeyF" && evt.ctrlKey && !evt.shiftKey) {
      const input = searchWidget.container.querySelector("#Search-input");
      if (!input) return;
      input.focus();
      evt.preventDefault();
    }
  });

  return searchWidget;
};

const toggleNoResultDialog = (searchWidget, show) => {
  const noRes = searchWidget.container.querySelector(".esri-search__container");
  if (show) noRes.classList.add("esri-search--warning");
  else noRes.classList.remove("esri-search--warning");
};

export const addProgressBar = (searchWidget, config) => {
  const search = searchWidget.container;

  if (!search) return;

  let progressBar = search.querySelector("#suggestionProgress");
  if (!progressBar) {
    progressBar = document.createElement("div");
    progressBar.setAttribute("id", "suggestionProgress");
    const root = createRoot(progressBar);
    root.render(<StyledProgress type="indeterminate" />);
    search.append(progressBar);
  }
};

export const removeProgressBar = (searchWidget) => {
  const progressBar = searchWidget.container.querySelector(
    "#suggestionProgress"
  );
  if (progressBar) progressBar.remove();
};

const noEnterEventListener = (evt) => {
  if (evt.keyCode !== 13) return;

  if (!isCoordSearch(evt.target.value)) evt.preventDefault();
};

export const isCoordSearch = (term) => {
  return /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)(,)?\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(
    term
  );
};

export function hasCoordinatesPattern(value) {
  // Check if the input is a string
  if (typeof value !== "string") return false;

  // Trim whitespace
  value = value.trim();

  // Check if the string contains only numbers and comma
  const numericWithCommaRegex = /^-?\d+(\.\d+)?(,\s*-?\d+(\.\d+)?)?$/;

  return numericWithCommaRegex.test(value);
}

export const shiftCoordinates = (searchWidget) => {
  /** Replace yx search with xy */
  const yx = searchWidget.searchTerm.split(",");
  if (yx.length === 2 && !isNaN(yx[0]) && !isNaN(yx[1]))
    searchWidget.searchTerm = yx[1] + ", " + yx[0];
};
